import dayjs from 'dayjs';

import { PaymentMethod, PaymentProvider } from '../store/enums';
import { ICheckoutStoreState } from '../store/checkout/types';
import { IUserStoreState } from '../store/user/types';
import UserHelper from './userHelper';
import { IDistributionStoreState } from '../store/distribution/types';

export class CheckoutHelper {
  public static getIsInitiated(checkout: ICheckoutStoreState): boolean {
    return !!checkout && !!checkout.init && !!checkout.init.event;
  }

  public static getIsEmailSet(checkout: ICheckoutStoreState): boolean {
    return this.getIsInitiated(checkout) && !!checkout.email;
  }

  public static getIsProcessed(checkout: ICheckoutStoreState): boolean {
    return this.getIsEmailSet(checkout) && !!checkout.process;
  }

  public static getIsCompleted(checkout: ICheckoutStoreState): boolean {
    return (
      this.getIsProcessed(checkout) &&
      !!checkout.complete &&
      !!checkout.complete.result &&
      checkout.complete.result.isCompleted
    );
  }

  public static getDistributionInfo(checkout: ICheckoutStoreState, distributionInfo: IDistributionStoreState): string {
    if (!this.getIsDistributionInfoValid(distributionInfo)) {
      return null;
    }
    const isValidDistributor = !checkout || (this.getIsInitiated(checkout) && checkout.init.distributorFound);
    return isValidDistributor ? 'Book.NameOfYourDistributor' : 'Book.DistributorNotFound';
  }

  public static getIsDistributionInfoValid(distribution: IDistributionStoreState): boolean {
    return !!distribution && !!distribution.distributorSlug && dayjs().isBefore(distribution.expirationDate);
  }

  public static isRedirectingMethod(checkout: ICheckoutStoreState): boolean {
    return (
      this.getIsProcessed(checkout) &&
      [PaymentProvider.YandexKassa, PaymentProvider.Fanaticka].includes(
        checkout.process.paymentProvider as PaymentProvider
      ) &&
      !!checkout.process.paymentRedirectUrl
    );
  }

  public static hasPaymentMethods(checkout: ICheckoutStoreState): boolean {
    return this.getIsInitiated(checkout) && checkout.init.event.paymentMethods.length > 0;
  }

  public static hasDifferentPaymentMethods(checkout: ICheckoutStoreState): boolean {
    return this.hasPaymentMethods(checkout) && checkout.init.event.paymentMethods.length > 1;
  }

  public static getIsNeedPaymentForm(checkout: ICheckoutStoreState): boolean {
    return (
      this.getIsProcessed(checkout) &&
      !this.hasDifferentPaymentMethods(checkout) &&
      [PaymentProvider.Stripe].includes(checkout.process.paymentProvider as PaymentProvider)
    );
  }

  public static getIsAvailableForProcessing(checkout: ICheckoutStoreState): boolean {
    return (
      this.getIsEmailSet(checkout) &&
      !this.getIsProcessed(checkout) &&
      checkout.paymentMethod === PaymentMethod.StripeBankCard
    );
  }

  public static getIsPointsUsed(checkout: ICheckoutStoreState): boolean {
    return this.getIsEmailSet(checkout) && checkout.usePoints;
  }

  public static getIsEnoughPoints(checkout: ICheckoutStoreState, user: IUserStoreState): boolean {
    if (!user || !this.getIsEmailSet(checkout)) {
      return false;
    }
    const currencyBalance = UserHelper.getBalanceByCurrency(user, checkout.init.currency);
    if (!currencyBalance) {
      return false;
    }

    const minPricePayByPoints = checkout.init.pricePayByPoints;
    return currencyBalance.amount >= minPricePayByPoints;
  }

  public static getIsInProcess(checkout: ICheckoutStoreState): boolean {
    return (
      this.getIsProcessed(checkout) &&
      !!checkout.complete &&
      !!checkout.complete.result &&
      checkout.complete.result.isProcessing
    );
  }
}

export default CheckoutHelper;
