import React, { FC, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { IErrorStoreState } from '../../../../store/app/types';
import { IEventStoreState, ISeatStoreState } from '../../../../store/event/types';
import { IBookStoreState } from '../../../../store/book/types';
import { FormError } from '../../../../components/forms';
import { PopUp } from '../../../../store/animations';
import { IGlobalStoreState } from '../../../../store';

export const WidgetError: FC<ErrorPropsType> = memo(({ seats, event, book }) => {
  const { t } = useTranslation();

  const error = useSelector<IGlobalStoreState, IErrorStoreState>((state) => state.app.error);
  const [errors, setErrors] = useState<IErrorStoreState>(null);

  useEffect(() => {
    setErrors(error);
  }, [error?.details, error?.message]);

  useEffect(() => {
    const { maxPurchase } = event;
    const seatsCount = seats.length;

    if (seatsCount >= maxPurchase) {
      setErrors({ message: t('WidgetBook.YouHaveReachedYourEventTicketPurchaseLimit') });
      return;
    }
    setErrors(null);
  }, [event?.maxPurchase, seats?.length, setErrors, t]);

  if (!event || !seats || !book) {
    return null;
  }

  if (!errors) {
    return null;
  }
  const { message, details } = errors;

  return (
    errors && (
      <AnimatePresence>
        <ErrorWrapper variants={PopUp} key="form-error">
          {message || details}
        </ErrorWrapper>
      </AnimatePresence>
    )
  );
});

const ErrorWrapper = styled(FormError as any)`
  width: 100%;
`;

type ErrorPropsType = {
  event: IEventStoreState;
  seats: ISeatStoreState[];
  book: IBookStoreState;
};
