import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';

import { IEventStoreState, EventActionTypes } from './types';
import { ThunkService } from '../../services';
import { IGlobalStoreState } from '..';
import { showSpinner, hideSpinner } from '../app/actions';
import { IEventDetailsStoreState } from './eventDetails/EventDetailsStoreState';

const initEvents = (events: Array<IEventStoreState>) => action(EventActionTypes.EVENTS_INIT, events);

export const getEvents =
  (city: string) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IGlobalStoreState,
    thunkService: ThunkService
  ): Promise<Array<IEventStoreState>> => {
    dispatch(showSpinner());
    const events = await thunkService.api.events.getEvents(city);
    dispatch(initEvents(events));
    dispatch(hideSpinner());
    return Promise.resolve(events);
  };

export const getWidgetEvent =
  (
    token: string
  ): ((
    dispatch: Dispatch<any>,
    getState: () => IGlobalStoreState,
    thunkService: ThunkService
  ) => Promise<IEventStoreState>) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(showSpinner());
    const event = await thunkService.api.widget.getWidgetEvent(token);
    dispatch(hideSpinner());
    return Promise.resolve(event);
  };

export const getEventById =
  (
    slug: string,
    distributorSlug?: string,
    token?: string
  ): ((
    dispatch: Dispatch<any>,
    getState: () => IGlobalStoreState,
    thunkService: ThunkService
  ) => Promise<IEventStoreState>) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    const state = getState();
    if (state.distribution) {
      distributorSlug = state.distribution.distributorSlug || distributorSlug;
    }
    if (state.book.token) {
      token = state.book.token || token;
    }
    dispatch(showSpinner());
    const event = await thunkService.api.events.getEventById(slug, distributorSlug, token);
    dispatch(hideSpinner());
    return Promise.resolve(event);
  };

export const getSellEventDetails =
  (
    slug: string
  ): ((
    dispatch: Dispatch<any>,
    getState: () => IGlobalStoreState,
    thunkService: ThunkService
  ) => Promise<IEventStoreState>) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(showSpinner());
    const event = await thunkService.api.events.getSellEventDetails(slug);
    dispatch(hideSpinner());
    return Promise.resolve(event);
  };

export const getEventDetailsBySlug =
  (
    slug: string,
    distributorSlug?: string,
    token?: string
  ): ((
    dispatch: Dispatch<any>,
    getState: () => IGlobalStoreState,
    thunkService: ThunkService
  ) => Promise<IEventDetailsStoreState>) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    const state = getState();
    if (state.distribution) {
      distributorSlug = state.distribution.distributorSlug || distributorSlug;
    }
    if (state.book.token) {
      token = state.book.token || token;
    }
    dispatch(showSpinner());
    const event = await thunkService.api.events.getEventDetailsBySlug(slug, distributorSlug, token);
    dispatch(hideSpinner());
    return Promise.resolve(event);
  };

export const startDistribute =
  (
    slug: string
  ): ((dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => Promise<void>) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(showSpinner());
    await thunkService.api.events.startDistribute(slug);
    dispatch(hideSpinner());
    return Promise.resolve();
  };

export const stopDistribute =
  (
    slug: string
  ): ((dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => Promise<void>) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(showSpinner());
    await thunkService.api.events.stopDistribute(slug);
    dispatch(hideSpinner());
    return Promise.resolve();
  };
