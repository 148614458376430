export interface IMenuStoreState {
  isShow: boolean;
  contracts: ContractInfo;
}

export class MenuStoreState implements IMenuStoreState {
  isShow: boolean;
  contracts: ContractInfo;

  constructor(contractInfo: ContractInfo) {
    this.isShow = false;
    this.contracts = contractInfo;
  }
}

export class ContractInfoContainer {
  language: string;
  fileUrl: string;

  constructor(language: string, fileUrl: string) {
    this.language = language;
    this.fileUrl = fileUrl;
  }
}

export class ContractInfo {
  publicContracts: Array<ContractInfoContainer>;
  bonusPrograms: Array<ContractInfoContainer>;

  constructor(publicContracts: Array<ContractInfoContainer>, bonusPrograms: Array<ContractInfoContainer>) {
    this.bonusPrograms = bonusPrograms;
    this.publicContracts = publicContracts;
  }
}

export enum MenuActionTypes {
  MENU_SHOW = '@@menu/SHOW',
  MENU_HIDE = '@@menu/HIDE',
  CONTRACTS_INIT = '@@menu/INITCONTRACT',
}
