import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import { IGlobalStoreState } from '../../../store';
import { IEventStoreState, ISeatStoreState, SeatStoreState } from '../../../store/event/types';
import { ITokenTicketQuotaStoreState, IVenueSectorStoreState } from '../../../store/ticket/types';
import { getEventById } from '../../../store/event/actions';
import { Button, ISelectorItem, Page, PageContent, PageFooter, Selector } from '../../../components/UI';
import { PlaceSelector, SchemaSectorSelector, TicketCountSelector } from '../../../components/book';
import { checkoutInit } from '../../../store/checkout/actions';
import { AnalyticService, Utils } from '../../../services';
import {
  clearBook,
  initBook,
  setQuota,
  setSector,
  setSectorRow,
  setSectorRowSeat,
  setTicketsCount,
} from '../../../store/book/actions';
import { CheckoutHelper, EventHelper } from '../../../helpers';
import { IBookStoreState } from '../../../store/book/types';
import { distributionInit } from '../../../store/distribution/actions';
import { AlertType, KeyErrors } from '../../../store/enums';
import { showSpinner } from '../../../store/app/actions';
import { IUserStoreState } from '../../../store/user/types';
import { IErrorStoreState } from '../../../store/app/types';
import { FormError, FormInfo } from '../../../components/forms';
import { IDistributionStoreState } from '../../../store/distribution/types';
import { getTicketQuotaByShareToken } from '../../../store/share/actions';
import { BookTotalPrices } from '.';
import { hideAdButton } from '../../../store/header/actions';
import { withTranslation } from 'react-i18next';
import RouteService from '../../../services/routeService';
import { setResultInfo } from '../../../store/resultInformarion/actions';
import { ResultInfoType } from '../../../store/resultInformarion/types';

interface IBookPageProps {
  quotaId: number;
  eventSlug: string;
  sectorSlug: string;
  distributorSlug?: string;
  event: IEventStoreState;
  isLoading: boolean;
  book: IBookStoreState;
  user: IUserStoreState;
  error: IErrorStoreState;
  isRedirection: boolean;
  isFinalized: boolean;
  distribution: IDistributionStoreState;
  token: string;

  getEventById: (id: string, distributorSlug?: string, token?: string) => Promise<IEventStoreState>;
  initBook: (
    event: IEventStoreState,
    sectorSlug?: string,
    seats?: ISeatStoreState[],
    token?: string
  ) => Promise<IBookStoreState>;
  clearBook: () => void;
  checkoutInit: () => Promise<void>;
  distributionInit: (distributorSlug: string) => void;
  go: (url: string) => void;
  replace: (url: string) => void;
  showAlert: (config: ResultInfoType) => void;
  showSpinner: () => void;
  setQuota: (id: number) => void;
  setSector: (slug: string) => void;
  setRow: (slug: string) => void;
  setSeat: (slug: string) => void;
  setTickets: (count: number) => void;
  getTicketQuotaByToken: (token: string) => Promise<ITokenTicketQuotaStoreState>;
  hideAdButton: () => void;
  t: (text: string, options?: {}) => string;
}

interface IBookPageState {
    isCheckoutInitiating: boolean;
    errors?: any;
    seats: ISeatStoreState[];
    showSeatsSelector?: boolean;
}

class BookPage extends React.Component<IBookPageProps, IBookPageState> {
    private routeSrv = new RouteService();
    private analyticSrv = new AnalyticService()
    constructor(props: IBookPageProps) {
        super(props);

        this.state = {
            isCheckoutInitiating: false,
            errors: null,
            seats: []
        };
    }

    public componentWillMount() {
        this.initEvent(this.props);
    }

    componentDidMount() {
        this.forceUpdate();
        this.props.hideAdButton();
        this.analyticSrv.trackEvent("TicketsDetails");
    }

    public componentWillReceiveProps(nextProps: IBookPageProps) {
        const isSlugChanged = this.props.eventSlug !== nextProps.eventSlug;
        const isSectorChanged = nextProps.sectorSlug !== this.props.sectorSlug;
        const isErrorChanged = !Utils.isEqual(this.props.error, nextProps.error);
        const isFinalizedChanged = nextProps.isFinalized !== this.props.isFinalized && nextProps.isFinalized;
        // if (isErrorChanged) this.setState({ ...this.state, errors: { form: nextProps.error } });
        // if (isSlugChanged || isFinalizedChanged) this.initEvent(nextProps);
        // if (isSectorChanged) this.props.setSector(nextProps.sectorSlug)
    }

    public render() {
        const event = this.props.event;
        if (this.props.isRedirection || this.props.isLoading || !event || this.state.isCheckoutInitiating || this.props.token)
            return null;

        const sector = event && event.sectors && event.sectors.find((x) => x.slug === this.props.sectorSlug);
        const isInteractiveSchema = !!sector && !!sector.schema;

        return (
            <Page
                isForm
                title={`${this.props.t("Book.TicketPurchase")} | ${event.title} - ${event.subtitle}`}
                description={EventHelper.getMetaDescription(event)}
                keywords={[event.title, event.venue.title, this.props.t("Book.BuyTicket"), this.props.t("Book.Concert")]}
                imageUrl={event.posterUrl}
            >
                <PageContent>
                    {<SchemaSectorSelector
                        venue={event.venue}
                        sectors={event.sectors}
                        selectedSector={this.props.book.sectorSlug}
                        onChange={(sectorSlug: string) => this.handleSchemaSectorSelect(sectorSlug)}
                    />}
                    {isInteractiveSchema && this.state.showSeatsSelector && this.renderPlaceSelector(event, sector)}
                    {!isInteractiveSchema && this.renderQuotaSelector(event, sector)}
                    {!isInteractiveSchema && this.renderTicketsCountSelector(event, sector)}
                </PageContent>
                {this.renderFooter()}
            </Page>
        );
    }

    private renderTicketsInfo() {
        if (!this.state.seats.length) return null;
        const tickets = this.state.seats;
        const event = this.props.event;
        const defaultPaymentMethod = event.paymentMethods && event.paymentMethods.length > 0 ? event.paymentMethods[0] : null;
        let total = 0
        let currency: string;
        tickets.forEach(ticket => {
            event.sectors.forEach(sector => {
                const q = sector.ticketQuotas.find((st) => st.id == ticket.quotaId);
                if (q) {
                    total += q.price;
                    currency = q.currency
                }
            });
        });

        if (!!defaultPaymentMethod.useConvertion) {
            total = Math.round(total * defaultPaymentMethod.convertionRate * 100) / 100;
            currency = defaultPaymentMethod.convertionCurrency;
        }
        return (
            <>
                <Table>
                    {this.props.t('Book.SelectedTickets')}
                    {this.state.seats.map((ticket) => {
                        const sector = this.props.event.sectors.find((x) => x.slug == ticket.sectorSlug);
                        const quota = sector.ticketQuotas.find((x) => x.id == ticket.quotaId);
                        const title = `${this.props.t('Book.Sector')} ${ticket.sectorTitle}${ticket.rowSlug ? `, ${this.props.t('Book.Row')} ${ticket.rowSlug}` : ``}${ticket.seatSlug ? `, ${this.props.t('Book.Seat')} ${ticket.seatSlug}.` : ``}`;
                        const price = `${quota.price} ${quota.currency}`;
                        return (
                            <TableRow>
                                <TableData>
                                    <CostTitle>{title}</CostTitle>
                                </TableData>
                                <TableData>
                                    <CostTitle>{price}</CostTitle>
                                </TableData>
                            </TableRow>
                        );
                    })}
                </Table>
                <Table>
                    <TableRow>
                        <TableData>
                            <TotalPriceTitle>{this.props.t("Book.Total")}</TotalPriceTitle>
                        </TableData>
                        <TableData>
                            <TotalPrice>
                                {total} {currency}
                            </TotalPrice>
                        </TableData>
                    </TableRow>
                </Table>
            </>
        );
    }

    private renderQuotaSelector(event: IEventStoreState, sector: IVenueSectorStoreState) {
        if (!event || !sector || !sector.ticketQuotas || this.props.quotaId) return null;
        return <Selector items={this.getQuotaSelectorItems(event, sector)} />;
    }

    private getQuotaSelectorItems(event: IEventStoreState, sector: IVenueSectorStoreState): ISelectorItem[] {
        return (
            sector &&
            sector.ticketQuotas
                .map((q) => {
                    const capacity = !sector.noSeats ? q.left : 0;
                    return {
                        to: this.routeSrv.getBookRoute(event.slug, q.id.toString(), sector.slug, null, null),
                        title: q.title,
                        subtitle: capacity > 0 ? `${capacity} ${Utils.getTicketsPostfixByCount(capacity)}` : null,
                    } as ISelectorItem;
                })
        );
    }

    private renderTicketsCountSelector(
        event: IEventStoreState,
        sector: IVenueSectorStoreState
    ) {
        if (!event || !sector || !sector.ticketQuotas || !this.props.quotaId) return null;
        const quota = sector.ticketQuotas.find((q) => q.id == this.props.quotaId);
        if (!quota) return null;
        let tickets = this.state.seats.filter((s) => s.quotaId == this.props.quotaId);
        let maxPurchase = quota.left < this.props.event.maxPurchase ? quota.left : this.props.event.maxPurchase;
        return (
            <TicketsPriceWrapper>
                {sector.noSeats && (
                    <>
                        <Title>{this.props.t("Book.TicketCount")}</Title>
                        <TicketSelectorsWrapper>
                            <TicketCountSelector
                                max={maxPurchase - this.state.seats.filter((s) => s.quotaId != this.props.quotaId).length}
                                sector={sector}
                                tickets={tickets.length}
                                handleTicketsCount={(count) => this.handleTicketsCount(count)}
                            />
                        </TicketSelectorsWrapper>
                    </>
                )}
                {!sector.noSeats && <Title>{this.props.t("Book.TicketsPrice")}</Title>}
                {this.renderPrices(event, sector)}
            </TicketsPriceWrapper>
        );
    }

    private renderDistributionInfo() {
        const distributionInfo = CheckoutHelper.getDistributionInfo(null, this.props.distribution);
        if (!distributionInfo) return null;
        return <Warning>{this.props.t(distributionInfo, {distributor: this.props.distribution.distributorSlug})}</Warning>
    }

    private handleTicketsCount(count: number) {
        this.setState(state => {
            let seats = state.seats.filter((s) => s.quotaId != this.props.quotaId)
            for (let index = 0; index < count; index++) {
                const sector = this.props.event.sectors.find((x) => x.slug == this.props.sectorSlug);
                seats = seats.concat(new SeatStoreState(this.props.quotaId, this.props.sectorSlug, sector.title))
            }
            return { seats };
        });
    }

    private renderPrices(
        event: IEventStoreState,
        sector: IVenueSectorStoreState
    ) {
        if (!event || !sector || !sector.ticketQuotas || !this.props.quotaId) return null;
        const quota = sector.ticketQuotas.find((q) => q.id == this.props.quotaId);
        if (!quota) return null;
        let tickets = this.state.seats.filter((s) => s.quotaId == this.props.quotaId);

        const defaultPaymentMethod = event.paymentMethods && event.paymentMethods.length > 0 ? event.paymentMethods[0] : null;
        const ticketPrice = quota.price;
        const ticketsCount = tickets.length;
        let total = ticketPrice * ticketsCount;
        let currency = quota.currency;
        let sectorTitle = '';

        if (!!defaultPaymentMethod.useConvertion) {
            total = Math.round(total * defaultPaymentMethod.convertionRate * 100) / 100;
            currency = defaultPaymentMethod.convertionCurrency;
        }

        if (!sector.noSeats && !!this.state.seats && !!this.state.seats.length) sectorTitle = `${ticketsCount} x ${sector.title}`;
        return <BookTotalPrices border={sector.noSeats} ticketPrice={ticketPrice} currency={currency} totalPrice={total} sectorTitle={sectorTitle} />;
    }



    private renderPlaceSelector(event: IEventStoreState, sector: IVenueSectorStoreState) {
        const caption = `${this.props.t("Book.RowNumbering")}`;
        if (!event || !sector) return null;
        return <PlaceSelector
            caption={caption}
            venueSlug={event.venue.slug}
            sector={sector}
            selectedSeats={this.state.seats}
            maxSelectCount={this.props.event.maxPurchase}
            onChange={(quotaId: number, sectorSlug: string, rowSlug: string, seatSlug: string) => this.handlePlaceSelect(quotaId, sectorSlug, rowSlug, seatSlug)}
        />;
    }

    private renderFooter() {
        const event = this.props.event;
        const sector = event && event?.sectors && event?.sectors.find((x) => x?.slug === this.props?.sectorSlug);
        if (!event) return null;

        const hasTickets = EventHelper.getHasTickets(event);
        const isSectorSelected = !!this.props.sectorSlug;
        const isSeatsSelected = isSectorSelected && this.state.seats && this.state.seats.length > 0;
        const isCheckoutEnabled = !this.props.isLoading && hasTickets && isSeatsSelected && !event.notAvailableForPurchase;
        let buttonTitle = this.props.t("Book.GoToPayment");
        if (!hasTickets) buttonTitle = this.props.t("Book.TicketsAreNotAvailableForSale");
        else if (!isSectorSelected) buttonTitle = this.props.t("Book.ChooseSector");
        else if (!isSeatsSelected && !sector?.noSeats) buttonTitle = this.props.t("Book.ChooseSeats");
        else if (event.notAvailableForPurchase) buttonTitle = event.notAvailableForPurchaseReason;
        return (
            <Footer>
                { !!sector && !sector?.noSeats && this.renderTicketsInfo()}
                <div>{this.renderError(sector)}</div>
                {this.renderDistributionInfo()}
                <Button disabled={!isCheckoutEnabled} onClick={(e) => this.handleCheckoutClick(e)}>
                    {buttonTitle}
                </Button>
            </Footer>
        );
    }

    private renderError(sector: IVenueSectorStoreState) {
        let error = null;
        if (!this.props.event || !this.state.seats || !this.props.book) return null;
        const maxPurchase = this.props.event.maxPurchase;
        const seatsCount = this.state.seats.length;
        const stateErrors = this.state.errors;
        if (seatsCount >= maxPurchase) error = this.props.t("Book.YouHaveReachedYourEventTicketPurchaseLimit");
        else if (stateErrors && stateErrors.form) error = stateErrors.form.details || stateErrors.form.message;
        return !!error && <ErrorWrapper key='form-error'>{error}</ErrorWrapper>;
    }

    private initEvent(props: IBookPageProps) {
        if (props.isRedirection || !props.isFinalized) return;
        if (props.distributorSlug) this.props.distributionInit(props.distributorSlug);
        if (!props.eventSlug) {
            this.props.go('/');
            return;
        }

        this.props.getEventById(props.eventSlug, null, props.token)
            .then((event) => {
                if (!event || !event.slug) {
                    this.props.clearBook();
                    this.props.showAlert({
                      type: AlertType.Error,
                      key: KeyErrors.EventNotFound,
                      title: 'Alert.SomethingWentWrong',
                      message: 'Alert.CheckLinkNoEventFound',
                    });
                    this.props.replace(this.routeSrv.getResultStatusRoute(AlertType.Error, KeyErrors.EventNotFound));
                    return;
                }

                if (props.token) {
                    this.props.getTicketQuotaByToken(props.token).then((q) => {
                        if (q.left === 0) {
                            this.props.clearBook();
                            this.props.showAlert({
                              type: AlertType.Error,
                              key: KeyErrors.QuotaNotFound,
                              title: 'Alert.SomethingWentWrong',
                              message: 'Alert.CheckLinkNoLocationFound',
                            });
                            this.props.replace(this.routeSrv.getResultStatusRoute(AlertType.Error, KeyErrors.QuotaNotFound));
                            return;
                        }
                        this.props.clearBook();
                        if (event.notAvailableForPurchase) {
                            this.props.go(this.routeSrv.getEventRoute(event.slug));
                            return;
                        }

                        const sector = event.sectors.find((x) => x.slug == q.sectorSlug);
                        if (!sector) {
                            this.props.go(this.routeSrv.getBookRoute(event.slug, null, null, null, null));
                            return;
                        }

                        if (!EventHelper.hasSectorTickets(sector)) {
                            this.props.showAlert({
                              type: AlertType.Error,
                              key: KeyErrors.NoTickets,
                              title: 'Alert.SomethingWentWrong',
                              message: 'Alert.NoTicketsForCurrentEvent',
                              buttons: [
                                {
                                  title: this.props.t('Result.GoToMainPage'),
                                  callback: () =>
                                    this.props.replace(
                                      this.routeSrv.getBookRoute(event.slug, null, sector.slug, null, null)
                                    ),
                                },
                              ],
                            });
                            this.props.replace(this.routeSrv.getResultStatusRoute(AlertType.Error, KeyErrors.NoTickets));
                            return;
                        }

                        this.setState(state => {
                            let seats = state.seats.filter((s) => s.quotaId != q.quotaId)
                            for (let index = 0; index < q.left; index++) {
                                seats = seats.concat(new SeatStoreState(q.quotaId, q.sectorSlug, sector.title))
                            }
                            return { seats };
                        });

                        this.props.initBook(event, q.sectorSlug, this.state.seats, this.props.token);
                        this.checkoutInit();
                    });
                    return;
                }

                this.props.clearBook();
                if (event.notAvailableForPurchase) {
                    this.props.go(this.routeSrv.getEventRoute(event.slug));
                    return;
                }

                if (!this.props.sectorSlug) {
                    this.props.initBook(event, this.props.sectorSlug, this.props.book.seats);
                    return;
                }

                if (!this.props.event || !this.props.event.sectors) {
                    this.props.initBook(event, this.props.sectorSlug, this.props.book.seats);
                    return;
                }
                const sector = event.sectors.find((x) => x.slug == this.props.sectorSlug);
                if (!EventHelper.hasSectorTickets(sector)) {
                    this.props.showAlert({
                        type: AlertType.Error,
                        key: KeyErrors.NoTickets,
                        title: 'Alert.SomethingWentWrong',
                        message: 'Alert.NoTicketsForCurrentEvent',
                        buttons: [
                            {
                                title: this.props.t('Result.GoToMainPage'),
                                callback: () =>
                                  this.props.replace(
                                    this.routeSrv.getBookRoute(event.slug, null, sector.slug, null, null)
                                  ),
                            },
                        ],
                    });
                    this.props.replace(this.routeSrv.getResultStatusRoute(AlertType.Error, KeyErrors.NoTickets));
                    return;
                }

                this.props.initBook(event, this.props.sectorSlug, this.props.book.seats);
            });
    }

    private handleCheckoutClick(e: any) {
        if (e) e.preventDefault();
        this.props.initBook(this.props.event, this.props.sectorSlug, this.state.seats);
        this.checkoutInit();
    }

    private handleSchemaSectorSelect(sectorSlug: string) {
        this.setState({ ...this.state, showSeatsSelector: false });
        this.props.go(this.routeSrv.getBookRoute(this.props.eventSlug, null, sectorSlug));
        this.setState({ ...this.state, showSeatsSelector: true });
    }

    private handlePlaceSelect(quotaId: number, sectorSlug: string, rowSlug: string, seatSlug: string) {
        this.setState(state => {
            if (state.seats.some((s) => s.sectorSlug === sectorSlug && s.rowSlug === rowSlug && s.seatSlug === seatSlug)) {
                const seats = state.seats.filter((s) => s.sectorSlug !== sectorSlug || s.rowSlug !== rowSlug || s.seatSlug !== seatSlug)
                return { seats };
            }
            const sector = this.props.event.sectors.find((x) => x.slug == this.props.sectorSlug);
            const seats = this.props.book.event.maxPurchase > state.seats.length ?
                state.seats.concat(new SeatStoreState(quotaId, sectorSlug, sector.title, rowSlug, seatSlug)) :
                state.seats;
            return { seats };
        });
    }

    private checkoutInit() {
            this.props
                .checkoutInit()
                .then(() => {
                    this.setState({ ...this.state, isCheckoutInitiating: false }, () => {
                        this.props.go(this.routeSrv.getCheckoutRouteFromBook(this.props.book));
                    });
                    this.analyticSrv.trackEvent("AddToCart")
                })
                .catch(() => {
                    this.setState({ ...this.state, isCheckoutInitiating: false });
                });
    }
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    &:after {
        background-size: cover;
        width: '14px';
        height: '14px';
        position: absolute;
        margin-left: '-25px';
        top: '0';
    }
`;

const CostTitle = styled.span`
    font-size: 12px;
    font-family: ${(props) => props.theme.fonts.lighterFont};
`;

const Footer = styled(PageFooter as any)`
    align-items: initial
    padding: 0 25px 20px 25px;
    > button,
    > input {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
`;

const TicketsPriceWrapper = styled.div`
    padding-top: 30px;
`;

const Title = styled.h1`
    font-size: 14px;
    margin: 0 0 15px 0;
`;

const TicketSelectorsWrapper = styled.section`
    margin-bottom: 30px;
`;

const ErrorWrapper = styled(FormError as any)`
    width: 100%;
`;

const Warning = styled(FormInfo as any)`
    padding: 10px 0 0 0;
`;

const ListItem = styled.li`
    font-size: 14px;
    font-family: ${(props) => props.theme.fonts.lighterFont};
    &:not(:last-child) {
        margin-bottom: 15px;
    }
`;

const Table: any = styled.table`
border-top: 1px solid ${(props) => props.theme.colors.accent};
table-layout: fixed;
width: 55%;
margin-left: auto;
min-width: 330px;
`;
const TableRow = styled.tr`
`;
const TableData = styled.td`
`;

const PriceTitle = styled.label`
    font-size: 12px;
`;

const Price = styled(PriceTitle as any)`
    flex: 1;
    text-align: right;
`;
const TotalPriceTitle = styled(PriceTitle as any)`
    font-size: 14px;
    font-family: ${(props) => props.theme.fonts.regularFont};
`;
const TotalPrice = styled(Price as any)`
    font-size: 14px;
    font-family: ${(props) => props.theme.fonts.boldFont};
`;
const mapStateToProps = (state: IGlobalStoreState, ownProps: any) => {
    const rowSlug = ownProps.match && ownProps.match.params && ownProps.match.params.rowSlug;
    return {
        eventSlug: ownProps.match && ownProps.match.params && ownProps.match.params.eventSlug,
        sectorSlug: ownProps.match && ownProps.match.params && ownProps.match.params.sectorSlug,
        rowSlug,
        tickets: ownProps.match && ownProps.match.params && (+ownProps.match.params.tickets || +rowSlug),
        distributorSlug: ownProps.match && ownProps.match.params && ownProps.match.params.distributorSlug,
        event: state.book.event,
        isLoading: state.app.isLoading,
        book: state.book,
        user: state.user,
        error: state.app.error,
        isRedirection: state.app.isRedirection,
        isFinalized: state.app.isFinalized,
        distribution: state.distribution,
        token: ownProps.match && ownProps.match.params && ownProps.match.params.token,
        quotaId: ownProps.match && ownProps.match.params && ownProps.match.params.quotaId,
    };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
    getEventById: (id: string, distributorSlug?: string, token?: string) => dispatch(getEventById(id, distributorSlug, token)),
    initBook: (event: IEventStoreState, sectorSlug?: string, seats?: ISeatStoreState[], token?: string) =>
        dispatch(initBook(event, sectorSlug, seats, token)),
    clearBook: () => dispatch(clearBook()),
    checkoutInit: () => dispatch(checkoutInit()),
    distributionInit: (distributorSlug: string) => dispatch(distributionInit(distributorSlug)),
    go: ownProps.history.push,
    replace: ownProps.history.replace,
    showAlert: (config: ResultInfoType) => dispatch(setResultInfo(config)),
    showSpinner: () => dispatch(showSpinner()),
    setQuota: (id: number) => dispatch(setQuota(id)),
    setSector: (slug: string) => dispatch(setSector(slug)),
    setRow: (slug: string) => dispatch(setSectorRow(slug)),
    setSeat: (slug: string) => dispatch(setSectorRowSeat(slug)),
    setTickets: (count: number) => dispatch(setTicketsCount(count)),
    getTicketQuotaByToken: (token: string) => dispatch(getTicketQuotaByShareToken(token)),
    hideAdButton: () => dispatch(hideAdButton()),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withTranslation()(BookPage)),
);
