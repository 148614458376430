import React, { useEffect, useState } from 'react';
import { ButtonBase, Theme, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { IGlobalStoreState } from '../../store';
import { hidePlayer, showPlayer } from '../../store/header/actions';
import { ModalBonusTicket } from './ModalBonusTicket';

const useStyles = makeStyles<undefined, 'imageBackdrop' | 'imageTitle'>()((theme: Theme, _params, classes) => ({
  button: {
    position: 'relative',
    height: '42px',
    '& .MuiTouchRipple-root': {
      borderRadius: '24px',
    },
    '&:hover': {
      zIndex: 1,
      [`& .${classes.imageBackdrop}`]: {
        opacity: 1,
      },
      [`& .${classes.imageTitle}`]: {
        opacity: 1,
      },
    },
  },
  imageBackdrop: {
    borderRadius: '24px',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  imageButton: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '24px',
  },
  imageTitle: {
    borderRadius: '24px',
    position: 'relative',
    top: '1px',
    textTransform: 'uppercase',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    opacity: 0.5,
  },
}));

const BONUS_TICKET_POPUP_KEY = 'bonus_ticket_popup';

export const SellEventButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isShowPlayer = useSelector<IGlobalStoreState, boolean>((state) => state.header.showPlayer);
  const { classes } = useStyles(undefined);
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleModalBtnClick = () => (isShowPlayer ? dispatch(hidePlayer()) : dispatch(showPlayer()));

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!params.has(BONUS_TICKET_POPUP_KEY)) return;

    setOpen(true);
  }, []);

  return (
    <>
      <ButtonBase
        focusRipple
        onClick={() => {
          history.replace(`?${BONUS_TICKET_POPUP_KEY}=true`);
          setOpen(true);
        }}
        className={classes.button}
      >
        <span className={classes.imageBackdrop} />
        <span className={classes.imageButton}>
          <Typography component="span" variant="subtitle2" color="inherit" noWrap className={classes.imageTitle}>
            GET A BONUS TICKET
          </Typography>
        </span>
      </ButtonBase>
      <ModalBonusTicket
        open={open}
        handleClose={() => {
          history.replace('/');
          setOpen(false);
        }}
      />
    </>
  );
};
