import React, { FC } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { Button, TextInput } from '../UI';
import { FieldError, Fieldset, FieldWrapper, FormError, FormInfo, IBaseFormProps } from './commonForm';
import { PopUp } from '../../store/animations';

export interface IEmailForm {
  email: string;
  emailConfirm: string;
}

interface IEmailFormProps extends IBaseFormProps<IEmailForm> {
  email?: string;
  warning?: string;
  emailPlaceholder?: string;
  emailConfirmPlaceholder?: string;
}

export const EmailForm: FC<IEmailFormProps> = ({
  email,
  emailPlaceholder,
  emailConfirmPlaceholder,
  warning,
  errors,
  onSubmit,
  submitTitle,
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t('EmailForm.EmailRequired')).email(t('EmailForm.EmailIncorrect')),
    emailConfirm: Yup.mixed().test(
      'match',
      t('EmailForm.EmailsDoNotMatch'),
      (emailConfirm, context) => emailConfirm === context.parent.email
    ),
  });

  return (
    <Formik
      initialValues={{ email, emailConfirm: email }}
      isInitialValid={!!email}
      validationSchema={validationSchema}
      onSubmit={(values: IEmailForm) => {
        if (onSubmit) {
          onSubmit(values);
        }
      }}
    >
      {({ isValid, handleChange, handleBlur, values, isSubmitting }) => (
        <StyledForm autoComplete="on">
          <StyledFieldset>
            <Field name="email">
              {({ field, form }) => (
                <AnimatePresence>
                  <FieldWrapper
                    noted={!!form.touched.email && !!form.errors && !!form.errors.email}
                    variants={PopUp}
                    initial={'enter'}
                    animate={'exit'}
                  >
                    <TextInput
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      {...field}
                      placeholder={emailPlaceholder}
                      error={!!form.touched.email && !!form.errors && !!form.errors.email}
                    />
                    <AnimatePresence>
                      {!!form.touched.email && !!form.errors && !!form.errors.email && (
                        <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                          {form.errors.email}
                        </FieldError>
                      )}
                    </AnimatePresence>
                  </FieldWrapper>
                </AnimatePresence>
              )}
            </Field>
            <Field name="emailConfirm">
              {({ field, form }) => (
                <AnimatePresence>
                  <FieldWrapper
                    noted={!!form.touched.emailConfirm && !!form.errors && !!form.errors.emailConfirm}
                    variants={PopUp}
                    initial={'enter'}
                    animate={'exit'}
                  >
                    <TextInput
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.emailConfirm}
                      {...field}
                      placeholder={emailConfirmPlaceholder}
                      autoComplete="off"
                      onPaste={(e) => {
                        if (e) {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                      }}
                      error={!!form.touched.emailConfirm && !!form.errors && !!form.errors.emailConfirm}
                    />
                    <AnimatePresence>
                      {!!form.touched.emailConfirm && !!form.errors && !!form.errors.emailConfirm && (
                        <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                          {form.errors.emailConfirm}
                        </FieldError>
                      )}
                    </AnimatePresence>
                  </FieldWrapper>
                </AnimatePresence>
              )}
            </Field>
          </StyledFieldset>
          <AnimatePresence>
            {!!errors && !!errors.form && (
              <FormError variants={PopUp} initial={'enter'} animate={'exit'} key="form-error">
                {t(errors.form.details) || t(errors.form.message)}
              </FormError>
            )}
            {warning && <FormInfo key="form-error">{warning}</FormInfo>}
          </AnimatePresence>
          <Button type="submit" disabled={!isValid || isSubmitting}>
            {submitTitle}
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledFieldset = styled(Fieldset)`
  flex: 1;
`;

const StyledForm = styled(Form)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
