import { BaseApiService } from '../baseApiService';
import { RegionalInfoStoreState, IRegionalInfoStoreState } from '../../store/region/types';
import { AppSettingsState, IAppSettingsState } from '../../store/appSettings/types';

export class HomeApiService extends BaseApiService {
  private static _instance: HomeApiService;

  constructor(config?: any) {
    if (typeof HomeApiService._instance === 'undefined') {
      super(config);
      HomeApiService._instance = this;
    }
    return HomeApiService._instance;
  }

  public async getRegion(): Promise<IRegionalInfoStoreState> {
    const response = await this.get('/regionalInfo');
    return new RegionalInfoStoreState(response.data);
  }

  public async getAppSettings(): Promise<IAppSettingsState> {
    const response = await this.get(`appsettings`);
    return new AppSettingsState(response.data);
  }
}
