import { Reducer } from 'redux';
import dayjs from 'dayjs';

import { IRegionalInfoStoreState, RegionalInfoStoreState, RegionActionTypes } from './types';
import Constants from '../constants';

const appReducer: Reducer<IRegionalInfoStoreState> = (
  state: IRegionalInfoStoreState = new RegionalInfoStoreState(),
  action
) => {
  switch (action.type) {
    case RegionActionTypes.REGION_SET:
      return <IRegionalInfoStoreState>{
        ...state,
        ...action.payload,
        lastCheckedAt: dayjs().add(Constants.RegionalInfoExpiresInHours, 'hour'),
      };
    default:
      return state;
  }
};

export default appReducer;
