import { IEventStoreState, ISeatStoreState } from '../event/types';

export interface IBookStoreState {
  event: IEventStoreState;
  sectorSlug?: string;
  seats: ISeatStoreState[];
  token?: string;
}

export class BookStoreState implements IBookStoreState {
  public event: IEventStoreState;
  public sectorSlug?: string;
  public seats: ISeatStoreState[];
  public token?: string;

  constructor() {
    this.event = null;
    this.sectorSlug = '';
    this.seats = [];
    this.token = '';
  }
}

export const enum BookActionTypes {
  BOOK_INIT = '@@book/INIT',
  BOOK_CLEAR = '@@book/CLEAR',
  BOOK_SET_QUOTA = '@@book/SET_QUOTA',
  BOOK_SET_SECTOR = '@@book/SET_SECTOR',
  BOOK_SET_SECTOR_ROW = '@@book/SET_SECTOR_ROW',
  BOOK_SET_SECTOR_ROW_SEAT = '@@book/SET_SECTOR_ROW_SEATS',
  BOOK_SET_TICKETS_COUNT = '@@book/SET_TICKETS_COUNT',
}
