import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { IGlobalStoreState } from '../../../store';
import { IEventStoreState, ISeatStoreState, SeatStoreState } from '../../../store/event/types';
import { IVenueSectorStoreState } from '../../../store/ticket/types';
import { getWidgetEvent } from '../../../store/event/actions';
import { Page, PageContent } from '../../../components/UI';
import { PlaceSelector, SchemaSectorSelector } from '../../../components/book';
import { clearBook, initBook } from '../../../store/book/actions';
import { EventHelper } from '../../../helpers';
import { IBookStoreState } from '../../../store/book/types';
import { AlertType, KeyErrors } from '../../../store/enums';
import { hideAdButton } from '../../../store/header/actions';
import { useTranslation } from 'react-i18next';
import { setResultInfo } from '../../../store/resultInformarion/actions';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { TicketsCountSelector } from './widgetComponents/ticketsCountSelector';
import { WidgetFooter } from './widgetComponents/widgetFooter';

const WidgetBookPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const event = useSelector<IGlobalStoreState, IEventStoreState>((state) => state.book.event);
  const isLoading = useSelector<IGlobalStoreState, boolean>((state) => state.app.isLoading);
  const book = useSelector<IGlobalStoreState, IBookStoreState>((state) => state.book);
  const isRedirection = useSelector<IGlobalStoreState>((state) => state.app.isRedirection);

  const [isCheckoutInitiating, setIsCheckoutInitiating] = useState(false);
  const [seats, setSeats] = useState<ISeatStoreState[]>([]);
  const [sectorSlug, setSectorSlug] = useState(null);
  const [quotaId, setQuotaId] = useState(null);
  const [showSeatsSelector, setShowSeatsSelector] = useState(false);

  const { getResultStatusRoute } = useRoutesHelper();
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    initEvent(token);
    dispatch(hideAdButton);
  }, []);

  const handleTicketsCount = useCallback(
    (count: number) => {
      let filteredSeatsByQuotaId = seats.filter((s) => s.quotaId !== quotaId);
      for (let index = 0; index < count; index++) {
        const sector = event.sectors.find((x) => x.slug === sectorSlug);
        filteredSeatsByQuotaId = [...filteredSeatsByQuotaId, new SeatStoreState(quotaId, sectorSlug, sector.title)];
      }
      setSeats(filteredSeatsByQuotaId);
    },
    [seats, event?.slug]
  );

  const renderPlaceSelector = (event: IEventStoreState, sector: IVenueSectorStoreState) => {
    if (!event || !sector) return null;
    return (
      <PlaceSelector
        venueSlug={event.venue.slug}
        sector={sector}
        selectedSeats={seats}
        maxSelectCount={event.maxPurchase}
        onChange={(quotaId: number, sectorSlug: string, rowSlug: string, seatSlug: string) =>
          handlePlaceSelect(quotaId, sectorSlug, rowSlug, seatSlug)
        }
      />
    );
  };

  const handleSchemaSectorSelect = (sectorSlug: string) => {
    setSectorSlug(sectorSlug);
    setShowSeatsSelector(false);
    const sector = event && event.sectors && event.sectors.find((x) => x.slug === sectorSlug);
    const quota = sector && sector.ticketQuotas[0];
    if (quota !== null) {
      setQuotaId(quota.id);
    }
    setShowSeatsSelector(true);
  };

  const handlePlaceSelect = (quotaId: number, sectorSlug: string, rowSlug: string, seatSlug: string) => {
    if (seats.some((s) => s.sectorSlug === sectorSlug && s.rowSlug === rowSlug && s.seatSlug === seatSlug)) {
      const seatsFiltered = seats.filter(
        (s) => s.sectorSlug !== sectorSlug || s.rowSlug !== rowSlug || s.seatSlug !== seatSlug
      );
      setSeats(seatsFiltered);
      return;
    }
    const sector = event.sectors.find((x) => x.slug === sectorSlug);
    book.event.maxPurchase > seats.length
      ? setSeats([...seats, new SeatStoreState(quotaId, sectorSlug, sector.title, rowSlug, seatSlug)])
      : seats;
    return;
  };

  const initEvent = useCallback(
    (token: string) => {
      (async () => dispatch(getWidgetEvent(token)))().then((event: any) => {
        if (!event || !event.slug) {
          dispatch(clearBook());
          dispatch(
            setResultInfo({
              type: AlertType.Error,
              key: KeyErrors.EventNotFound,
              title: 'Alert.SomethingWentWrong',
              message: 'Alert.CheckLinkNoEventFound',
            })
          );
          history.replace(getResultStatusRoute(AlertType.Error, KeyErrors.EventNotFound));
          return;
        }
        dispatch(clearBook());
        dispatch(initBook(event));
      });
    },
    [token]
  );

  if (isRedirection || isLoading || !event || isCheckoutInitiating) return null;

  const sector = event && event.sectors && event.sectors.find((x) => x.slug === sectorSlug);
  const isInteractiveSchema = !!sector && !!sector.schema;

  let posterUrl = event.optionPosterUrl;
  if (posterUrl === '') {
    posterUrl = event.posterUrl;
  }

  return (
    <Page
      isForm
      title={`${t('WidgetBook.TicketPurchase')} | ${event.title} - ${event.subtitle}`}
      description={EventHelper.getMetaDescription(event)}
      keywords={[event.title, event.venue.title, t('WidgetBook.BuyTicket'), t('WidgetBook.Concert')]}
      imageUrl={posterUrl}
    >
      <PageContent>
        {
          <SchemaSectorSelector
            venue={event.venue}
            sectors={event.sectors}
            selectedSector={sectorSlug}
            onChange={(sectorSlug: string) => handleSchemaSectorSelect(sectorSlug)}
          />
        }
        {isInteractiveSchema && showSeatsSelector && renderPlaceSelector(event, sector)}
        {!isInteractiveSchema && (
          <TicketsCountSelector
            handleTicketsCount={handleTicketsCount}
            event={event}
            sector={sector}
            seats={seats}
            quotaId={quotaId}
          />
        )}
      </PageContent>
      <WidgetFooter
        book={book}
        event={event}
        seats={seats}
        sectorSlug={sectorSlug}
        isLoading={isLoading}
        setIsCheckoutInitiating={setIsCheckoutInitiating}
        token={token}
      />
    </Page>
  );
};

export default WidgetBookPage;
