import { Dispatch } from 'redux';
import { action } from 'typesafe-actions';

import { HeaderActionTypes } from './types';

export const show = () => action(HeaderActionTypes.HEADER_SHOW);
export const hide = () => action(HeaderActionTypes.HEADER_HIDE);
export const playerShow = () => action(HeaderActionTypes.PLAYER_SHOW);
export const playerHide = () => action(HeaderActionTypes.PLAYER_HIDE);
export const citySelectorShow = () => action(HeaderActionTypes.CITYSELECTOR_SHOW);
export const citySelectorHide = () => action(HeaderActionTypes.CITYSELECTOR_HIDE);

export const showAdButton = () => async (dispatch: Dispatch) => {
  dispatch(show());
};

export const hideAdButton = () => (dispatch: Dispatch) => {
  dispatch(hide());
};

export const showPlayer = () => async (dispatch: Dispatch) => {
  dispatch(playerShow());
};

export const hidePlayer = () => (dispatch: Dispatch) => {
  dispatch(playerHide());
};

export const showCitySelector = () => async (dispatch: Dispatch) => {
  dispatch(citySelectorShow());
};

export const hideCitySelector = () => (dispatch: Dispatch) => {
  dispatch(citySelectorHide());
};
