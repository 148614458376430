import * as React from 'react';
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { Translation, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { IGlobalStoreState } from '../../../store';
import { Button, PageContent } from '../../../components/UI';
import { IUserStoreState } from '../../../store/user/types';
import { Utils } from '../../../services';
import { UserStats } from '../../../components/user';
import { Routes } from '../../../store/routes';
import { getCurrentAccount } from '../../../store/user/actions';
import { useRoutesHelper } from '../../../helpers/routesHelper';

const ProfilePage = () => {
  const isInitializing = useSelector<IGlobalStoreState, boolean>((state) => state.app.isInitializing);
  const isAuthorized = useSelector<IGlobalStoreState, boolean>((state) => state.app.isAuthorized);
  const user = useSelector<IGlobalStoreState, IUserStoreState>((state) => state.user);
  const isLoading = useSelector<IGlobalStoreState, boolean>((state) => state.app.isLoading);

  const history = useHistory();
  const { t } = useTranslation();

  const { getProfileRoute } = useRoutesHelper();

  useEffect(() => {
    if (!isAuthorized || !user.phoneNumberConfirmed) {
      history.push(getProfileRoute());
      return;
    }
  }, [getProfileRoute, history, isAuthorized, user.phoneNumberConfirmed]);

  const handleEditBtnClick = (e, path: string) => {
    if (e) {
      e.preventDefault();
    }
    history.push(path);
  };

  if (isInitializing || !user || isLoading) {
    return null;
  }
  return (
    <PageContent center>
      <Helmet>
        <title>{Utils.toPageTitle(user.fullName || user.referralSlug)}</title>
      </Helmet>
      <AvatarWrapper>
        <ProfileImage>
          {user.imageUrl && <Avatar src={user.imageUrl} alt={t('Profile.YourAvatar')} />}
          {!user.imageUrl && <ProfileImageTitle>{t<string>('Profile.Avatar')}</ProfileImageTitle>}
        </ProfileImage>
        <Button
          round
          edit
          title={t('Profile.EditProfile')}
          onClick={(e) => handleEditBtnClick(e, Routes.EditProfile)}
        />
      </AvatarWrapper>
      <UserInfo>
        <FullName>{user.fullName}</FullName>
        <UserStats stats={user.stats} />
      </UserInfo>
      <ButtonsWrapper>
        <Button title={t('Profile.GoToMyEvents')} onClick={(e) => handleEditBtnClick(e, Routes.UserEvents)}>
          {t<string>('Profile.MyEvents')}
        </Button>
        <Button title={t('Profile.GoToPurchasedTickets')} onClick={(e) => handleEditBtnClick(e, Routes.UserTickets)}>
          {t<string>('Profile.MyTickets')}
        </Button>
      </ButtonsWrapper>
    </PageContent>
  );
};

const AvatarWrapper = styled.section`
  position: relative;
`;

const UserInfo = styled.section`
  width: 80%;
`;

const FullName = styled.h1`
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.boldFont};
  margin: 10px 0;
  text-align: center;
`;

const ButtonsWrapper = styled.section`
  width: 100%;

  > a,
  > button {
    margin-top: 10px;
  }
`;

const ProfileImage = styled.div`
  width: 130px;
  height: 130px;
  border: 1px solid ${(props) => props.theme.colors.text};
  align-self: center;
  border-radius: 50%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

const ProfileImageTitle = styled.span`
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
`;

const Avatar = styled.img`
  flex: 1;
  object-fit: contain;
  z-index: 1;
  max-width: 130px;
  max-height: 130px;
`;

export default ProfilePage;
