import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { AnimatePresence, motion } from 'framer-motion';
import { withTranslation } from 'react-i18next';
import { IGlobalStoreState } from '../../../store';
import { Page, PageContent } from '../../../components/UI';
import { AlertType, KeyErrors, SellStatus } from '../../../store/enums';
import { getTicketById, sellTicket } from '../../../store/user/actions';
import { EmailForm, IEmailForm, PurchaseForm } from '../../../components/forms';
import { PopUp } from '../../../store/animations';
import { IErrorStoreState } from '../../../store/app/types';
import { Utils } from '../../../services';
import { EventHelper } from '../../../helpers';
import { EventSellHeader } from '../../../components/event/eventSellHeader';
import { IProfileTicketStoreState } from '../../../store/ticket/ProfileTicketStoreState';
import RouteService from '../../../services/routeService';
import { setResultInfo } from '../../../store/resultInformarion/actions';
import { ResultInfoType } from '../../../store/resultInformarion/types';

interface IUserTicketSellDetailsPageProps {
  masterTicketId: string;
  ticketId: string;
  isFinalized: boolean;
  isAuthorized: boolean;
  error: IErrorStoreState;

  getTicketById: (masterTicketId: string, ticketId?: string) => Promise<IProfileTicketStoreState>;
  sellTicket: (ticketId: string, email: string, price: number) => Promise<IProfileTicketStoreState>;
  go: (url: string) => void;
  setResultInfo: (config: ResultInfoType) => void;
  t: (key: string) => string;
}

interface IUserTicketSellDetailsPageState {
  ticket: IProfileTicketStoreState;
  errors: any;
  resendInSecs: number;
  email: string;
}

class UserTicketSellDetailsPage extends React.Component<
  IUserTicketSellDetailsPageProps,
  IUserTicketSellDetailsPageState
> {
  private routeSrv = new RouteService();

  constructor(props: IUserTicketSellDetailsPageProps) {
    super(props);

    this.state = {
      ticket: null,
      errors: null,
      resendInSecs: 0,
      email: null,
    };
  }

  componentWillMount() {
    if (this.props.isFinalized) {
      this.initTicket(this.props.masterTicketId, this.props.ticketId);
    }
  }

  componentWillReceiveProps(nextProps: IUserTicketSellDetailsPageProps) {
    const isFinalizedChanged = this.props.isFinalized !== nextProps.isFinalized && nextProps.isFinalized;
    const isIdChanged =
      this.props.masterTicketId !== nextProps.masterTicketId || this.props.ticketId !== nextProps.ticketId;
    const isErrorChanged = !Utils.isEqual(this.props.error, nextProps.error);
    if (isIdChanged || isFinalizedChanged) {
      this.initTicket(nextProps.masterTicketId, nextProps.ticketId);
    }
    if (!nextProps.isAuthorized) {
      this.props.go(this.routeSrv.getProfileRoute());
    }
    if (isErrorChanged) {
      this.setState({ errors: { form: nextProps.error } });
    }
  }

  render() {
    const ticket = this.state.ticket;
    if (!this.props.isFinalized || !ticket || !ticket.masterTicketId) {
      return null;
    }
    const event = ticket.event;
    const isSale = ticket.status === SellStatus.InitiatedSale;
    const isGetToken = isSale && !!ticket.token;
    return (
      <React.Fragment>
        {!isGetToken && (
          <Page
            title={`${this.props.t('TicketSellDetails.TicketIssue')} | ${EventHelper.getEventDetailsMetaDescription(
              event
            )}`}
          >
            <EventSellHeader ticket={ticket} />
            <Content roundCorners>
              <>{!this.state.email && this.renderEmailForm()}</>
            </Content>
          </Page>
        )}
        {isGetToken && (
          <Page
            title={`${this.props.t('TicketSellDetails.TicketIssue')} | ${EventHelper.getEventDetailsMetaDescription(
              event
            )}`}
          >
            <EventSellHeader ticket={ticket} />
            <PageContent roundCorners>{this.renderCheckoutContent()}</PageContent>
          </Page>
        )}
      </React.Fragment>
    );
  }

  private renderCheckoutContent() {
    return <React.Fragment>{<PurchaseForm token={this.state.ticket.token} />}</React.Fragment>;
  }

  private renderEmailForm() {
    return (
      <AnimatePresence>
        <FormWrapper key="form-wrapper" variants={PopUp} initial={'enter'} animate={'exit'}>
          <Label>{this.props.t('TicketSellDetails.TheTicketWillBeSentToEmail')}</Label>
          <EmailForm
            errors={this.state.errors}
            emailPlaceholder={this.props.t('TicketSellDetails.EnterTheRecipientsEmailAddress')}
            emailConfirmPlaceholder={this.props.t('TicketSellDetails.RetryRecipientEmail')}
            submitTitle={this.props.t('TicketSellDetails.GoToEnteringCardDetails')}
            onSubmit={(formModel: IEmailForm) => this.handleEmailFormSubmit(formModel)}
          />
        </FormWrapper>
      </AnimatePresence>
    );
  }

  private initTicket(masterTicketId: string, ticketId?: string) {
    if (!masterTicketId) {
      return;
    }
    this.props.getTicketById(masterTicketId, ticketId).then((ticket) => {
      if (!ticket || !ticket.masterTicketId) {
        this.props.setResultInfo({
          type: AlertType.Error,
          key: KeyErrors.TicketNotFound,
          title: 'Alert.WaitForTheServerToCompleteThePayment',
        });
        this.props.go(this.routeSrv.getResultStatusRoute(AlertType.Error, KeyErrors.TicketNotFound));
        return;
      }
      this.setState({ ticket });
    });
  }

  private handleEmailFormSubmit(formModel: IEmailForm) {
    if (formModel) {
      this.props.sellTicket(this.props.ticketId, formModel.email, this.state.ticket.price).then((ticket) => {
        this.setState({ ticket });
        this.props.go(this.routeSrv.getUserTicketSellRoute(ticket.masterTicketId, ticket.ticketId));
      });
    }
  }
}

const Content = styled(PageContent as any)`
  padding-top: 0;
  flex: 0;
  min-height: 250px;
`;

const Label = styled.h3`
  font-size: 12px;
  margin: 0 0 15px 0;
  text-align: center;
`;

const FormWrapper = styled(motion.div)`
  margin-top: 60px;
`;

const mapStateToProps = (state: IGlobalStoreState, ownProps: any) => {
  return {
    masterTicketId: ownProps.match && ownProps.match.params && ownProps.match.params.masterTicketId,
    ticketId: ownProps.match && ownProps.match.params && ownProps.match.params.ticketId,
    isFinalized: state.app.isFinalized,
    error: state.app.error,
    isAuthorized: state.app.isAuthorized,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  getTicketById: (masterTicketId: string, ticketId?: string) => dispatch(getTicketById(masterTicketId, ticketId)),
  sellTicket: (ticketId: string, email: string, price: number) => dispatch(sellTicket(ticketId, email, price)),
  go: ownProps.history.replace,
  setResultInfo: (config: ResultInfoType) => dispatch(setResultInfo(config)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserTicketSellDetailsPage)));
