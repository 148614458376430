import { Reducer } from 'redux';
import dayjs from 'dayjs';

import { IUserStoreState, UserActionTypes } from './types';
import { IEventStoreState } from '../event/types';
import { IProfileTicketStoreState } from '../ticket/ProfileTicketStoreState';

export const userReducer: Reducer<IUserStoreState> = (state: IUserStoreState = null, action) => {
  switch (action.type) {
    case UserActionTypes.USER_INIT: {
      return action.payload;
    }
    case UserActionTypes.USER_UPDATE: {
      return <IUserStoreState>{ ...state, ...action.payload };
    }
    case UserActionTypes.USER_CLEAR: {
      return null;
    }
    case UserActionTypes.USER_PHONE_CODE_SEND: {
      return <IUserStoreState>{ ...state, phoneCodeSentAt: dayjs(), phoneCodeSentMessage: action.payload };
    }
    case UserActionTypes.PHONE_CURRENT_UPDATE: {
      return <IUserStoreState>{ ...state, phoneNumber: action.payload, phoneNumberConfirmed: false };
    }
    default:
      return state;
  }
};

export const userEventsReducer: Reducer<Array<IEventStoreState>> = (state: Array<IEventStoreState> = [], action) => {
  switch (action.type) {
    case UserActionTypes.USER_EVENTS_INIT: {
      return [...action.payload];
    }
    case UserActionTypes.USER_EVENTS_CLEAR: {
      return [];
    }
    default:
      return state;
  }
};

export const userTicketsReducer: Reducer<Array<IProfileTicketStoreState>> = (
  state: Array<IProfileTicketStoreState> = [],
  action
) => {
  switch (action.type) {
    case UserActionTypes.USER_TICKETS_INIT: {
      const tickets = action.payload.filter((t: IProfileTicketStoreState) => !t.ticketId);
      for (const newTicket of action.payload.filter((t: IProfileTicketStoreState) => !!t.ticketId)) {
        let ticketToAdd = state.find((t: IProfileTicketStoreState) => t.ticketId === newTicket.ticketId);
        if (ticketToAdd) {
          ticketToAdd = { ...ticketToAdd, ...newTicket };
        } else {
          ticketToAdd = newTicket;
        }
        tickets.push(ticketToAdd);
      }
      return [...tickets];
    }
    case UserActionTypes.USER_TICKETS_CLEAR: {
      return [];
    }
    case UserActionTypes.USER_TICKETS_UPDATE: {
      const tickets = state.slice();
      tickets.forEach((t) => {
        if (t.ticketId === action.payload.ticketId) {
          t = action.payload;
        }
      });
      return [...tickets];
    }
    default:
      return state;
  }
};
