import * as React from 'react';
import { useCallback, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import { motion } from 'framer-motion';
import { Page } from '../../../components/UI';
import { Routes } from '../../../store/routes';
import RegistrationPage from './registrationPage';
import LoginPage from './loginPage';
import EmailConfirmPage from './emailConfirmPage';
import ProfilePage from './profilePage';
import EditProfilePage from './editProfilePage';
import UserEventsPage from './userEventsPage';
import UserTicketsPage from './userTicketsPage';
import PasswordRecoveryPage from './password/passwordRecoveryPage';
import ResetPasswordPage from './password/resetPasswordPage';
import { Pages } from '../../../store/enums';
import { getCurrentAccount, getUserEvents, getUserTickets } from '../../../store/user/actions';
import { AppTheme } from '../../../settings/appTheme';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { distributionInit } from '../../../store/distribution/actions';

const UserPage = () => {
  const { isActivePage } = useRoutesHelper();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { distributorSlug } = useParams<{ distributorSlug: string }>();

  useEffect(() => {
    if (!distributorSlug) {
      return;
    }

    dispatch(distributionInit(distributorSlug));
    history.push(`${Routes.Registration}`);
  }, []);

  const init = useCallback(() => {
    if (isActivePage(Pages.User, Pages.Profile)) {
      dispatch(getCurrentAccount());
    } else if (isActivePage(Pages.User, Pages.UserEvents)) {
      dispatch(getUserEvents());
    } else if (isActivePage(Pages.User, Pages.UserTickets)) {
      dispatch(getUserTickets());
    }
  }, [dispatch, isActivePage]);

  useEffect(() => {
    init();
  }, []);

  useLayoutEffect(() => {
    init();
  }, [location.pathname, init]);

  return (
    <Page bg={AppTheme.img.backgrounds.bg2} fullsize>
      <Route
        render={({ location }) => (
          <RouteContainer key={location.pathname}>
            <Switch location={location}>
              <Route key="registration" exact path={Routes.Registration} component={RegistrationPage} />
              <Route key="login" exact path={Routes.Login} component={LoginPage} />
              <Route key="profile" exact path={Routes.Profile} component={ProfilePage} />
              <Route key="edit-profile" exact path={Routes.EditProfile} component={EditProfilePage} />
              <Route key="email-confirm" exact path={Routes.UserConfirmEmail} component={EmailConfirmPage} />
              <Route key="user-events" exact path={Routes.UserEvents} component={UserEventsPage} />
              <Route key="user-tickets" exact path={Routes.UserTickets} component={UserTicketsPage} />
              <Route key="password-recovery" exact path={Routes.PasswordRecovery} component={PasswordRecoveryPage} />
              <Route key="password-reset" exact path={Routes.ResetPassword} component={ResetPasswordPage} />
              <Redirect to={'/'} />
            </Switch>
          </RouteContainer>
        )}
      />
    </Page>
  );
};

const RouteContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export default UserPage;
