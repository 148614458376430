import { BaseApiService } from '../baseApiService';
import {
  IChangePasswordInfo,
  IResetPasswordConfirmInfo,
  IUpdateUserStoreState,
  IUserRegistrationStoreState,
  IUserStoreState,
  UserStoreState,
} from '../../store/user/types';
import { ActionResponseStoreState, IActionResponseStoreState } from '../../store/app/types';
import { AuthStoreState, IAuthStoreState } from '../../store/auth/types';
import { RequestType } from '../../store/enums';

export class AccountApiService extends BaseApiService {
  private static _instance: AccountApiService;

  constructor(config?: any) {
    if (typeof AccountApiService._instance === 'undefined') {
      super(config);
      AccountApiService._instance = this;
    }
    return AccountApiService._instance;
  }

  public async registerAccount(model: IUserRegistrationStoreState): Promise<IActionResponseStoreState> {
    const response = await this.post('/signup', model, RequestType.signup);
    return new ActionResponseStoreState(response.data);
  }

  public async getCurrentAccount(): Promise<IUserStoreState> {
    const response = await this.get('/me');
    return new UserStoreState(response.data);
  }

  public async updatePhone(phoneNumber: string): Promise<IActionResponseStoreState> {
    const response = await this.post('/phone', { phoneNumber });
    return new ActionResponseStoreState(response.data);
  }

  public async confirmPhone(code: string, phoneNumber: string): Promise<void> {
    const response = await this.post('/confirmphone', { code, phoneNumber }, null, RequestType.confirmPhone);
    return Promise.resolve();
  }

  public async updateCurrentAccount(model: IUpdateUserStoreState): Promise<IUserStoreState> {
    const response = await this.post('/me', model);
    return new UserStoreState(response.data);
  }

  public async resetPassword(email: string, returnUrl: string): Promise<IActionResponseStoreState> {
    const response = await this.post('/resetpassword', { email, returnUrl });
    return new ActionResponseStoreState(response.data);
  }

  public async confirmResetPassword(model: IResetPasswordConfirmInfo): Promise<IAuthStoreState> {
    const response = await this.post('/confirmresetpassword', model);
    return new AuthStoreState(response.data);
  }

  public async changePassword(model: IChangePasswordInfo): Promise<IActionResponseStoreState> {
    const response = await this.post('/changepassword', model);
    return new ActionResponseStoreState(response.data);
  }
}
