import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';

import { IAuthStoreState, AuthActionTypes } from './types';

const init = (payload: IAuthStoreState) => action(AuthActionTypes.AUTH_INIT, payload);
const clear = () => action(AuthActionTypes.AUTH_CLEAR);

export const authorize =
  (token: IAuthStoreState) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(init(token));
  };

export const deauthorize =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(clear());
  };
