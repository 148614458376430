import { BaseApiService } from '../baseApiService';
import { ContractInfo, ContractInfoContainer, IMenuStoreState, MenuStoreState } from '../../store/menu/types';
import { EventHelper } from '../../helpers';

export class PublicContractApiService extends BaseApiService {
  private static _instance: PublicContractApiService;

  constructor(config?: any) {
    if (typeof PublicContractApiService._instance === 'undefined') {
      super(config);
      PublicContractApiService._instance = this;
    }
    return PublicContractApiService._instance;
  }

  public async getUserAgreements(): Promise<IMenuStoreState> {
    const domain = window.location.hostname;
    const resultDomainType = EventHelper.getDomainType(domain);
    const response = await this.get(`?dtype=${resultDomainType}`);

    const bonusPrograms = response.data['BonusProgram']
      ? <ContractInfoContainer[]>(
          response.data['BonusProgram'].map((t) => new ContractInfoContainer(t.Language, t.FileUrl))
        )
      : ([] as ContractInfoContainer[]);
    const publicContracts = response.data['PublicContract']
      ? <ContractInfoContainer[]>(
          response.data['PublicContract'].map((t) => new ContractInfoContainer(t.Language, t.FileUrl))
        )
      : ([] as ContractInfoContainer[]);

    const result = new ContractInfo(publicContracts, bonusPrograms);

    const menuState = new MenuStoreState(result);

    return menuState;
  }
}
