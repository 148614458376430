import { Box, Grid, Link, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FC, memo } from 'react';
import dayjs from 'dayjs';
import { SkeletonEventCarousel } from './skeletonEventCarousel';
import { ItemEventCarouselType } from './events';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { AppTheme } from '../../settings/appTheme';
import Constants from '../../store/constants';

const useStyles = makeStyles<void, 'gridTileImage' | 'gradientImg'>()((_theme, _params, classes) => ({
  box: {
    overflow: 'scroll',
    overflowY: 'hidden',
    marginTop: 20,
    height: '250px',
    '&::-webkit-scrollbar': {
      height: `${AppTheme.scrollbarHeight}`,
    },
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    width: '100%',
    marginBottom: 20,
  },
  gridTile: {
    height: '175px',
    cursor: 'pointer',
    margin: '0px 24px 12px 0px',
    width: '240px',
  },
  gridTileImage: {
    height: '170px',
    width: '240px',
    objectFit: 'cover',
    borderRadius: '8px',
    boxShadow: '0px 8px 15px rgb(0 0 0 / 45%)',
  },
  gradientImg: {
    position: 'absolute',
    width: '240px',
    height: '70px',
    left: '0px',
    top: '100px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
    opacity: 0.7,
    borderRadius: '0px 0px 8px 8px',
  },
  subTitleText: {
    color: '#6B6B6B',
  },
  gridText: {
    marginTop: 10,
    marginBottom: 6,
  },
  linkStyle: {
    '&:hover': {
      color: 'rgb(178, 178, 178)',
      filter: 'brightness(60%)',
    },
  },
  gridImg: {
    position: 'relative',
    '&:hover': {
      [`& .${classes.gridTileImage}, .${classes.gradientImg}`]: {
        filter: 'brightness(60%)',
      },
    },
  },
  titleText: {
    fontSize: 16,
    lineHeight: '16px',
  },
}));

export const EventCarousel: FC<EventCarouselType> = memo(({ events, isLoading, getItemLink }) => {
  const { classes } = useStyles();
  const { handleEventLink } = useRoutesHelper();

  return (
    <Box className={`carousel ${classes.box}`}>
      <Grid container className={classes.gridList}>
        {isLoading && <SkeletonEventCarousel skeletonCount={3} />}

        {events?.length > 0 &&
          !isLoading &&
          events.map((item: ItemEventCarouselType) => (
            <Grid item key={item.key} className={classes.gridTile}>
              <Link
                underline={'none'}
                onClick={() => handleEventLink(item.domainTypes, getItemLink(item.key))}
                className={classes.linkStyle}
              >
                <Grid container direction="row">
                  <Grid item lg={12} xs={12} sm={12} md={12} xl={12} className={classes.gridImg}>
                    <img src={item.imageUrl} alt={item.title} className={classes.gridTileImage} />
                    <div className={classes.gradientImg} />
                  </Grid>
                  <Grid item lg={12} xs={12} sm={12} md={12} xl={12} className={classes.gridText}>
                    <Typography className={classes.titleText}>{item.title}</Typography>
                  </Grid>
                  <Grid item lg={12} xs={12} sm={12} md={12} xl={12} style={{ position: 'relative' }}>
                    <Typography variant="caption" className={classes.subTitleText}>
                      {`${dayjs(item.date).format(Constants.DateFormat)}, ${item.city}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
});

type EventCarouselType = {
  events: ItemEventCarouselType[];
  getItemLink: (itemKey: string) => string;
  reloadDependencyValue?: any;
  isLoading: boolean;
};
