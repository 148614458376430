import { Box, Grid, Link, Typography, Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useRouteService } from '../../../helpers/customHooks';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { AppTheme } from '../../../settings/appTheme';
import Constants from '../../../store/constants';
import { ItemEventCarouselType } from '../events';

const useStyles = makeStyles<void, 'gridTileImage' | 'gradientImg' | 'titleEvent'>()((_theme, _params, classes) => ({
  box: {
    overflow: 'scroll',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      height: `${AppTheme.scrollbarHeight}`,
    },
    marginBottom: 50,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    marginBottom: '7px',
    marginTop: '7px',
  },
  gridTile: {
    height: '88px',
  },
  gridContainer: {
    width: '266px',
    cursor: 'pointer',
  },
  gridTileImage: {
    height: '88px',
    width: '88px',
    objectFit: 'cover',
    borderRadius: '10px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
  },
  skeletonImage: {
    height: '88px',
    width: '88px',
    borderRadius: '10px',
  },
  skeletonText: {
    height: 20,
    marginTop: 2,
    marginLeft: 5,
  },
  textEvent: {
    fontSize: '14px',
    lineHeight: '14px',
    color: '#6B6B6B',
  },
  linkStyle: {
    '&:hover': {
      [`& .${classes.gridTileImage}, .${classes.gradientImg}, .${classes.titleEvent}`]: {
        filter: 'brightness(60%)',
      },
    },
  },
  gridImg: {
    position: 'relative',
  },
  gradientImg: {
    position: 'absolute',
    width: '88px',
    height: '32px',
    left: '0px',
    bottom: '5px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
    opacity: 0.7,
    borderRadius: '0px 0px 8px 8px',
  },
  titleEvent: {
    fontSize: '16px',
    lineHeight: '16px',
    marginBottom: '12px',
  },
  linesContainer: {
    marginBottom: 29,
  },
}));

export const DatepickerCarousel: FC<DatepickerEventCarouselType> = ({
  load,
  reloadDependencyValue,
  defaultRowsCount,
}) => {
  const [rowsCount, setRowsCount] = useState(defaultRowsCount);
  const [events, setEvents] = useState<ItemEventCarouselType[][]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { classes } = useStyles();

  const { getEventRoute } = useRouteService();
  const { handleEventLink } = useRoutesHelper();

  useEffect(() => {
    setIsLoading(true);

    const loadEvents = async () => {
      const result = await load();

      const resultEvent = [];
      const defaultColumnCount = 3;

      let internalRowCount = defaultRowsCount;

      if (result.length > defaultColumnCount) {
        while (result.length / internalRowCount < defaultColumnCount && internalRowCount > 2) {
          internalRowCount--;
          setRowsCount(internalRowCount);
        }
      }

      for (let row = 0; row < internalRowCount; row++) {
        const rows = [];
        for (let eventIndex = 0; eventIndex < result.length; eventIndex += internalRowCount) {
          const event = result[row + eventIndex];
          if (event) {
            rows.push(event);
          }
        }

        resultEvent.push(rows);
      }

      setEvents(resultEvent);
      setIsLoading(false);
    };
    loadEvents();
  }, [reloadDependencyValue]);

  const renderEvent = (item: ItemEventCarouselType) => (
    <Grid item key={item.key} className={classes.gridTile}>
      <Link
        underline={'none'}
        className={classes.linkStyle}
        onClick={() => handleEventLink(item.domainTypes, getEventRoute(item.key))}
      >
        <Grid container className={classes.gridContainer} direction="row" justifyContent="center" alignItems="center">
          <Grid item lg={4} xs={4} sm={4} md={4} xl={4} className={classes.gridImg}>
            <img src={item.imageUrl} alt={item.title} className={classes.gridTileImage} />
            <div className={classes.gradientImg} />
          </Grid>
          <Grid item lg={8} xs={8} sm={8} md={8} xl={8} style={{ margin: 0 }}>
            <Grid container style={{ marginLeft: '10px' }}>
              <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                <Typography className={classes.titleEvent} variant="subtitle2">
                  {item.title}
                </Typography>
              </Grid>
              <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                <Typography variant="caption" className={classes.textEvent}>
                  {dayjs(item.date).format(Constants.DateFormat)}
                </Typography>
              </Grid>
              <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                <Typography variant="caption" className={classes.textEvent}>
                  {item.city}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Link>
    </Grid>
  );

  const renderSkeleton = (index: number) => (
    <Grid key={index} item className={classes.gridTile}>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ minWidth: '266px' }}
      >
        <Grid item lg={4} xs={4} sm={4} md={4} xl={4}>
          <Skeleton animation="wave" variant="rectangular" className={classes.skeletonImage} />
        </Grid>
        <Grid item lg={8} xs={8} sm={8} md={8} xl={8}>
          <Skeleton animation="wave" variant="rectangular" width="75%" className={classes.skeletonText} />
          <Skeleton animation="wave" variant="rectangular" width="65%" className={classes.skeletonText} />
          <Skeleton animation="wave" variant="rectangular" width="55%" className={classes.skeletonText} />
        </Grid>
      </Grid>
    </Grid>
  );

  const renderLine = (items: ItemEventCarouselType[]) => (
    <Grid container className={classes.gridList}>
      {!isLoading && items.length > 0 && items.map((item) => renderEvent(item))}
      {isLoading && Array.from(new Array(rowsCount)).map((value, index) => renderSkeleton(index))}
    </Grid>
  );

  return (
    <Box className={`carousel ${classes.box}`}>
      <Grid container className={classes.linesContainer}>
        {events?.map((item, index) => (
          <Grid key={index} item>
            {renderLine(item)}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

type DatepickerEventCarouselType = {
  reloadDependencyValue?: any;
  load: () => Promise<ItemEventCarouselType[]>;
  defaultRowsCount: number;
};
