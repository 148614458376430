import { Reducer } from 'redux';

import { IEventStoreState, EventActionTypes } from './types';

const eventsReducer: Reducer<Array<IEventStoreState>> = (
  state: Array<IEventStoreState> = new Array<IEventStoreState>(),
  action
) => {
  switch (action.type) {
    case EventActionTypes.EVENTS_INIT: {
      return [...action.payload];
    }
    default:
      return state;
  }
};

export default eventsReducer;
