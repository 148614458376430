import * as React from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { PopUp } from '../../store/animations';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  round?: boolean;
  outline?: boolean;
  copy?: boolean;
  small?: boolean;
  edit?: boolean;
  advertising?: boolean;
  width?: string;
}

interface IButtonState {
  active?: boolean;
  hover?: boolean;
  isTouched?: boolean;
}

export class Button extends React.Component<IButtonProps, IButtonState> {
  private mouseUpTimer: any;
  private touchEndTimer: any;

  constructor(props: IButtonProps) {
    super(props);
    this.state = {
      active: false,
      hover: false,
      isTouched: false,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.mouseUpTimer);
    clearTimeout(this.touchEndTimer);
  }

  render() {
    return (
      <AnimatePresence>
        <StyledButton
          variants={PopUp}
          initial={'enter'}
          animate={'exit'}
          disabled={this.props.disabled}
          type={this.props.type}
          round={this.props.round}
          outline={this.props.outline}
          copy={this.props.copy}
          title={this.props.title}
          active={this.state.active}
          hover={this.state.hover}
          small={this.props.small}
          edit={this.props.edit}
          advertising={this.props.advertising}
          width={this.props.width}
          onClick={(e) => {
            if (this.props.onClick) {
              this.props.onClick(e);
            }
          }}
          onMouseEnter={(e) => this.handleMouseEnter(e)}
          onMouseLeave={(e) => this.handleMouseLeave(e)}
          onMouseDown={(e) => this.handleMouseDown(e)}
          onMouseUp={(e) => this.handleMouseUp(e)}
          onTouchStart={(e) => this.handleTouchStart(e)}
          onTouchEnd={(e) => this.handleTouchEnd(e)}
        >
          {this.props.children}
        </StyledButton>
      </AnimatePresence>
    );
  }

  private handleMouseEnter(e: any) {
    if (this.props.disabled || this.state.isTouched) {
      return;
    }
    if (e) {
      e.preventDefault();
    }
    this.setState({ hover: true });
  }

  private handleMouseLeave(e: any) {
    if (this.props.disabled || this.state.isTouched) {
      return;
    }
    if (e) {
      e.preventDefault();
    }
    this.setState({ hover: false, active: false });
  }

  private handleMouseDown(e: any) {
    if (this.props.disabled) {
      return;
    }
    if (e) {
      e.preventDefault();
    }
    this.setState({ active: true });
  }

  private handleMouseUp(e: any) {
    if (this.props.disabled) {
      return;
    }
    if (e) {
      e.preventDefault();
    }
    this.mouseUpTimer = setTimeout(() => {
      this.setState({ active: false });
    }, 200);
  }

  private handleTouchStart(e: any) {
    if (this.props.disabled) {
      return;
    }
    this.setState({ active: true, hover: false });
  }

  private handleTouchEnd(e: any) {
    if (this.props.disabled) {
      return;
    }
    this.touchEndTimer = setTimeout(() => {
      this.setState({ active: false, hover: false, isTouched: true });
    }, 200);
  }
}

const StyledButton = styled(motion.button as any)<IButtonProps>`
  display: inline-block;
  color: ${(props) => {
    let result = props.theme.colors.text;
    if ((props.active || props.hover) && !props.disabled) {
      result = props.theme.colors.text;
    } else if (props.outline) {
      result = props.disabled ? props.theme.colors.accentDisabled : props.theme.colors.accent;
    } else if (props.disabled) {
      result = props.theme.colors.textSecond;
    }
    return `${result} !important`;
  }};
  background-color: ${(props) => {
    let result = props.edit ? props.theme.colors.bg : props.theme.colors.accent;
    if (props.active && !props.disabled && !props.advertising) {
      result = props.theme.colors.accentDimmed;
    } else if (props.hover && !props.disabled) {
      result = '#D4A108';
    } else if (props.outline) {
      result = 'transparent';
    } else if (props.disabled) {
      result = props.theme.colors.accentDisabled;
    } else if (props.advertising) {
      result = props.theme.colors.accent;
    }
    return `${result} !important`;
  }};
  cursor: ${(props) => {
    if (props.disabled) {
      return 'not-allowed';
    } else {
      return 'pointer';
    }
  }};
  transition: background-color 0.2s ease-in;
  transition: color 0.2s ease-in;
  transition: width 0.2s ease-in;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-family: ${(props) => (props.round ? props.theme.fonts.fontLighter : props.theme.fonts.boldFont)};
  line-height: 15px;
  position: ${(props) => props.edit && 'absolute'};
  padding: ${(props) => (props.copy || props.small || props.edit ? '0' : props.advertising ? '11px' : '15px')};
  right: ${(props) => props.edit && 0};
  bottom: ${(props) => props.edit && 0};
  width: ${(props) => {
    if (props.copy) {
      return '45px';
    } else if (props.small) {
      return '24px';
    } else if (props.edit) {
      return '35px';
    } else if (props.advertising) {
      return '210px';
    } else {
      return props.width || '100%';
    }
  }};
  height: ${(props) => {
    if (props.copy) {
      return '45px';
    } else if (props.small) {
      return '24px';
    } else if (props.edit) {
      return '35px';
    } else if (props.advertising) {
      return '36px';
    } else {
      return 'unset';
    }
  }};
  border-radius: ${(props) => (props.round ? '50%' : '24px')};
  border: ${(props) => {
    if (props.outline) {
      return `1px solid ${props.disabled ? props.theme.colors.accentDisabled : props.theme.colors.accent}`;
    } else if (props.edit) {
      return `1px solid ${props.theme.colors.text}`;
    } else if (props.advertising) {
      return `1px solid ${props.theme.colors.accent}`;
    } else {
      return 'none';
    }
  }};
  outline: none;
  z-index: ${(props) => props.edit && 1};

  &:before {
    content: ${(props) => (props.copy || props.edit) && '""'};
    background: url(${(props) => (props.copy ? props.theme.img.icons.copy : props.theme.img.icons.edit)}) center
      no-repeat;
    width: ${(props) => (props.copy ? '25px' : '20px')};
    height: ${(props) => (props.copy ? '25px' : '20px')};
    display: inline-block;
    background-size: contain;
    filter: ${(props) => props.edit && (props.active || props.hover) && 'brightness(0)'};
  }
`;

export default Button;
