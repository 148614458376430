import dayjs from 'dayjs';

import { IVenueSectorStoreState, VenueSectorStoreState } from '../ticket/types';
import { IPaymentStoreState, PaymentStoreState } from '../payment/types';

export interface IEventCategory {
  id: number;
  slug: string;
  title: string;
  imageUrl: string;
  eventsCount: number;
}

export class EventCategory implements IEventCategory {
  id: number;
  slug: string;
  title: string;
  imageUrl: string;
  eventsCount: number;

  constructor(dto: any) {
    this.id = dto && dto.Id ? dto.Id : 0;
    this.title = dto && dto.Title ? dto.Title : '';
    this.slug = dto && dto.Slug ? dto.Slug : '';
    this.imageUrl = dto && dto.ImageUrl ? dto.ImageUrl : '';
    this.eventsCount = dto && dto.EventsCount ? dto.EventsCount : 0;
  }
}

export interface ICategorizedEvent {
  categoryName: string;
  events: IEventShortInformation[];
}

export class CategorizedEvent implements ICategorizedEvent {
  categoryName: string;
  events: IEventShortInformation[];

  constructor(dto: any) {
    this.categoryName = dto && dto.CategoryName ? dto.CategoryName : '';
    this.events = dto && dto.Events ? dto.Events.map((item) => new EventShortInformation(item)) : [];
  }
}

export interface IEventShortInformation {
  title: string;
  slug: string;
  posterUrl: string;
  date: Date;
  city: string;
  categoryId: number;
  domainTypes: number[];
}

export class EventShortInformation implements IEventShortInformation {
  title: string;
  slug: string;
  posterUrl: string;
  date: Date;
  city: string;
  categoryId: number;
  domainTypes: number[];

  constructor(dto: any) {
    this.title = dto && dto.Title ? dto.Title : '';
    this.city = dto && dto.City ? dto.City : '';
    this.slug = dto && dto.Slug ? dto.Slug : '';
    this.posterUrl = dto && dto.PosterUrl ? dto.PosterUrl : '';
    this.date = dto && dto.EventDate ? dto.EventDate : null;
    this.categoryId = dto && dto.CategoryId ? dto.CategoryId : null;
    this.domainTypes = dto && dto.DomainTypes ? dto.DomainTypes : [];
  }
}

export interface ILocationShortInformation {
  id: string;
  country: string;
  city: string;
}

export class LocationShortInformation implements ILocationShortInformation {
  id: string;
  country: string;
  city: string;

  constructor(dto: any) {
    this.id = dto && dto.Id ? dto.Id : '';
    this.country = dto && dto.Country ? dto.Country : '';
    this.city = dto && dto.City ? dto.City : '';
  }
}

export interface IEventStoreState {
  title: string;
  subtitle: string;
  slug: string;
  posterUrl: string;
  optionPosterUrl: string;
  date: dayjs.Dayjs;
  venue: IVenueStoreState;
  artist: IArtistStoreState;
  sectors: Array<IVenueSectorStoreState>;
  paymentMethods: Array<IPaymentStoreState>;
  distributorComission: IDistributorComissionStoreState;
  notAvailableForPurchase: boolean;
  notAvailableForPurchaseReason: string;
  maxPurchase: number;
  isDistributionAvailable: boolean;
  domainTypes: number[];
}

export class EventStoreState implements IEventStoreState {
  title: string;
  subtitle: string;
  slug: string;
  posterUrl: string;
  optionPosterUrl: string;
  date: dayjs.Dayjs;
  venue: IVenueStoreState;
  artist: IArtistStoreState;
  sectors: Array<IVenueSectorStoreState>;
  paymentMethods: Array<IPaymentStoreState>;
  distributorComission: IDistributorComissionStoreState;
  notAvailableForPurchase: boolean;
  notAvailableForPurchaseReason: string;
  maxPurchase: number;
  isDistributionAvailable: boolean;
  domainTypes: number[];

  constructor(dto?: any) {
    this.title = dto && dto.Title ? dto.Title : '';
    this.subtitle = dto && dto.SubTitle ? dto.SubTitle : '';
    this.slug = dto && dto.Slug ? dto.Slug : '';
    this.posterUrl = dto && dto.PosterUrl ? dto.PosterUrl : '';
    this.optionPosterUrl = dto && dto.OptionPosterUrl ? dto.OptionPosterUrl : '';
    this.date = dto && dto.EventDate ? dayjs(dto.EventDate) : dayjs();
    this.venue = dto && dto.Venue ? new VenueStoreState(dto.Venue) : null;
    this.artist = dto && dto.Artist ? new ArtistStoreState(dto.Artist) : null;
    this.sectors = dto && dto.Sectors ? dto.Sectors.map((item) => new VenueSectorStoreState(item)) : [];
    this.paymentMethods =
      dto && dto.PaymentMethods ? dto.PaymentMethods.map((item) => new PaymentStoreState(item)) : [];
    this.distributorComission =
      dto && dto.DistributorComission ? new DistributorComissionStoreState(dto.DistributorComission) : null;
    this.notAvailableForPurchase = dto && dto.NotAvailableForPurchase ? dto.NotAvailableForPurchase : false;
    this.notAvailableForPurchaseReason =
      dto && dto.NotAvailableForPurchaseReason ? dto.NotAvailableForPurchaseReason : '';
    this.maxPurchase = dto && dto.MaxPurchase ? dto.MaxPurchase : 0;
    this.isDistributionAvailable = dto && dto.IsDistributionAvailable ? dto.IsDistributionAvailable : false;
    this.domainTypes = dto && dto.DomainTypes ? dto.DomainTypes : [];
  }
}

export interface IVenueStoreState {
  title: string;
  slug: string;
  address: string;
  city: string;
  country: string;
  imageUrl: string;
  schema: ISchemaInformation;
}

export class VenueStoreState implements IVenueStoreState {
  title: string;
  slug: string;
  address: string;
  city: string;
  country: string;
  imageUrl: string;
  schema: ISchemaInformation;

  constructor(dto?: any) {
    this.title = dto && dto.Title ? dto.Title : '';
    this.slug = dto && dto.Slug ? dto.Slug : '';
    this.address = dto && dto.Address ? dto.Address : '';
    this.city = dto && dto.City ? dto.City : '';
    this.country = dto && dto.Country ? dto.Country : '';
    this.imageUrl = dto && dto.ImageUrl ? dto.ImageUrl : '';
    this.schema = dto && dto.Schema ? new SchemaInformation(dto.Schema) : null;
  }
}

export interface IArtistStoreState {
  title: string;
  slug: string;
  imageUrl: string;
}

export class ArtistStoreState implements IArtistStoreState {
  title: string;
  slug: string;
  imageUrl: string;

  constructor(dto?: any) {
    this.title = dto && dto.Title ? dto.Title : '';
    this.slug = dto && dto.Slug ? dto.Slug : '';
    this.imageUrl = dto && dto.ImageUrl ? dto.ImageUrl : '';
  }
}

export interface IDistributorComissionStoreState {
  currentComission: number;
  nextTicketComission: number;
  ticketsSold: number;
}

export class DistributorComissionStoreState implements IDistributorComissionStoreState {
  currentComission: number;
  nextTicketComission: number;
  ticketsSold: number;

  constructor(dto?: any) {
    this.currentComission = dto && dto.CurrentComission ? dto.CurrentComission : 0;
    this.nextTicketComission = dto && dto.NextTicketComission ? dto.NextTicketComission : 0;
    this.ticketsSold = dto && dto.TicketsSold ? dto.TicketsSold : 0;
  }
}

export interface ISeatStoreState {
  quotaId: number;
  sectorSlug: string;
  sectorTitle: string;
  rowSlug: string;
  seatSlug: string;
}

export class SeatStoreState implements ISeatStoreState {
  quotaId: number;
  sectorSlug: string;
  sectorTitle: string;
  rowSlug: string;
  seatSlug: string;

  constructor(quotaId: number, sectorSlug: string, sectorTitle: string, row?: string, seat?: string) {
    this.quotaId = quotaId;
    this.sectorSlug = sectorSlug;
    this.sectorTitle = sectorTitle;
    this.rowSlug = row;
    this.seatSlug = seat;
  }
}

export interface ISchema {
  id: number;
  schema: string;
  version: number;
}

export class Schema implements ISchema {
  id: number;
  schema: string;
  version: number;

  constructor(dto: any) {
    this.id = dto && dto.Id ? dto.Id : null;
    this.schema = dto && dto.Schema ? dto.Schema : null;
    this.version = dto && dto.Version ? dto.Version : null;
  }
}

export interface ISchemaInformation {
  id: number;
  version: number;
}

export class SchemaInformation implements ISchemaInformation {
  id: number;
  version: number;

  constructor(dto: any) {
    this.id = dto && dto.Id ? dto.Id : null;
    this.version = dto && dto.Version ? dto.Version : null;
  }
}

export const enum EventActionTypes {
  EVENTS_INIT = '@@events/INIT',
}

export enum EventType {
  None = 0,
  Popular = 1,
  Tour = 2,
  Near = 4,
  Category = 8,
  Date = 16,
}
