import { BaseApiService } from '../baseApiService';
import { AuthStoreState, IAuthStoreState, ITokenRequestStoreState } from '../../store/auth/types';
import { RequestType } from '../../store/enums';

export class AuthApiService extends BaseApiService {
  private static _instance: AuthApiService;

  constructor(config?: any) {
    if (typeof AuthApiService._instance === 'undefined') {
      super(config);
      AuthApiService._instance = this;
    }
    return AuthApiService._instance;
  }

  public async token(model: ITokenRequestStoreState): Promise<IAuthStoreState> {
    const response = await this.post('/token', model, null, RequestType.token);
    return new AuthStoreState(response.data);
  }

  public async googleToken(model: { access_token: string; client_id: string }) {
    const response = await this.post('/googleToken', model, null, RequestType.token);
    return new AuthStoreState(response.data);
  }

  public async facebookToken(model: { access_token: string; client_id: string }) {
    const response = await this.post('/facebookToken', model, null, RequestType.token);
    return new AuthStoreState(response.data);
  }
}
