import { Dispatch } from 'redux';
import { action } from 'typesafe-actions';

import { AppActionTypes, IErrorStoreState } from './types';
import { getEvents } from '../event/actions';
import { getCurrentUser } from '../user/actions';
import { IGlobalStoreState } from '..';
import { ThunkService } from '../../services';
import { AppSettingsActionTypes, IAppSettingsState } from '../appSettings/types';
import { getContracts } from '../menu/actions';

export const init = () => action(AppActionTypes.APP_INIT);
export const finalize = () => action(AppActionTypes.APP_FINALIZE);
export const showSpinner = () => action(AppActionTypes.SPINNER_SHOW);
export const hideSpinner = () => action(AppActionTypes.SPINNER_HIDE);
export const authorize = () => action(AppActionTypes.APP_AUTHORIZE);
export const deauthorize = () => action(AppActionTypes.APP_DEAUTHORIZE);
export const setError = (payload: IErrorStoreState) => action(AppActionTypes.ERROR_SET, payload);
export const clearError = () => action(AppActionTypes.ERROR_CLEAR);
export const redirect = () => action(AppActionTypes.APP_REDIRECTION);

const appSettingInit = (settings: IAppSettingsState) => action(AppSettingsActionTypes.APP_SETTINGS_INIT, settings);

export const initApp =
  (city: string) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState): Promise<any> => {
    dispatch(init());
    try {
      await dispatch(await getSettings());
      if (!getState().appSettings.useNewDashboard) {
        await dispatch(getEvents(city));
      }
      await dispatch(getCurrentUser());
      await dispatch(await getContracts());
      dispatch(hideSplash());
    } finally {
      await dispatch(finalize());
    }
  };

export const getSettings =
  () =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IGlobalStoreState,
    thunkService: ThunkService
  ): Promise<IAppSettingsState> => {
    const appSettings = await thunkService.api.home.getAppSettings();
    dispatch(appSettingInit(appSettings));
    return appSettings;
  };

export const hideSplash = () => (): void => {
  document.body.classList.remove('splash-show');
};

export const redirectApp =
  (url: string) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    await dispatch(redirect());
    location.replace(url);
    return Promise.resolve();
  };
