import { Reducer } from 'redux';

import { IDistributionStoreState, DistributionStoreState, DistributionActionTypes } from './types';

const distributionReducer: Reducer<IDistributionStoreState> = (
  state: IDistributionStoreState = new DistributionStoreState(),
  action
) => {
  switch (action.type) {
    case DistributionActionTypes.DISTRIBUTION_INIT:
      return { ...state, ...action.payload };
    case DistributionActionTypes.DISTRIBUTION_CLEAR:
      return new DistributionStoreState();
    default:
      return state;
  }
};

export default distributionReducer;
