import { BaseApiService } from '../baseApiService';
import { EventStoreState, IEventStoreState } from '../../store/event/types';
import { AppSettingsState, IAppSettingsState } from '../../store/appSettings/types';
import EventHelper from '../../helpers/eventHelper';

export class WidgetApiService extends BaseApiService {
  private static _instance: WidgetApiService;

  constructor(config?: any) {
    if (typeof WidgetApiService._instance === 'undefined') {
      super(config);
      WidgetApiService._instance = this;
    }
    return WidgetApiService._instance;
  }

  public async getWidgetEvent(token: string): Promise<IEventStoreState> {
    const domain = window.location.hostname;
    const resultDomainType = EventHelper.getDomainType(domain);
    const response = await this.get(`book/details/${token}?dtype=${resultDomainType}`);
    return new EventStoreState(response.data);
  }

  public async getAppSettings(token: string): Promise<IAppSettingsState> {
    const response = await this.get(`app/settings/${token}`);
    return new AppSettingsState(response.data);
  }
}
