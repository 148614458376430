import { IEventDetailsStoreState } from '../../../../store/event/eventDetails/EventDetailsStoreState';
import { EventHelper } from '../../../../helpers';
import { isEmpty } from 'lodash';
import { Button, PageFooter } from '../../../../components/UI';
import * as React from 'react';
import { FC, memo, MouseEvent } from 'react';
import { startDistribute } from '../../../../store/event/actions';
import { Routes } from '../../../../store/routes';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalStoreState } from '../../../../store';
import { IEventStoreState } from '../../../../store/event/types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useRoutesHelper } from '../../../../helpers/routesHelper';

type EventDetailsFooterType = {
  event: IEventDetailsStoreState;
  isInitializing: boolean;
};

export const EventDetailsFooter: FC<EventDetailsFooterType> = memo(({ event, isInitializing }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { getBookRoute } = useRoutesHelper();

  const isAuthorized = useSelector<IGlobalStoreState, boolean>((state) => state.app.isAuthorized);
  const userEvents = useSelector<IGlobalStoreState, Array<IEventStoreState>>((state) => state.userEvents);

  if (!event) {
    return null;
  }
  const isDomain = isEmpty(event.domains);

  const isDistributed = EventHelper.isEventDetailsDistributed(event, userEvents);
  const sellTicketButtonTitle = isDistributed ? t('Event.SellTicket') : t('Event.BecomeDistributor');
  const showDistributionButton = isAuthorized && !isInitializing && event.isDistributionAvailable;
  const isBookingEnabled = !event.notAvailableForPurchase;

  const getBookTicketsButtonTitle = () => {
    let bookTicketsButtonTitle = t('Event.BuyTicket');

    if (!isBookingEnabled) {
      bookTicketsButtonTitle = t(event.notAvailableForPurchaseReason);
    }
    if (isDomain) {
      bookTicketsButtonTitle = t('Event.TemporarilyUnavailable');
    }

    return bookTicketsButtonTitle;
  };

  const bookTicketsButtonTitle = getBookTicketsButtonTitle();

  const handleOnBookClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
    }
    history.push(getBookRoute(event.slug, null));
  };

  const handleOnDistributeClick = (e: MouseEvent<HTMLButtonElement>, event: IEventDetailsStoreState) => {
    if (e) {
      e.preventDefault();
    }

    if (EventHelper.isEventDetailsDistributed(event, userEvents)) {
      history.push(getBookRoute(event.slug, null, null, null, null, true));
      return;
    }

    (async () => dispatch(startDistribute(event.slug)))().then(() => {
      history.push(Routes.UserEvents);
    });
  };

  return (
    <PageFooter>
      <Button disabled={!isBookingEnabled || isDomain} onClick={(e) => handleOnBookClick(e)}>
        {bookTicketsButtonTitle}
      </Button>
      {showDistributionButton && (
        <Button disabled={isDomain} onClick={(e) => handleOnDistributeClick(e, event)}>
          {sellTicketButtonTitle}
        </Button>
      )}
    </PageFooter>
  );
});
