import { useLocation } from 'react-router';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { GlobalStoreState } from '../store';
import { IMenuStoreState } from '../store/menu/types';
import RouteService from '../services/routeService';

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useRouteService = () => new RouteService();

export const useContractData = () => {
  const menuStorage = useSelector<GlobalStoreState, IMenuStoreState>((state) => state.menu).contracts;

  const bonusProgramItems = menuStorage?.bonusPrograms?.map((bonusProgram) => ({
    path: bonusProgram.fileUrl,
    title: bonusProgram.language,
  }));
  const publicContractItems = menuStorage?.publicContracts?.map((publicContract) => ({
    path: publicContract.fileUrl,
    title: publicContract.language,
  }));

  return { bonusProgramItems, publicContractItems };
};

export const useScript = (url, callback) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.defer = true;
    script.async = true;
    script.src = url;
    script.onload = callback;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [url, callback]);
};
