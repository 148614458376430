import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    height: '100%',
    touchAction: 'none',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserDrag: 'none',
  },
  wrapperContainer: {
    overflow: 'hidden',
    height: 'inherit',
    position: 'relative',
  },
  buttonGroup: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 10,
    height: 200,
    right: 0,
    top: 0,
  },
  buttonOutZoom: {
    '&.MuiButton-root': {
      color: theme.palette.secondary.light,
    },
    background: theme.palette.primary.light,
    minWidth: 25,
    height: 19,
    border: '1px solid #4B4B4B',
    borderRadius: 3,
    marginBottom: 6,
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '& .MuiTouchRipple-root': {
      display: 'flex',
      justifyContent: 'center',
      '&::after': {
        content: '""',
        width: '8.86px',
        display: 'block',
        background: theme.palette.primary.main,
        height: '1px',
        position: 'absolute',
        top: '50%',
      },
    },
  },
  buttonInZoom: {
    '&.MuiButton-root': {
      color: theme.palette.secondary.light,
    },
    background: theme.palette.primary.light,
    minWidth: 25,
    height: 19,
    border: '1px solid #4B4B4B',
    borderRadius: 3,
    marginBottom: 6,
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '& .MuiTouchRipple-root': {
      display: 'flex',
      justifyContent: 'center',
      '&::after': {
        content: '""',
        width: '8.86px',
        display: 'block',
        background: theme.palette.primary.main,
        height: '1px',
        position: 'absolute',
        top: '50%',
      },
      '&::before': {
        content: '""',
        width: '8.86px',
        display: 'block',
        background: theme.palette.primary.main,
        height: '1px',
        position: 'absolute',
        top: '50%',
        transform: 'rotate(90deg)',
      },
    },
  },
}));
