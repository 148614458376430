import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { IGlobalStoreState } from '../../../../store';
import { PageContent } from '../../../../components/UI';
import { IErrorStoreState } from '../../../../store/app/types';
import { PasswordRecoveryForm, PasswordRecoveryFormType } from '../../../../components/forms';
import { resetPassword } from '../../../../store/user/actions';
import { PopUp } from '../../../../store/animations';
import { AlertType, KeySuccess } from '../../../../store/enums';
import { useRoutesHelper } from '../../../../helpers/routesHelper';
import { useUtils } from '../../../../services/useUtils';

const PasswordRecoveryPage = () => {
  const { getResultStatusRoute } = useRoutesHelper();
  const { toPageTitle } = useUtils();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const isInitializing = useSelector<IGlobalStoreState, boolean>((state) => state.app.isInitializing);
  const error = useSelector<IGlobalStoreState, IErrorStoreState>((state) => state.app.error);

  const [errors, setErrors] = useState(null);

  useEffect(() => {
    setErrors({ form: error });
  }, [error?.message, error?.details]);

  if (isInitializing) {
    return null;
  }

  const handleFormSubmit = (formModel: PasswordRecoveryFormType) => {
    if (formModel) {
      (async () => dispatch(resetPassword(formModel.email)))().then(() => {
        history.replace(getResultStatusRoute(AlertType.Info, KeySuccess.EmailSent));
      });
    }
  };

  return (
    <AnimatePresence>
      <PageContent center variants={PopUp} initial={'enter'} animate={'exit'}>
        <Helmet>
          <title>{toPageTitle(t('PasswordRecovery.PasswordRecovery'))}</title>
        </Helmet>
        <Header>{t('PasswordRecovery.PasswordRecovery')}</Header>
        <PasswordRecoveryForm
          submitTitle={t('PasswordRecovery.Reestablish')}
          onSubmit={(formModel: PasswordRecoveryFormType) => handleFormSubmit(formModel)}
          errors={errors}
        />
      </PageContent>
    </AnimatePresence>
  );
};

const Header = styled.h1`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
`;

export default PasswordRecoveryPage;
