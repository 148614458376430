import * as React from 'react';
import { FC, memo } from 'react';
import { IPaymentInitiateTicketResult } from '../../../store/payment/types';
import Constants from '../../../store/constants';
import { ListItem } from './eventCheckoutHeader';
import EventInfoItem from '../eventInfoItem';
import { useUtils } from '../../../services/useUtils';

type RenderTicketsInfoPropsType = {
  tickets: Array<IPaymentInitiateTicketResult>;
};

export const RenderTicketsInfo: FC<RenderTicketsInfoPropsType> = memo(({ tickets }) => {
  const { getTicketsPostfixByCount } = useUtils();
  if (!tickets.length) {
    return null;
  }
  return (
    <ListItem>
      {tickets.map((ticket, index) => {
        const title = `${ticket.quantity} ${getTicketsPostfixByCount(ticket.quantity)} ${Constants.BulletDelimiter} 
          ${ticket.title}`;
        return <EventInfoItem key={index} icon={index === 0 ? 'person' : null} themed title={title} />;
      })}
    </ListItem>
  );
});
