import { Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import * as ReactGA from 'react-ga';

import { CheckoutActionTypes } from './types';
import * as PT from '../payment/types';
import { AlertType, KeyErrors, Pages, PaymentMethod } from '../enums';
import { ThunkService } from '../../services';
import { IGlobalStoreState } from '..';
import { hideSpinner, showSpinner } from '../app/actions';
import { distributionClear } from '../distribution/actions';
import { getCurrentAccount } from '../user/actions';
import { CheckoutHelper } from '../../helpers';
import { ISeatStoreState } from '../event/types';
import { AppSettings } from '../../settings/appSettings';
import RouteService from '../../services/routeService';
import { setResultInfo } from '../resultInformarion/actions';

const paymentInit = (payload: PT.IPaymentInitiateInfo) => action(CheckoutActionTypes.CHECKOUT_INITIATE, payload);
const finishPaymentInit = (payload: PT.IPaymentInitiateResult) =>
  action(CheckoutActionTypes.CHECKOUT_INITIATED, payload);
const paymentProcess = (payload: PT.IPaymentProcessInfo) => action(CheckoutActionTypes.CHECKOUT_PROCESS, payload);
const finishPaymentProcess = (payload: PT.IPaymentProcessResult) =>
  action(CheckoutActionTypes.CHECKOUT_PROCESSED, payload);
const paymentComplete = (payload: PT.IPaymentCompleteInfo) =>
  action(CheckoutActionTypes.CHECKOUT_COMPLETE, { ...payload });
const finishPaymentComplete = (payload: PT.IPaymentCompleteResult) =>
  action(CheckoutActionTypes.CHECKOUT_COMPLETED, payload);
const clearPayment = () => action(CheckoutActionTypes.CHECKOUT_CLEAR);
const setEmail = (payload: string) => action(CheckoutActionTypes.CHECKOUT_SET_EMAIL, payload);
const redo = () => action(CheckoutActionTypes.CHECKOUT_REDO);

export const checkoutInit =
  (usePoints?: boolean, force = true) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService): Promise<void> => {
    const state = getState();
    const { book } = state;
    const paymentModel = new PT.PaymentInitiateInfo();
    paymentModel.eventSlug = book.event.slug;
    paymentModel.paymentMethod = PaymentMethod.StripeBankCard;
    paymentModel.token = book.token;

    const tickets = GetTickets(book.seats);
    paymentModel.tickets = tickets;
    if (usePoints) {
      paymentModel.usePoints = usePoints;
    }

    if (CheckoutHelper.getIsDistributionInfoValid(state.distribution)) {
      paymentModel.distributorSlug = state.distribution.distributorSlug;
    } else {
      dispatch(distributionClear());
    }

    await dispatch(getCurrentAccount());

    dispatch(showSpinner());
    let canInit = true;
    if (force) {
      const { checkout } = state;
      if (CheckoutHelper.getIsProcessed(checkout) && !CheckoutHelper.getIsCompleted(checkout)) {
        const paymentCompleteModel = new PT.PaymentCompleteInfo();
        paymentCompleteModel.referenceNumber = checkout.process.referenceNumber;
        paymentCompleteModel.cancel = true;

        dispatch(paymentComplete(paymentCompleteModel));
        try {
          const paymentResult = await thunkService.api.payments.complete(paymentCompleteModel);
          if (!AppSettings.IS_DEV && paymentResult.isCompleted && paymentResult.isSuccess) {
            ReactGA.event({
              category: 'Payment',
              action: 'Success',
            });
          }
          if (!paymentResult.isProcessing) {
            dispatch(clearPayment());
          } else {
            canInit = false;
          }
          dispatch(finishPaymentComplete(paymentResult));
        } finally {
        }
      } else {
        dispatch(clearPayment());
      }
    }

    if (canInit) {
      dispatch(paymentInit(paymentModel));
      try {
        const paymentResult = await thunkService.api.payments.initiate(paymentModel);
        paymentResult.event = book.event;
        dispatch(finishPaymentInit(paymentResult));
      } finally {
        dispatch(hideSpinner());
      }
    } else {
      dispatch(hideSpinner());
    }
  };

export const widgetCheckoutInit =
  (token: string) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService): Promise<void> => {
    const state = getState();
    const { book } = state;
    const paymentModel = new PT.PaymentInitiateInfo();
    paymentModel.eventSlug = book.event.slug;
    paymentModel.paymentMethod = book.event.paymentMethods[0].method;
    const tickets = GetTickets(book.seats);
    paymentModel.tickets = tickets;
    paymentModel.token = token;

    dispatch(distributionClear());
    dispatch(getCurrentAccount());
    dispatch(showSpinner());

    const { checkout } = state;
    if (CheckoutHelper.getIsProcessed(checkout) && !CheckoutHelper.getIsCompleted(checkout)) {
      const paymentCompleteModel = new PT.PaymentCompleteInfo();
      paymentCompleteModel.referenceNumber = checkout.process.referenceNumber;
      paymentCompleteModel.cancel = true;

      dispatch(paymentComplete(paymentCompleteModel));

      const paymentResult = await thunkService.api.payments.complete(paymentCompleteModel);
      const isPaymentProcessing = paymentResult.isProcessing;
      if (!isPaymentProcessing) {
        dispatch(clearPayment());
      }
      dispatch(finishPaymentComplete(paymentResult));

      if (isPaymentProcessing) {
        dispatch(hideSpinner());
        return;
      }
    } else {
      dispatch(clearPayment());
    }

    dispatch(paymentInit(paymentModel));
    try {
      const paymentResult = await thunkService.api.payments.widgetInitiate(paymentModel);
      paymentResult.event = book.event;
      dispatch(finishPaymentInit(paymentResult));
    } finally {
      dispatch(hideSpinner());
    }
  };

export const widgetCheckoutProcess =
  (token: string) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IGlobalStoreState,
    thunkService: ThunkService
  ): Promise<PT.PaymentProcessResult> => {
    const routeSrv = new RouteService();
    const state = getState();
    const paymentModel = new PT.PaymentProcessInfo(state.checkout);

    paymentModel.token = token;
    paymentModel.paymentMethod = state.checkout.paymentMethod;
    paymentModel.returnUrl = `${routeSrv.getBaseUrl()}/${Pages.Widget}/${Pages.Book}/${token}/result`;
    paymentModel.returnErrorUrl = `${routeSrv.getBaseUrl()}/${Pages.Widget}/${Pages.Book}/${token}/result/${
      Pages.Failed
    }`;
    paymentModel.retailWebsite = routeSrv.getBaseUrl();

    dispatch(distributionClear());
    dispatch(showSpinner());
    dispatch(paymentProcess(paymentModel));
    try {
      const paymentResult = await thunkService.api.payments.widgetProcess(paymentModel);
      dispatch(finishPaymentProcess(paymentResult));
      return paymentResult;
    } catch (e) {
      const alertType = AlertType.Error;
      const alertKey = KeyErrors.PaymentFailed;
      const title = 'CheckoutWidget.Error';
      const message = e.response.data;

      dispatch(
        setResultInfo({
          type: alertType,
          key: alertKey,
          title,
          message,
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

export const checkoutSetEmail =
  (email: string) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(setEmail(email));
  };

export const checkoutProcess =
  (usePoints?: boolean) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IGlobalStoreState,
    thunkService: ThunkService
  ): Promise<PT.PaymentProcessResult> => {
    const routeSrv = new RouteService();
    const state = getState();
    const paymentModel = new PT.PaymentProcessInfo(state.checkout);
    const bookResultLinkTemplate = `${routeSrv.getBaseUrl()}/${Pages.Book}/result`;
    paymentModel.paymentMethod = PaymentMethod.StripeBankCard;
    paymentModel.returnUrl = `${bookResultLinkTemplate}`;
    paymentModel.returnErrorUrl = `${routeSrv.getBaseUrl()}/${Pages.Book}/result/${Pages.Failed}`;
    paymentModel.retailWebsite = routeSrv.getBaseUrl();
    if (usePoints) {
      paymentModel.usePoints = usePoints;
    }

    if (CheckoutHelper.getIsDistributionInfoValid(state.distribution)) {
      paymentModel.distributorSlug = state.distribution.distributorSlug;
    } else {
      dispatch(distributionClear());
    }

    dispatch(showSpinner());
    dispatch(paymentProcess(paymentModel));
    try {
      const paymentResult = await thunkService.api.payments.process(paymentModel);
      if (!AppSettings.IS_DEV) {
        ReactGA.event({
          category: 'Payment',
          action: 'Process',
        });
      }
      dispatch(finishPaymentProcess(paymentResult));
      return paymentResult;
    } catch (e) {
      // console.log("paymentProcess exception:", e)
    } finally {
      dispatch(hideSpinner());
    }
  };

export const checkoutComplete =
  (refNumber: string, cancel: boolean, needSpinner = true) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService): Promise<void> => {
    const { checkout } = getState();
    if (checkout.isChecking) {
      return null;
    }

    const paymentModel = new PT.PaymentCompleteInfo();
    paymentModel.referenceNumber = refNumber;
    paymentModel.cancel = cancel;

    if (needSpinner) {
      dispatch(showSpinner());
    }
    dispatch(paymentComplete(paymentModel));
    try {
      const paymentResult = await thunkService.api.payments.complete(paymentModel);
      if (!AppSettings.IS_DEV && paymentResult.isCompleted && paymentResult.isSuccess) {
        ReactGA.event({
          category: 'Payment',
          action: 'Success',
        });
      }
      if (!paymentResult.isProcessing && cancel) {
        dispatch(clearPayment());
      }
      dispatch(finishPaymentComplete(paymentResult));
    } finally {
      if (needSpinner) {
        dispatch(hideSpinner());
      }
    }
  };

export const checkoutClearPayment =
  (refNumber: string, needSpinner = true) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService): Promise<void> => {
    const { checkout } = getState();
    if (checkout.isChecking) {
      return null;
    }

    const paymentModel = new PT.PaymentCompleteInfo();
    paymentModel.referenceNumber = refNumber;
    paymentModel.cancel = true;

    if (needSpinner) {
      dispatch(showSpinner());
    }
    dispatch(paymentComplete(paymentModel));
    try {
      const paymentResult = await thunkService.api.payments.complete(paymentModel);
      dispatch(clearPayment());
      dispatch(finishPaymentComplete(paymentResult));
    } finally {
      if (needSpinner) {
        dispatch(hideSpinner());
      }
    }
  };

export const checkoutClear =
  () =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState): Promise<void> => {
    const state = getState();
    const { checkout } = state;
    if (CheckoutHelper.getIsProcessed(checkout) && !CheckoutHelper.getIsCompleted(checkout)) {
      await dispatch(checkoutComplete(checkout.process.referenceNumber, true, false));
    }
  };

export const redoCheckout =
  () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(redo());
  };

export const checkCurrentCheckout =
  () =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState): Promise<void> => {
    const state = getState();
    const { checkout } = state;
    if (CheckoutHelper.getIsProcessed(checkout) && !CheckoutHelper.getIsCompleted(checkout)) {
      await dispatch(checkoutComplete(checkout.process.referenceNumber, false, false));
    }
  };

function GetTickets(seats: ISeatStoreState[]) {
  const map = new Map<number, ISeatStoreState[]>();
  // group by quotaID
  seats.forEach((item) => {
    const key = item.quotaId;
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  const tickets = [];
  map.forEach((value, key) => {
    const quotaId = key;
    const ticket = new PT.PaymentInitiateTicketInfo();
    ticket.quotaId = quotaId;
    ticket.sector = new PT.PaymentInitiateSectorInfo();
    ticket.sector.sectorRows = [];
    value
      .filter((r) => !!r && !!r.sectorSlug)
      .forEach((item) => {
        ticket.sector.sectorSlug = item.sectorSlug;
        if (!!item.rowSlug && !!item.seatSlug) {
          ticket.sector.sectorRows.push(
            new PT.PaymentInitiateSectorRowInfo({
              rowSlug: item.rowSlug,
              rowSeatSlugs: [item.seatSlug],
            })
          );
          ticket.sector.quantity = ticket.sector.sectorRows.reduce(
            (result, current) => (result += current.rowSeatSlugs.length),
            0
          );
        } else {
          ticket.sector.quantity = seats.filter((s) => s.quotaId === quotaId).length;
        }
      });

    tickets.push(ticket);
  });
  return tickets;
}
