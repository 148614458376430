import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Page, PageContent, PageFooter, TextInput } from '../../../components/UI';
import { IUserStoreState } from '../../../store/user/types';
import CopyButton from '../../../components/UI/copyButton';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import UserHelper from '../../../helpers/userHelper';

interface IShareLinkPageProps {
  user: IUserStoreState;
  token: string;
  isRedirection: boolean;
  isLoading: boolean;
  eventSlug: string;
}

const ShareLinkPage: FC<IShareLinkPageProps> = ({ user, eventSlug, isLoading, token, isRedirection }) => {
  const { getProfileRoute } = useRoutesHelper();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (!user) {
      history.push(getProfileRoute());
      return;
    }
  }, [user]);

  if (isRedirection || isLoading || !token) {
    return null;
  }

  const renderFooter = () => {
    const referralLink = UserHelper.generateReferralUrlByToken(user, eventSlug, token);
    return (
      <Footer>
        <ReferralLinkInput type="text" readOnly tabIndex={-1} value={referralLink} />
        <CopyButton value={referralLink} title={t('Share.CopyLink')} text={t('Share.CopyLink')} />
      </Footer>
    );
  };

  return (
    <Page isForm title={t('Share.EventLink')}>
      <PageContent>
        <Title>{t<string>('Share.EventLink')}</Title>
      </PageContent>
      {renderFooter()}
    </Page>
  );
};

const Footer = styled(PageFooter as any)`
  padding: 0 25px 20px 25px;

  > button,
  > input {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;

const Title = styled.h1`
  font-size: 14px;
  margin: 0 0 15px 0;
`;

const ReferralLinkInput = styled(TextInput as any)`
  color: ${(props) => props.theme.colors.accent};
  padding: 15px 20px;

  &::placeholder {
    color: ${(props) => props.theme.colors.accent};
    opacity: 1;
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.text};
  }
`;

export default ShareLinkPage;
