import { Reducer } from 'redux';

import { IAuthStoreState, AuthActionTypes } from './types';

const authReducer: Reducer<IAuthStoreState> = (state: IAuthStoreState = null, action) => {
  switch (action.type) {
    case AuthActionTypes.AUTH_INIT:
      return { ...action.payload } as IAuthStoreState;
    case AuthActionTypes.AUTH_CLEAR:
      return null;
    default:
      return state;
  }
};

export default authReducer;
