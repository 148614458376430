import { Reducer } from 'redux';

import { ICheckoutStoreState, CheckoutStoreState, CheckoutActionTypes } from './types';

const checkoutReducer: Reducer<ICheckoutStoreState> = (
  state: ICheckoutStoreState = new CheckoutStoreState(),
  action
) => {
  switch (action.type) {
    case CheckoutActionTypes.CHECKOUT_INITIATE:
      return <ICheckoutStoreState>{ ...state, ...action.payload, process: null, complete: null, isRedoing: false };
    case CheckoutActionTypes.CHECKOUT_INITIATED:
      return <ICheckoutStoreState>{ ...state, init: action.payload, process: null, complete: null, isRedoing: false };
    case CheckoutActionTypes.CHECKOUT_PROCESS:
      return <ICheckoutStoreState>{ ...state, ...action.payload, complete: null, isRedoing: false };
    case CheckoutActionTypes.CHECKOUT_PROCESSED:
      return <ICheckoutStoreState>{ ...state, process: action.payload, complete: null, isRedoing: false };
    case CheckoutActionTypes.CHECKOUT_COMPLETE:
      return <ICheckoutStoreState>{
        ...state,
        complete: { ...state.complete, ...action.payload, isRedoing: false },
        isChecking: true,
      };
    case CheckoutActionTypes.CHECKOUT_COMPLETED:
      return <ICheckoutStoreState>{
        ...state,
        complete: { ...state.complete, result: action.payload, isRedoing: false },
        isChecking: false,
      };
    case CheckoutActionTypes.CHECKOUT_CLEAR:
      return new CheckoutStoreState();
    case CheckoutActionTypes.CHECKOUT_SET_EMAIL:
      return <ICheckoutStoreState>{ ...state, email: action.payload };
    case CheckoutActionTypes.CHECKOUT_REDO:
      return <ICheckoutStoreState>{ ...state, isRedoing: true };
    default:
      return state;
  }
};

export default checkoutReducer;
