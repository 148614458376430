import dayjs from 'dayjs';

import { IDistributionInfo, DistributionInfo } from '../user/types';

export interface IDistributionStoreState extends IDistributionInfo {
  expirationDate?: dayjs.Dayjs;
}

export class DistributionStoreState extends DistributionInfo implements IDistributionStoreState {
  expirationDate?: dayjs.Dayjs;

  constructor(distributionSlug?: string, expirationDate?: dayjs.Dayjs) {
    super(distributionSlug);
    this.expirationDate = expirationDate || null;
  }
}

export const enum DistributionActionTypes {
  DISTRIBUTION_INIT = '@@distribution/INIT',
  DISTRIBUTION_CLEAR = '@@distribution/CLEAR',
}
