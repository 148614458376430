import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import dayjs from 'dayjs';

import {
  IChangePasswordInfo,
  ISocialNetworkLogin,
  IUpdateUserStoreState,
  IUserStoreState,
  ResetPasswordConfirmInfo,
  UserActionTypes,
  UserRegistrationStoreState,
} from './types';
import { ThunkService } from '../../services';
import { IGlobalStoreState } from '..';
import { AlertType, KeySuccess } from '../enums';
import { TokenRequestStoreState } from '../auth/types';
import * as AppActions from '../app/actions';
import { authorize, deauthorize } from '../auth/actions';
import { IActionResponseStoreState } from '../app/types';
import { IEventStoreState } from '../event/types';
import { BoughtTicketInfo, IssueTicketInfo, ITicketStoreState, SellTicketInfo } from '../ticket/types';
import { checkoutClear } from '../checkout/actions';
import { IProfileTicketStoreState } from '../ticket/ProfileTicketStoreState';
import { AuthSettings } from '../../settings/appSettings';
import RouteService from '../../services/routeService';
import { setResultInfo } from '../resultInformarion/actions';
import { distributionClear } from '../distribution/actions';

const init = (payload: IUserStoreState) => action(UserActionTypes.USER_INIT, payload);
const update = (payload: IUserStoreState) => action(UserActionTypes.USER_UPDATE, payload);
const clear = () => action(UserActionTypes.USER_CLEAR);
const initEvents = (payload: Array<IEventStoreState>) => action(UserActionTypes.USER_EVENTS_INIT, payload);
const clearEvents = () => action(UserActionTypes.USER_EVENTS_CLEAR);
const initTickets = (payload: Array<IProfileTicketStoreState>) => action(UserActionTypes.USER_TICKETS_INIT, payload);
const clearTickets = () => action(UserActionTypes.USER_TICKETS_CLEAR);
const sendPhoneCode = (payload: string) => action(UserActionTypes.USER_PHONE_CODE_SEND, payload);
const updateTicket = (payload: IProfileTicketStoreState) => action(UserActionTypes.USER_TICKETS_UPDATE, payload);
const phoneCurrentUpdate = (payload: string) => action(UserActionTypes.PHONE_CURRENT_UPDATE, payload);

export const registerUser = (
  email: string,
  password: string
): ((dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => Promise<void>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    const routeSrv = new RouteService();
    const state = getState();
    const redirectUrl = routeSrv.getEmailConfirmationRedirectUrl();
    const model = new UserRegistrationStoreState(
      email,
      password,
      redirectUrl,
      true,
      state.distribution.distributorSlug
    );
    dispatch(AppActions.showSpinner());
    try {
      const actionResponse = await thunkService.api.account.registerAccount(model);
      if (actionResponse) {
        dispatch(
          setResultInfo({
            type: AlertType.Info,
            key: KeySuccess.EmailSent,
            title: actionResponse.message,
            message: actionResponse.details,
          })
        );
      }
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};

export const login = (
  email: string,
  password: string
): ((dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => Promise<void>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    const model = new TokenRequestStoreState();
    model.username = email;
    model.password = password;
    model.client_id = AuthSettings.CLIENT_ID;
    model.client_secret = AuthSettings.CLIENT_SECRET;
    model.grant_type = 'password';

    dispatch(AppActions.showSpinner());
    try {
      const token = await thunkService.api.auth.token(model);
      if (token) {
        dispatch(AppActions.authorize());
        dispatch(authorize(token));
        dispatch(distributionClear());
        await dispatch(getCurrentUser());
      }
    } catch {
      await dispatch(logout());
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};

export const googleLogin =
  (model: ISocialNetworkLogin) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(AppActions.showSpinner());
    const token = await thunkService.api.googleAuth.googleToken(model);
    try {
      if (token) {
        dispatch(AppActions.authorize());
        dispatch(authorize(token));
        await dispatch(getCurrentUser());
      }
    } catch {
      await dispatch(logout());
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };

export const facebookLogin =
  (model: ISocialNetworkLogin) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(AppActions.showSpinner());
    const token = await thunkService.api.facebookAuth.facebookToken(model);
    try {
      if (token) {
        dispatch(AppActions.authorize());
        dispatch(authorize(token));
        await dispatch(getCurrentUser());
      }
    } catch {
      await dispatch(logout());
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };

export const logout = (): ((
  dispatch: Dispatch<any>,
  getState: () => IGlobalStoreState,
  thunkService: ThunkService
) => Promise<void>) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(clear());
    dispatch(clearEvents());
    dispatch(clearTickets());
    dispatch(AppActions.deauthorize());
    dispatch(deauthorize());
    dispatch(checkoutClear());
  };
};

export const getCurrentAccount = (): ((
  dispatch: Dispatch<any>,
  getState: () => IGlobalStoreState,
  thunkService: ThunkService
) => Promise<void>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    const state = getState();
    if (!state.app.isAuthorized) {
      return Promise.resolve();
    }

    dispatch(AppActions.showSpinner());
    try {
      const user = await thunkService.api.account.getCurrentAccount();
      dispatch(init(user));
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};

export const getCurrentUser = (): ((
  dispatch: Dispatch<any>,
  getState: () => IGlobalStoreState,
  thunkService: ThunkService
) => Promise<void>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState) => {
    const state = getState();
    if (!state.app.isAuthorized) {
      return Promise.resolve();
    }

    dispatch(AppActions.showSpinner());
    try {
      await dispatch(getCurrentAccount());
      await dispatch(getUserTickets());
      await dispatch(getUserEvents());
    } catch {
      dispatch(logout());
      return Promise.resolve();
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};

export const updatePhone =
  (
    phone: string
  ): ((
    dispatch: Dispatch<any>,
    getState: () => IGlobalStoreState,
    thunkService: ThunkService
  ) => Promise<IActionResponseStoreState>) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(AppActions.showSpinner());
    try {
      const response = await thunkService.api.account.updatePhone(phone);
      dispatch(sendPhoneCode(response.details || response.message));
      dispatch(phoneCurrentUpdate(phone));
      return response;
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };

export const confirmPhone = (
  code: string,
  phone: string
): ((dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => Promise<void>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(AppActions.showSpinner());
    try {
      await thunkService.api.account.confirmPhone(code, phone);
      await dispatch(getCurrentUser());
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};

export const updateUser = (
  model: IUpdateUserStoreState,
  passwordModel?: IChangePasswordInfo
): ((dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => Promise<void>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(AppActions.showSpinner());
    try {
      if (passwordModel) {
        await thunkService.api.account.changePassword(passwordModel);
      }
      const user = await thunkService.api.account.updateCurrentAccount(model);
      dispatch(update(user));
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};

export const getUserEvents = (): ((
  dispatch: Dispatch<any>,
  getState: () => IGlobalStoreState,
  thunkService: ThunkService
) => Promise<void>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(AppActions.showSpinner());
    try {
      const events = await thunkService.api.events.getUserEvents();
      dispatch(initEvents(events));
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};

export const getUserTickets = (): ((
  dispatch: Dispatch<any>,
  getState: () => IGlobalStoreState,
  thunkService: ThunkService
) => Promise<void>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(AppActions.showSpinner());
    try {
      const tickets = await thunkService.api.tickets.getUserTickets();
      dispatch(initTickets(tickets));
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};

export const getTicketById = (
  masterTicketId: string,
  ticketId?: string
): ((
  dispatch: Dispatch<any>,
  getState: () => IGlobalStoreState,
  thunkService: ThunkService
) => Promise<IProfileTicketStoreState>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState) => {
    dispatch(AppActions.showSpinner());
    try {
      const state = getState();
      const ticket = state.userTickets.find((item: IProfileTicketStoreState) => {
        const isMasterId = item.masterTicketId === masterTicketId;
        const isTicketId = (!ticketId && !item.ticketId) || item.ticketId === ticketId;
        return isTicketId && isMasterId;
      });
      return Promise.resolve(ticket);
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};

export const issueTicket = (
  ticketId: string,
  email: string
): ((
  dispatch: Dispatch<any>,
  getState: () => IGlobalStoreState,
  thunkService: ThunkService
) => Promise<ITicketStoreState>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(AppActions.showSpinner());
    try {
      const model = new IssueTicketInfo(ticketId, email, false);
      const ticket = await thunkService.api.tickets.issueTicket(model);
      await dispatch(getUserTickets());
      return ticket;
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};

export const sellTicket = (
  ticketId: string,
  email: string,
  price: number
): ((
  dispatch: Dispatch<any>,
  getState: () => IGlobalStoreState,
  thunkService: ThunkService
) => Promise<ITicketStoreState>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(AppActions.showSpinner());
    try {
      const routeSrv = new RouteService();
      const model = new SellTicketInfo(ticketId, email, price);
      const resultLinkTemplate = `${routeSrv.getBaseUrl()}/user/my-tickets`;
      model.returnUrl = `${resultLinkTemplate}`;
      const ticket = await thunkService.api.tickets.sellTicket(model);
      await dispatch(getUserTickets());
      return ticket;
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};

export const boughtTicket = (
  ticketId: string
): ((
  dispatch: Dispatch<any>,
  getState: () => IGlobalStoreState,
  thunkService: ThunkService
) => Promise<ITicketStoreState>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(AppActions.showSpinner());
    try {
      const routeSrv = new RouteService();
      const model = new BoughtTicketInfo(ticketId);
      const boughtResultLinkTemplate = `${routeSrv.getBaseUrl()}/user/my-tickets`;
      model.returnUrl = `${boughtResultLinkTemplate}`;
      const ticket = await thunkService.api.tickets.boughtTicket(model);
      await dispatch(getUserTickets());
      return ticket;
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};

export const cancelSellTicket = (
  ticketId: string
): ((
  dispatch: Dispatch<any>,
  getState: () => IGlobalStoreState,
  thunkService: ThunkService
) => Promise<IProfileTicketStoreState>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(AppActions.showSpinner());
    try {
      const ticket = await thunkService.api.tickets.cancelSellTicket(ticketId);
      await dispatch(getUserTickets());
      return ticket;
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};

export const resendTicket = (
  ticketId: string
): ((
  dispatch: Dispatch<any>,
  getState: () => IGlobalStoreState,
  thunkService: ThunkService
) => Promise<IProfileTicketStoreState>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    dispatch(AppActions.showSpinner());
    const state = getState();
    try {
      await thunkService.api.tickets.resendTicket(ticketId);
      let ticket = state.userTickets.find((t) => t.ticketId === ticketId);
      ticket.sentAt = dayjs();
      dispatch(updateTicket(ticket));
      return ticket;
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};
export const resetPassword = (
  email: string
): ((dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => Promise<void>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    const routeSrv = new RouteService();
    const redirectUrl = routeSrv.getResetPasswordRedirectUrl();
    dispatch(AppActions.showSpinner());
    try {
      const actionResponse = await thunkService.api.account.resetPassword(email, redirectUrl);
      if (actionResponse) {
        dispatch(
          setResultInfo({
            type: AlertType.Info,
            key: KeySuccess.EmailSent,
            title: actionResponse.message,
            message: actionResponse.details,
          })
        );
      }
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};

export const confirmResetPassword = (
  password: string,
  token: string,
  encodedEmail: string
): ((dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => Promise<void>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    const model = new ResetPasswordConfirmInfo(encodedEmail, password, token);
    dispatch(AppActions.showSpinner());
    try {
      const token = await thunkService.api.account.confirmResetPassword(model);
      if (token) {
        dispatch(AppActions.authorize());
        dispatch(authorize(token));
        await dispatch(getCurrentUser());
      }
    } finally {
      dispatch(AppActions.hideSpinner());
    }
  };
};
