import { Reducer } from 'redux';
import { ResultInfoActionTypes, ResultInfoType } from './types';

export const resultInfoStoreState: ResultInfoType = {
  type: null,
  key: null,
  message: null,
  title: null,
  buttons: [],
};

const resultInfoReducer: Reducer<ResultInfoType> = (state = resultInfoStoreState, action): ResultInfoType => {
  switch (action.type) {
    case ResultInfoActionTypes.RESULT_INFO_SHOW: {
      return { ...action.payload };
    }
    case ResultInfoActionTypes.RESULT_INFO_CLEAR:
      return { ...state, type: null, key: null, message: null, title: null, buttons: [] };
    default:
      return state;
  }
};

export default resultInfoReducer;
