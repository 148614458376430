import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IGlobalStoreState } from '../../../store';
import { Button, Message, MessageButtonsWrapper, PageContent } from '../../../components/UI';
import { IUserStoreState } from '../../../store/user/types';
import { Utils } from '../../../services';
import { cancelSellTicket } from '../../../store/user/actions';
import { UserTicketsList } from '../../../components/ticket';
import { IProfileTicketStoreState } from '../../../store/ticket/ProfileTicketStoreState';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { Routes } from '../../../store/routes';

const UserTicketsPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getProfileRoute } = useRoutesHelper();

  const isInitializing = useSelector<IGlobalStoreState, boolean>((state) => state.app.isInitializing);
  const isAuthorized = useSelector<IGlobalStoreState, boolean>((state) => state.app.isAuthorized);
  const isLoading = useSelector<IGlobalStoreState, boolean>((state) => state.app.isLoading);
  const user = useSelector<IGlobalStoreState, IUserStoreState>((state) => state.user);
  const tickets = useSelector<IGlobalStoreState, IProfileTicketStoreState[]>((state) => state.userTickets);

  useEffect(() => {
    if (!isAuthorized || !user.phoneNumberConfirmed) {
      history.push(getProfileRoute());
      return;
    }
  }, [isAuthorized, user?.phoneNumberConfirmed]);

  const cancelTicket = useCallback(
    (ticketId: string): any => {
      dispatch(cancelSellTicket(ticketId));
    },
    [dispatch]
  );

  if (isInitializing || !user || isLoading) {
    return null;
  }

  return (
    <PageContent noPadding>
      <Helmet>
        <title>{Utils.toPageTitle(t('Profile.MyTickets'))}</title>
      </Helmet>
      {!!tickets.length && (
        <UserTicketsList tickets={tickets} cancelSellTicket={cancelTicket} go={(url) => history.push(url)} />
      )}
      {!tickets.length && (
        <Message
          title={t('MyTickets.NoTickets')}
          description={t('MyTickets.NoTicketsDescription')}
          renderButtons={() => (
            <MessageButtonsWrapper>
              <Button title={t('MyTickets.AllEvents')} onClick={() => history.push(Routes.Main)}>
                {t<string>('MyTickets.AllEvents')}
              </Button>
            </MessageButtonsWrapper>
          )}
        />
      )}
    </PageContent>
  );
};

export default UserTicketsPage;
