import * as SrvM from '.';
import RouteService from './routeService';

export class Api {
  base: SrvM.BaseApiService;
  events: SrvM.EventsApiService;
  payments: SrvM.PaymentsApiService;
  account: SrvM.AccountApiService;
  auth: SrvM.AuthApiService;
  googleAuth: SrvM.AuthApiService;
  facebookAuth: SrvM.AuthApiService;
  tickets: SrvM.TicketsApiService;
  home: SrvM.HomeApiService;
  share: SrvM.ShareApiService;
  widget: SrvM.WidgetApiService;
  publicContract: SrvM.PublicContractApiService;

  constructor(config?: any) {
    this.base = new SrvM.BaseApiService(config);
    this.events = new SrvM.EventsApiService({ ...config, baseURL: `${config.baseURL}/events` });
    this.payments = new SrvM.PaymentsApiService({ ...config, baseURL: `${config.baseURL}/payments` });
    this.account = new SrvM.AccountApiService({ ...config, baseURL: `${config.baseURL}/accounts` });
    this.auth = new SrvM.AuthApiService({ ...config, baseURL: `${config.baseURL}/auth` });
    this.googleAuth = new SrvM.AuthApiService({ ...config, baseURL: `${config.baseURL}/auth` });
    this.facebookAuth = new SrvM.AuthApiService({ ...config, baseURL: `${config.baseURL}/auth` });
    this.tickets = new SrvM.TicketsApiService({ ...config, baseURL: `${config.baseURL}/tickets` });
    this.home = new SrvM.HomeApiService({ ...config, baseURL: `${config.baseURL}/home` });
    this.share = new SrvM.ShareApiService({ ...config, baseURL: `${config.baseURL}/share` });
    this.widget = new SrvM.WidgetApiService({ ...config, baseURL: `${config.baseURL}/widget` });
    this.publicContract = new SrvM.PublicContractApiService({
      ...config,
      baseURL: `${config.baseURL}/PublicContracts`,
    });
  }
}

export class ThunkService {
  api: Api;
  routeService: RouteService;

  constructor(apiConfig?: any) {
    this.api = new Api(apiConfig);
    this.routeService = new RouteService();
  }
}
