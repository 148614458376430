import { Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import { ResultInfoActionTypes, ResultInfoType } from './types';

export const resultInfo = (payload: ResultInfoType) => action(ResultInfoActionTypes.RESULT_INFO_SHOW, payload);
export const clearInfo = () => action(ResultInfoActionTypes.RESULT_INFO_CLEAR);

export const setResultInfo = (config: ResultInfoType) => (dispatch: Dispatch) => {
  if (!config) {
    return;
  }
  dispatch(resultInfo(config));
};
