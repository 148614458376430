import React, { FC } from 'react';
import styled from 'styled-components';

import { IVenueSectorStoreState } from '../../store/ticket/types';
import { Label } from '../UI';
import { Utils } from '../../services';
import NumberInput from '../UI/numberInput';

type TicketCountSelectorPropsType = {
  sector: IVenueSectorStoreState;
  tickets: number;
  max: number;

  handleTicketsCount?: (count: number) => void;
};

export const TicketCountSelector: FC<TicketCountSelectorPropsType> = ({ handleTicketsCount, tickets, max, sector }) => {
  if (!sector) {
    return null;
  }

  return (
    <TicketWrapper>
      <TicketSelectorWrapper>
        <Title>{sector.title}</Title>
        <NumberInput
          value={tickets || 0}
          postfix={Utils.getTicketsPostfixByCount(tickets)}
          min={0}
          max={max}
          handleTicketsCount={handleTicketsCount}
        />
      </TicketSelectorWrapper>
    </TicketWrapper>
  );
};

const TicketWrapper = styled.section`
  margin-bottom: 15px;
`;

const TicketSelectorWrapper = styled.section`
  display: flex;
  font-size: 12px;
  align-items: center;
  margin-bottom: 10px;
`;

const Title = styled(Label)`
  flex: 1;
  font-size: 12px;
`;
