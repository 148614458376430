import React, { ChangeEvent, FC } from 'react';
import styled from 'styled-components';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, RadioButton, RadioLabel } from '../UI';
import { FieldError, Fieldset, FieldWrapper, FormError, IBaseFormProps } from './commonForm';
import { PopUp } from '../../store/animations';
import { ICheckoutStoreState } from '../../store/checkout/types';
import { IUserStoreState } from '../../store/user/types';
import { CheckoutHelper } from '../../helpers';
import { Utils } from '../../services';
import { useUtils } from '../../services/useUtils';
import UserHelper from '../../helpers/userHelper';

export interface IPaymentForm {
  usePoints: boolean;
}

interface IPaymentFormProps extends IBaseFormProps<IPaymentForm> {
  checkoutModel: ICheckoutStoreState;
  user: IUserStoreState;

  onUsePointsChange?: (usePoints: boolean) => void;
}

export const PaymentForm: FC<IPaymentFormProps> = ({ checkoutModel, errors, onUsePointsChange, user, onSubmit }) => {
  const { t } = useTranslation();
  const { getEmojiFlagByCurrency } = useUtils();

  const ValidationSchema = Yup.object().shape({
    usePoints: Yup.boolean().required(),
  });
  const isEnoughPoints = CheckoutHelper.getIsEnoughPoints(checkoutModel, user);
  const { currency } = checkoutModel.init;
  const currencyBalance = UserHelper.getBalanceByCurrency(user, currency);
  const balanceDescription = `${t('Book.Available')} ${currencyBalance ? currencyBalance.amount : 0}
  ${t('Book.Points')} ${getEmojiFlagByCurrency(currency)}`;
  const pointsDescription = `${!isEnoughPoints ? `${t('Book.NotEnoughPoints')} ` : ''}${balanceDescription}`;

  const pointsUseChange = (e: ChangeEvent<HTMLInputElement>, usePoints: boolean) => {
    if (e) {
      e.preventDefault();
    }
    if (onUsePointsChange) {
      onUsePointsChange(usePoints);
    }
  };

  const getSubmitTitle = (): string => {
    const checkout = checkoutModel;
    const amount = Utils.round(checkout.init.pricePayByCard || checkout.init.priceTotal);
    const { currency } = checkout.init;
    return `${t('Checkout.ToPay')} ${amount} ${currency}`;
  };

  return (
    <Formik
      isInitialValid={!!checkoutModel.paymentMethod}
      enableReinitialize
      initialValues={{ usePoints: checkoutModel.usePoints }}
      validationSchema={ValidationSchema}
      onSubmit={(values: IPaymentForm) => {
        if (onSubmit) {
          onSubmit(values);
        }
      }}
    >
      {({ isValid }) => (
        <StyledForm autoComplete="on">
          <StyledFieldset fullWidth>
            <Field name="usePoints">
              {({ field, form }) => (
                <>
                  <RadioWrapper>
                    <RadioLabel checked={!field.value}>
                      <RadioButton
                        {...field}
                        type="radio"
                        error={!!form.touched.usePoints && !!form.errors && !!form.errors.usePoints}
                        value={0}
                        checked={!field.value}
                        onChange={(e) => pointsUseChange(e, false)}
                      />{' '}
                      {t('Book.DontUsePoints')}
                    </RadioLabel>
                  </RadioWrapper>
                  <RadioWrapper>
                    <RadioLabel checked={field.value} disabled={!isEnoughPoints}>
                      <RadioButton
                        {...field}
                        type="radio"
                        error={!!form.touched.usePoints && !!form.errors && !!form.errors.usePoints}
                        value={1}
                        checked={field.value}
                        onChange={(e) => pointsUseChange(e, true)}
                      />{' '}
                      {t('Book.UsePoints')}
                    </RadioLabel>
                    <AnimatePresence>
                      <RadioDescription variants={PopUp} initial={'enter'} animate={'exit'}>
                        <RadioDescriptionTitle
                          variants={PopUp}
                          initial={'enter'}
                          animate={'exit'}
                          error={!isEnoughPoints}
                        >
                          {pointsDescription}
                        </RadioDescriptionTitle>
                      </RadioDescription>
                    </AnimatePresence>
                  </RadioWrapper>
                  <AnimatePresence>
                    {!!form.touched.usePoints && !!form.errors && !!form.errors.usePoints && (
                      <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                        {form.errors.usePoints}
                      </FieldError>
                    )}
                  </AnimatePresence>
                </>
              )}
            </Field>
          </StyledFieldset>
          <AnimatePresence>
            {!!errors && !!errors.form && (
              <FormError variants={PopUp} initial={'enter'} animate={'exit'} key="form-error">
                {errors.form.details || errors.form.message}
              </FormError>
            )}
          </AnimatePresence>
          {
            <Button type="submit" disabled={!isValid}>
              {getSubmitTitle()}
            </Button>
          }
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledForm = styled(Form)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledFieldset = styled(Fieldset)`
  flex: 1;
  margin-bottom: 20px;
`;

const RadioWrapper = styled(FieldWrapper)`
  margin-bottom: 0;
`;

const RadioDescription = styled(motion.div)`
  padding: 10px 25px;
  font-size: 10px;
`;

const RadioDescriptionTitle: any = styled(motion.span)`
  color: ${(props: RadioDescriptionTitleType) => (props.error ? props.theme.colors.error : props.theme.colors.accent)};
`;

type RadioDescriptionTitleType = {
  error: boolean;
  theme: {
    colors: {
      error: string;
      accent: string;
    };
  };
};
