import { Reducer } from 'redux';

import { IMenuStoreState, MenuStoreState, MenuActionTypes } from './types';

const menuReducer: Reducer<IMenuStoreState> = (
  state: IMenuStoreState = new MenuStoreState(null),
  action
): IMenuStoreState => {
  switch (action.type) {
    case MenuActionTypes.MENU_SHOW:
      return { ...state, isShow: true };
    case MenuActionTypes.MENU_HIDE:
      return { ...state, isShow: false };
    case MenuActionTypes.CONTRACTS_INIT:
      return { ...state, contracts: action.payload };
    default:
      return state;
  }
};

export default menuReducer;
