import { Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import dayjs from 'dayjs';

import { IRegionalInfoStoreState, RegionActionTypes } from './types';
import { ThunkService } from '../../services';
import { IGlobalStoreState } from '..';

export const setRegion = (payload: IRegionalInfoStoreState) => action(RegionActionTypes.REGION_SET, payload);

export const getRegion = (): ((
  dispatch: Dispatch<any>,
  getState: () => IGlobalStoreState,
  thunkService: ThunkService,
) => Promise<IRegionalInfoStoreState>) => {
  return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    const state = getState();
    const region = state.region;
    const isAfter = dayjs().isAfter(region.lastCheckedAt);
    if (!region || !region.lastCheckedAt || isAfter) {
      const response = await thunkService.api.home.getRegion();
      dispatch(setRegion(response));
      return Promise.resolve(response);
    }
    return Promise.resolve(region);
  };
};
