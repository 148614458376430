import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IGlobalStoreState } from '../../../../store';
import { getEventDetailsBySlug } from '../../../../store/event/actions';
import { Page, PageContent } from '../../../../components/UI';
import { EventDetailsHeader } from '../../../../components/event';
import { AnalyticService } from '../../../../services';
import { AlertType, KeyErrors } from '../../../../store/enums';
import { EventHelper } from '../../../../helpers';
import { hideAdButton, showAdButton } from '../../../../store/header/actions';
import { IEventDetailsStoreState } from '../../../../store/event/eventDetails/EventDetailsStoreState';
import EventInfoItem from '../../../../components/event/eventInfoItem';
import { useHistory, useParams } from 'react-router';
import { EventDetailsFooter } from './eventDetailsFooter';
import { DescriptionCollapse } from './descriptionCollapse';
import { setResultInfo } from '../../../../store/resultInformarion/actions';
import { useRoutesHelper } from '../../../../helpers/routesHelper';

const EventDetailsPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const analyticSrv = new AnalyticService();

  const [event, setEvent] = useState<IEventDetailsStoreState>(null);
  const { eventSlug } = useParams<{ eventSlug: string }>();

  const isInitializing = useSelector<IGlobalStoreState, boolean>((state) => state.app.isInitializing);

  const { getResultStatusRoute } = useRoutesHelper();

  useEffect(() => {
    dispatch(hideAdButton());

    if (!eventSlug) {
      return;
    }

    (async () => dispatch(getEventDetailsBySlug(eventSlug)))().then((event: any) => {
      if (!event || !event.slug) {
        dispatch(
          setResultInfo({
            type: AlertType.Error,
            key: KeyErrors.EventNotFound,
            title: 'Alert.SomethingWentWrong',
            message: 'Alert.CheckLinkNoEventFound',
          })
        );
        history.replace(getResultStatusRoute(AlertType.Error, KeyErrors.EventNotFound));
        return;
      }

      setEvent(event);
    });
  }, [eventSlug]);

  useEffect(() => {
    dispatch(showAdButton());
    analyticSrv.trackEvent('EventsDetails');
  }, []);

  const renderDateBlock = (date: dayjs.Dayjs) => {
    const day = dayjs(date).date();
    const month = dayjs(date).format('MMMM YYYY');
    const time = dayjs(date).format('LT');
    return (
      <ListItem>
        <DateTimeItem dateTime={dayjs(date).format()}>
          <DayTimeWrapper>
            <Day>{day}</Day>
            <Time>{time}</Time>
          </DayTimeWrapper>
          <MonthYearWrapper>
            <Month>{month}</Month>
          </MonthYearWrapper>
        </DateTimeItem>
      </ListItem>
    );
  };

  const renderPriceBlock = (event: IEventDetailsStoreState) => {
    const { minPrice, maxPrice, currency } = event;

    if (!minPrice && !maxPrice) {
      return null;
    }

    const priceTitle = minPrice !== maxPrice ? `${minPrice}–${maxPrice}` : minPrice;

    return (
      <ListItem>
        <ListItemWrapper>
          <EventInfoItem icon="purse" sm title={`${currency} ${priceTitle}`} />
        </ListItemWrapper>
      </ListItem>
    );
  };

  if (isInitializing || !event || !event.slug) {
    return null;
  }

  return (
    <Page
      title={`${event.title} - ${event.subtitle}`}
      description={EventHelper.getEventDetailsMetaDescription(event)}
      keywords={[event.title, event.venue.title, t('Event.BuyTicket'), t('Event.Concert')]}
      imageUrl={event.posterUrl}
    >
      <EventDetailsHeader event={event} diagonal />
      <EventInfoWrapper>
        <List>
          {renderDateBlock(event.date)}
          {event.venue && (
            <ListItem>
              <ListItemWrapper>
                <EventInfoItem
                  icon="location"
                  sm
                  title={event.venue.title}
                  subtitle={`${event.venue.address}, ${event.venue.city}`}
                />
              </ListItemWrapper>
            </ListItem>
          )}
          {renderPriceBlock(event)}
        </List>
        {event?.description && (
          <DescriptionCollapse title={`${t('Event.MoreAboutTheEvent')}`} subtitle={`${event.description}`} />
        )}
      </EventInfoWrapper>
      <EventDetailsFooter event={event} isInitializing={isInitializing} />
    </Page>
  );
};

const EventInfoWrapper = styled(PageContent as any)`
  padding: 20px 35px;
  margin-top: 10px;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  position: relative;
  border-left: 1px solid ${(props) => props.theme.colors.text};
  padding-bottom: 25px;

  &:last-child {
    padding-bottom: 0;
    border-left-color: transparent;
  }
`;

const ListItemWrapper = styled.div`
  top: -10px;
  position: relative;
  padding-left: 35px;

  &:before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background: ${(props) => props.theme.colors.accent};
    top: 6px;
    border-radius: 50%;
    left: -4px;
  }
`;

const Title = styled.span`
  font-size: 14px;
  margin-bottom: 5px;
`;

const Subtitle = styled.span`
  font-size: 12px;
`;

const DateTimeItem = styled.time`
  display: flex;
  flex-direction: column;
  width: 180px;
  top: -10px;
  position: relative;
  padding-left: 35px;
  font-family: ${(props) => props.theme.fonts.boldFont};

  &:before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background: ${(props) => props.theme.colors.accent};
    top: 6px;
    border-radius: 50%;
    left: -4px;
  }
`;

const DayTimeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const MonthYearWrapper = styled.div`
  text-align: right;
  margin-top: 15px;
`;

const Day = styled.span`
  font-size: 64px;
  line-height: 40px;
  font-family: ${(props) => props.theme.fonts.bolderFont};
`;

const Time = styled(Subtitle as any)`
  text-align: right;
  margin-left: 10px;
  padding-bottom: 8px;
  border-bottom: 2px solid ${(props) => props.theme.colors.text};
  flex: 1;
`;

const Month = styled(Title as any)``;

export default EventDetailsPage;
