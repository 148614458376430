import React, { FC } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Button from '../UI/button';

const useStyles = makeStyles()(() => ({
  paper: {
    background: 'rgba(18, 17, 17, 1)',
    boxShadow: '4px -4px 8px rgba(0, 0, 0, 0.4), -4px 4px 8px rgba(0, 0, 0, 0.4)',
    borderRadius: '12px',
    color: '#fff',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '20px 55px',
  },
  button: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export const ModalBonusTicket: FC<ModalBonusTicketPropsType> = ({ handleClose, open }) => {
  const { classes } = useStyles();

  const handleNavigateClick = () => {
    window.open('https://wa.me/37128294056');
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      classes={{
        paper: classes.paper,
      }}
    >
      <div style={{ position: 'relative' }}>
        <DialogTitle align={'center'}>Как получить бонусный билет?</DialogTitle>
      </div>

      <DialogContent className={classes.content}>
        <div style={{ textAlign: 'center' }}>
          Стань распространителем на Fanaticka, помоги другим пользователям купить 10 билетов и ты сможешь получить
          бонусный билет. Так давно делают другие фанаты. Как? Наши пользователи-волонтеры с радостью подскажут в
          WhatsApp или службе поддержки😊
        </div>
        <div>
          <Button onClick={handleNavigateClick}>Перейти в WhatsApp</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

type ModalBonusTicketPropsType = {
  handleClose: () => void;
  open: boolean;
};
