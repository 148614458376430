import React, { FC } from 'react';
import { IEventStoreState } from '../../../../store/event/types';
import { IVenueSectorStoreState } from '../../../../store/ticket/types';
import { BookTotalPrices } from '../../book';

type RenderPricesPropsType = {
  event: IEventStoreState;
  sector: IVenueSectorStoreState;
  quotaId: number;
  seats: any;
};

export const WidgetPrices: FC<RenderPricesPropsType> = ({ event, sector, seats, quotaId }) => {
  if (!event || !sector || !sector.ticketQuotas || !quotaId) {
    return null;
  }
  const quota = sector.ticketQuotas.find((q) => q.id === quotaId);

  if (!quota) {
    return null;
  }

  const tickets = seats.filter((s) => s.quotaId === quotaId);

  const defaultPaymentMethod = event.paymentMethods && event.paymentMethods.length > 0 ? event.paymentMethods[0] : null;
  const ticketPrice = quota.price;
  const ticketsCount = tickets.length;
  let total = ticketPrice * ticketsCount;
  let { currency } = quota;
  let sectorTitle = '';

  if (defaultPaymentMethod.useConvertion) {
    total = Math.round(total * defaultPaymentMethod.convertionRate * 100) / 100;
    currency = defaultPaymentMethod.convertionCurrency;
  }

  if (!sector.noSeats && !!seats && !!seats.length) {
    sectorTitle = `${ticketsCount} x ${sector.title}`;
  }

  return (
    <BookTotalPrices
      border={sector.noSeats}
      ticketPrice={ticketPrice}
      currency={currency}
      totalPrice={total}
      sectorTitle={sectorTitle}
    />
  );
};
