import { Dispatch } from 'redux';
import { action } from 'typesafe-actions';

import { ThunkService } from '../../services';
import { IGlobalStoreState } from '..';
import { hideSpinner, showSpinner } from '../app/actions';
import { ITokenTicketQuotaStoreState } from '../ticket/types';
import { IShareStoreState, ShareActionTypes, ShareStoreState } from './types';
import { IEventStoreState } from '../event/types';

const init = (payload: IShareStoreState) => action(ShareActionTypes.SHARE_INIT, payload);
export const clear = () => action(ShareActionTypes.CLEAR_INIT);

export const initShare =
  (event: IEventStoreState) =>
  (dispatch: Dispatch<any>): Promise<IShareStoreState> => {
    const shareStore = new ShareStoreState();
    shareStore.event = event;

    dispatch(init(shareStore));
    return Promise.resolve(shareStore);
  };

export const getTicketShareToken =
  (
    eventSlug: string,
    sectorSlug: string,
    tickets: number
  ): ((dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => Promise<string>) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    if (!eventSlug || !sectorSlug || tickets <= 0) {
      return;
    }
    try {
      dispatch(showSpinner());
      const response = await thunkService.api.share.getToken(eventSlug, sectorSlug, tickets);
      return Promise.resolve(response);
    } finally {
      dispatch(hideSpinner());
    }
  };

export const getTicketQuotaByShareToken =
  (
    token: string
  ): ((
    dispatch: Dispatch<any>,
    getState: () => IGlobalStoreState,
    thunkService: ThunkService
  ) => Promise<ITokenTicketQuotaStoreState>) =>
  async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
    if (!token) {
      return;
    }
    try {
      dispatch(showSpinner());
      const response = await thunkService.api.share.getTicketQuotaByToken(token);
      return Promise.resolve(response);
    } finally {
      dispatch(hideSpinner());
    }
  };
