import * as React from 'react';
import styled from 'styled-components';

import { PopUp } from '../../store/animations';

interface IItemProps {
  icon: 'star' | 'ticket' | 'meet' | 'soldTicket';
}

interface IUserStatItemProps extends IItemProps {
  title: string;
  count: number;
}

export class UserStatItem extends React.PureComponent<IUserStatItemProps> {
  constructor(props: IUserStatItemProps) {
    super(props);
  }

  render() {
    return (
      <ItemContainer icon={this.props.icon}>
        <Title>{this.props.title}</Title>
        <Count>{this.props.count}</Count>
      </ItemContainer>
    );
  }
}

const ItemContainer = styled('li' as any)<IItemProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:before {
    content: '';
    background: ${(props) => `url(${props.theme.img.icons[props.icon]}) center no-repeat`};
    display: block;
    width: 26px;
    height: 26px;
    background-size: contain;
    margin-bottom: 10px;
  }
`;

const Title = styled.span`
  font-size: 12px;
  text-align: center;
  flex: 1;
`;

const Count = styled.span`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
`;
