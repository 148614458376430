import { motion } from 'framer-motion';
import styled from 'styled-components';

interface IPageContentProps {
  center?: boolean;
  noPadding?: boolean;
  roundCorners?: boolean;
}

export const PageContent = styled(motion.section)<IPageContentProps>`
  border-radius: ${(props) => props.roundCorners && '26px 26px 0 0'};
  background-color: ${(props) => props.roundCorners && props.theme.colors.bg};
  top: ${(props) => props.roundCorners && '-26px'};
  position: relative;
  padding: ${(props) => (props.noPadding ? '0' : `20px 25px ${props.roundCorners ? '0' : '25px'} 25px`)};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.center && 'center'};
  justify-content: ${(props) => props.center && 'center'};
  z-index: 1;
`;

export default PageContent;
