import { Reducer } from 'redux';
import { ILocationStoreState, LocationActionTypes, LocationStoreState } from './types';

const locationReducer: Reducer<ILocationStoreState> = (state: LocationStoreState = null, action) => {
  switch (action.type) {
    case LocationActionTypes.INIT_LOCATION:
      return { ...state, location: action.payload } as LocationStoreState;
    default:
      return state;
  }
};

export default locationReducer;
