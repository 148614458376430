import { Reducer } from 'redux';

import { IAppStoreState, AppStoreState, AppActionTypes } from './types';

const appReducer: Reducer<IAppStoreState> = (state: IAppStoreState = new AppStoreState(), action) => {
  switch (action.type) {
    case AppActionTypes.APP_INIT:
      return { ...state, isInitializing: false, isRedirection: false };
    case AppActionTypes.APP_FINALIZE:
      return { ...state, isFinalized: true };
    case AppActionTypes.SPINNER_SHOW:
      return { ...state, isLoading: true };
    case AppActionTypes.SPINNER_HIDE:
      return { ...state, isLoading: false };
    case AppActionTypes.APP_AUTHORIZE:
      return { ...state, isAuthorized: true };
    case AppActionTypes.APP_DEAUTHORIZE:
      return { ...state, isAuthorized: false };
    case AppActionTypes.ERROR_SET:
      return { ...state, error: action.payload };
    case AppActionTypes.ERROR_CLEAR:
      return { ...state, error: null };
    case AppActionTypes.APP_REDIRECTION:
      return { ...state, isRedirection: true };
    default:
      return state;
  }
};

export default appReducer;
