import { BaseApiService } from '../baseApiService';
import { ITokenTicketQuotaStoreState, TokenTicketQuotaStoreState } from '../../store/ticket/types';

export class ShareApiService extends BaseApiService {
  private static _instance: ShareApiService;

  constructor(config?: any) {
    if (typeof ShareApiService._instance === 'undefined') {
      super(config);
      ShareApiService._instance = this;
    }
    return ShareApiService._instance;
  }

  public async getToken(eventSlug: string, sectorSlug: string, tickets: number): Promise<string> {
    const response = await this.get(`/${eventSlug}/${sectorSlug}/${tickets}`);
    return response.data;
  }

  public async getTicketQuotaByToken(token: string): Promise<ITokenTicketQuotaStoreState> {
    const response = await this.get(`/token/${token}`);
    return new TokenTicketQuotaStoreState(response.data);
  }
}
