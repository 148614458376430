import { Reducer } from 'redux';

import { IShareStoreState, ShareStoreState, ShareActionTypes } from './types';

const shareReducer: Reducer<IShareStoreState> = (state: IShareStoreState = new ShareStoreState(), action) => {
  switch (action.type) {
    case ShareActionTypes.SHARE_INIT:
      return { ...action.payload } as IShareStoreState;
    case ShareActionTypes.CLEAR_INIT:
      return new ShareStoreState() as IShareStoreState;
    default:
      return state;
  }
};

export default shareReducer;
