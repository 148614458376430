import dayjs from 'dayjs';

export interface IRegionalInfoStoreState {
  countryCode: string;
  domainName: string;
  isDomainRequired: boolean;
  lastCheckedAt?: dayjs.Dayjs;
  city: string;
}

export class RegionalInfoStoreState implements IRegionalInfoStoreState {
  countryCode: string;
  domainName: string;
  isDomainRequired: boolean;
  lastCheckedAt?: dayjs.Dayjs;
  city: string;

  constructor(dto?: any) {
    this.countryCode = dto && dto.CountryCode ? dto.CountryCode : '';
    this.domainName = dto && dto.DomainName ? dto.DomainName : '';
    this.isDomainRequired = dto && dto.IsDomainRequired ? dto.IsDomainRequired : false;
    this.city = dto && dto.City ? dto.City : '';
  }
}

export const enum RegionActionTypes {
  REGION_SET = '@region/SET',
}
