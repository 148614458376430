import { useTranslation } from 'react-i18next';
import { AppSettings } from '../settings/appSettings';

export const useUtils = () => {
  const { t } = useTranslation();

  const getTicketsPostfixByCount = (count: number): string => {
    switch (count) {
      case 1:
        return t('Book.Ticket');
      case 2:
      case 3:
      case 4:
        return t('Book.MultipleTicketsBeforeFive');
      default:
        return t('Book.Tickets');
    }
  };

  const getEmojiFlagByCurrency = (currency: string): string => {
    let result = '';
    switch (currency) {
      case 'BYN':
        result = 'Br';
        break;
      case 'RUB':
        result = '₽';
        break;
      case 'EUR':
        result = '€';
        break;
      case 'UAH':
        result = '₴';
        break;
      case 'USD':
        result = '$';
        break;
      default:
        result = '';
        break;
    }
    return result;
  };

  const toPageTitle = (source: string): string =>
    source && source.trim() ? `${source.trim()} | ${AppSettings.APP_NAME}` : AppSettings.APP_NAME;

  const toMetaKeywords = (keywords: Array<string>): string => keywords.join(',');

  return {
    getTicketsPostfixByCount,
    getEmojiFlagByCurrency,
    toPageTitle,
    toMetaKeywords,
  };
};
