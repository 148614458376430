import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

interface IEventInfoItemProps extends IContainerProps {
  title: string;
  subtitle?: string;
}

interface IContainerProps {
  sm?: boolean;
  themed?: boolean;
  icon?: 'location' | 'purse' | 'person' | 'calendar';
}

const EventInfoItem: FC<IEventInfoItemProps> = ({ icon, title, themed, subtitle, sm }) => {
  return (
    <Container icon={icon} sm={sm} themed={themed}>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Container>
  );
};

const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  padding-left: 35px;
  position: relative;

  &:after {
    content: ${(props) => props.icon && '""'};
    background: ${(props) => props.icon && `url(${props.theme.img.icons[props.icon]}) no-repeat`};
    background-size: cover;
    width: ${(props) => (props.sm ? '14px' : '20px')};
    height: ${(props) => (props.sm ? '14px' : '20px')};
    position: absolute;
    margin-left: ${(props) => (props.sm ? '-25px' : '-35px')};
    filter: ${(props) => !props.themed && 'brightness(0) invert(1)'};
    top: ${(props) => (props.sm ? '0' : '-3px')};
  }
`;

const Title = styled.span`
  font-size: 14px;
  margin-bottom: 5px;
  font-family: ${(props) => props.theme.fonts.boldFont};
`;

const Subtitle = styled.span`
  font-size: 12px;
  text-align: justify;
  font-family: ${(props) => props.theme.fonts.lightFont};
  color: ${(props) => props.theme.colors.textSecond};
`;

export default EventInfoItem;
