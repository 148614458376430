import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { EventHelper } from '../../../../helpers';
import { TicketsInfo } from './ticketsInfo';
import { WidgetError } from './widgetError';
import { Button, PageFooter } from '../../../../components/UI';
import { IEventStoreState, ISeatStoreState } from '../../../../store/event/types';
import { IBookStoreState } from '../../../../store/book/types';
import { initBook } from '../../../../store/book/actions';
import { widgetCheckoutInit } from '../../../../store/checkout/actions';
import { useRoutesHelper } from '../../../../helpers/routesHelper';

type WidgetFooterPropsType = {
  event: IEventStoreState;
  sectorSlug: string;
  isLoading: boolean;
  seats: ISeatStoreState[];
  book: IBookStoreState;
  token: string;
  setIsCheckoutInitiating: (checkoutInitiating: boolean) => void;
};

export const WidgetFooter: FC<WidgetFooterPropsType> = memo(
  ({ event, token, setIsCheckoutInitiating, sectorSlug, book, isLoading, seats }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const { getWidgetCheckoutRouteFromBook } = useRoutesHelper();

    if (!event) {
      return null;
    }

    const sector = event && event.sectors && event.sectors.find((x) => x.slug === sectorSlug);

    const hasTickets = EventHelper.getHasTickets(event);
    const isSectorSelected = !!sectorSlug;
    const isSeatsSelected = isSectorSelected && seats && seats.length > 0;
    const isCheckoutEnabled = !isLoading && hasTickets && isSeatsSelected && !event.notAvailableForPurchase;
    let buttonTitle = t('WidgetBook.GoToPayment');
    if (!hasTickets) {
      buttonTitle = t('WidgetBook.TicketsAreNotAvailableForSale');
    } else if (!isSectorSelected) {
      buttonTitle = t('WidgetBook.ChooseSector');
    } else if (!isSeatsSelected && !sector.noSeats) {
      buttonTitle = t('WidgetBook.ChooseSeats');
    } else if (event.notAvailableForPurchase) {
      buttonTitle = event.notAvailableForPurchaseReason;
    }

    const handleCheckoutClick = (e: any) => {
      if (e) e.preventDefault();
      dispatch(initBook(event, sectorSlug, seats));
      try {
        checkoutInit();
      } catch (e) {
        console.log('checkoutInit', e);
      }
    };

    const checkoutInit = () => {
      (async () => dispatch(widgetCheckoutInit(token)))()
        .then(() => {
          setIsCheckoutInitiating(false);
          history.push(getWidgetCheckoutRouteFromBook(book, token));
        })
        .catch(() => {
          setIsCheckoutInitiating(false);
        });
    };

    return (
      <Footer>
        {!!sector && !sector.noSeats && <TicketsInfo event={event} seats={seats} />}
        {<WidgetError event={event} seats={seats} book={book} />}
        <Button disabled={!isCheckoutEnabled} onClick={(e) => handleCheckoutClick(e)}>
          {buttonTitle}
        </Button>
      </Footer>
    );
  }
);

const Footer = styled(PageFooter as any)`
  align-items: initial;
  padding: 0 25px 20px 25px;

  > button,
  > input {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;
