import React, { FC, useEffect, useState } from 'react';
import { Alert, Slide } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AlertColor } from '@mui/material/Alert/Alert';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { clearError } from '../../store/app/actions';
import { IGlobalStoreState } from '../../store';

export const AlertError: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [close, setClose] = useState(true);

  const message = useSelector<IGlobalStoreState, string>((state) => state.app.error?.message);
  const type = useSelector<IGlobalStoreState, AlertColor>((state) => state.app.error?.type);

  const closeHandle = () => {
    setClose(false);
    dispatch(clearError());
  };

  useEffect(() => {
    if (!message) {
      return;
    }

    scrollTo({ top: 0, behavior: 'smooth' });
    const timeout = setTimeout(() => {
      setClose(false);
      dispatch(clearError());
    }, 5000);

    return () => {
      clearTimeout(timeout);
      setClose(true);
    };
  }, [dispatch, message]);

  if (!message) {
    return null;
  }

  return (
    <Slide in={close} direction={'left'}>
      <Alert
        variant="filled"
        severity={type}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={closeHandle}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ position: 'absolute', top: 0, right: 0, margin: '10px', borderRadius: '50px', zIndex: 999 }}
      >
        {t(message)}
      </Alert>
    </Slide>
  );
};
