import { Action, combineReducers, Dispatch, Reducer } from 'redux';
import { PersistedState } from 'redux-persist';

import appReducer from './app/reducers';
import eventsReducer from './event/reducers';
import bookReducer from './book/reducers';
import checkoutReducer from './checkout/reducers';
import distributionReducer from './distribution/reducers';
import authReducer from './auth/reducers';
import { userEventsReducer, userReducer, userTicketsReducer } from './user/reducers';
import { IMenuStoreState, MenuStoreState } from './menu/types';
import menuReducer from './menu/reducers';
import { AppStoreState, IAppStoreState } from './app/types';
import { IEventStoreState } from './event/types';
import { BookStoreState, IBookStoreState } from './book/types';
import { CheckoutStoreState, ICheckoutStoreState } from './checkout/types';
import { DistributionStoreState, IDistributionStoreState } from './distribution/types';
import { IAuthStoreState } from './auth/types';
import { IUserStoreState } from './user/types';
import { IRegionalInfoStoreState, RegionalInfoStoreState } from './region/types';
import regionReducer from './region/reducers';
import { HeaderStoreState, IHeaderStoreState } from './header/types';
import headerReducer from './header/reducer';
import { IShareStoreState, ShareStoreState } from './share/types';
import shareReducer from './share/reduser';
import { IProfileTicketStoreState } from './ticket/ProfileTicketStoreState';
import appSettingsReducer from './appSettings/reducers';
import { AppSettingsState, IAppSettingsState } from './appSettings/types';
import { ILocationStoreState, LocationStoreState } from './location/types';
import locationReducer from './location/reducers';
import resultInfoReducer, { resultInfoStoreState } from './resultInformarion/reducers';
import { ResultInfoType } from './resultInformarion/types';

export interface IGlobalStoreState extends PersistedState {
  app: IAppStoreState;
  events: Array<IEventStoreState>;
  book: IBookStoreState;
  checkout: ICheckoutStoreState;
  distribution: IDistributionStoreState;
  auth: IAuthStoreState;
  user: IUserStoreState;
  userEvents: Array<IEventStoreState>;
  userTickets: Array<IProfileTicketStoreState>;
  menu: IMenuStoreState;
  region: IRegionalInfoStoreState;
  header: IHeaderStoreState;
  share: IShareStoreState;
  appSettings: IAppSettingsState;
  location: ILocationStoreState;
  resultInfo: ResultInfoType;
}

export class GlobalStoreState implements IGlobalStoreState {
  app: IAppStoreState;
  events: Array<IEventStoreState>;
  checkout: ICheckoutStoreState;
  book: IBookStoreState;
  distribution: IDistributionStoreState;
  auth: IAuthStoreState;
  user: IUserStoreState;
  userEvents: Array<IEventStoreState>;
  userTickets: Array<IProfileTicketStoreState>;
  menu: IMenuStoreState;
  region: IRegionalInfoStoreState;
  header: IHeaderStoreState;
  share: IShareStoreState;
  appSettings: IAppSettingsState;
  location: ILocationStoreState;
  resultInfo: ResultInfoType;

  constructor() {
    this.app = new AppStoreState();
    this.events = new Array<IEventStoreState>();
    this.checkout = new CheckoutStoreState();
    this.book = new BookStoreState();
    this.distribution = new DistributionStoreState();
    this.auth = null;
    this.user = null;
    this.userEvents = [];
    this.userTickets = [];
    this.menu = new MenuStoreState(null);
    this.region = new RegionalInfoStoreState();
    this.header = new HeaderStoreState();
    this.share = new ShareStoreState();
    this.appSettings = new AppSettingsState();
    this.location = new LocationStoreState();
    this.resultInfo = resultInfoStoreState;
  }
}

export const rootReducer: Reducer<IGlobalStoreState> = combineReducers<IGlobalStoreState>({
  app: appReducer,
  events: eventsReducer,
  checkout: checkoutReducer,
  book: bookReducer,
  distribution: distributionReducer,
  auth: authReducer,
  user: userReducer,
  userEvents: userEventsReducer,
  userTickets: userTicketsReducer,
  menu: menuReducer,
  region: regionReducer,
  header: headerReducer,
  share: shareReducer,
  appSettings: appSettingsReducer,
  location: locationReducer,
  resultInfo: resultInfoReducer,
});

export interface ConnectedReduxProps<S extends Action> {
  dispatch: Dispatch<S>;
}
