import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { IGlobalStoreState } from '../../../store';
import { PageContent, RLink } from '../../../components/UI';
import { LoginForm, LoginFormType } from '../../../components/forms';
import { login } from '../../../store/user/actions';
import { Routes } from '../../../store/routes';
import { Utils } from '../../../services';
import { checkoutClear } from '../../../store/checkout/actions';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { PopUp } from '../../../store/animations';
import { ModalWindow } from './socialNetworkLogin/modalWindow';

export const isMobile = window.matchMedia('only screen and (max-width: 479px)').matches;

const LoginPage = () => {
  const isAuthorized = useSelector<IGlobalStoreState, boolean>((state) => state.app.isAuthorized);
  const isInitializing = useSelector<IGlobalStoreState, boolean>((state) => state.app.isInitializing);

  const { getProfileRoute } = useRoutesHelper();

  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleFormSubmit = useCallback(
    (formModel: LoginFormType) => {
      if (formModel) {
        (async () => dispatch(login(formModel.email, formModel.password)))().then(() => {
          dispatch(checkoutClear());
          history.replace(getProfileRoute());
        });
      }
    },
    [dispatch, getProfileRoute, history]
  );

  if (isInitializing || isAuthorized) {
    return null;
  }

  return (
    <AnimatePresence>
      <PageContent center variants={PopUp} initial={'enter'} animate={'exit'}>
        <Helmet>
          <title>{Utils.toPageTitle(t('Login.Login'))}</title>
        </Helmet>
        <Header>{t<string>('Login.Login')}</Header>
        <LoginForm
          submitTitle={t('Login.Login')}
          onSubmit={(formModel: LoginFormType) => handleFormSubmit(formModel)}
        />
        <Link to={`${Routes.Registration}`}>{t('Login.SignUp')}</Link>
        {/*<Divider isMobile={isMobile}>{t('Login.Or')}</Divider>*/}
        {/*<ModalWindow />*/}
      </PageContent>
    </AnimatePresence>
  );
};

const Header = styled.h1`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
`;

const Link = styled(RLink)<{ theme: { fonts: { boldFont: string } } }>`
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.boldFont};
`;

export const Divider = styled.div<{ isMobile: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 30px 0;
  user-select: none;

  &:after {
    content: '';
    position: absolute;
    height: 0.5px;
    width: ${(props) => (props.isMobile ? '33%' : '35%')};
    display: block;
    left: 10%;
    background: #fff;
    top: 50%;
  }

  &:before {
    content: '';
    position: absolute;
    height: 0.5px;
    width: ${(props) => (props.isMobile ? '33%' : '35%')};
    display: block;
    right: 10%;
    background: #fff;
    top: 50%;
  }
`;

export default LoginPage;
