import * as React from 'react';
import { connect } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader';
import styled from 'styled-components';
import { IGlobalStoreState } from '../store';
import { AppTheme } from '../settings/appTheme';
import { FC } from 'react';

interface ISpinnerProps {
  isLoading: boolean;
}

const Container = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  max-height: 100vh;
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;
const override = {
  paddingTop: '90px',
  transform: 'translateX(calc(50% - 21px))',
};

export const Spinner: FC<ISpinnerProps> = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }
  return (
    <Container className="spinner">
      <BeatLoader color={AppTheme.colors.text} cssOverride={override} loading={isLoading} size={10} />
    </Container>
  );
};

const mapStateToProps = (state: IGlobalStoreState, ownProps: any) => ({
  isLoading: state.app.isLoading,
});

export default connect(mapStateToProps)(Spinner);
