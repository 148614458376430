import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import EventHelper from './eventHelper';
import { AppSettings } from '../settings/appSettings';
import { IBookStoreState } from '../store/book/types';
import { AlertType, BookStatus, Pages } from '../store/enums';
import { store } from '../index';
import { Routes } from '../store/routes';
import { IUserStoreState } from '../store/user/types';

export const useRoutesHelper = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const changeDomainTopLevel = (domainTopLevel: string, hostName: string): string => {
    const parseHostName = hostName.split('.');
    const hostDomainToplevel = parseHostName[parseHostName.length - 1];
    return `${location.protocol}//${hostName.replace(hostDomainToplevel, domainTopLevel)}`;
  };

  const handleEventLink = (eventDomainTypes, callback) => {
    const domain = location.host;

    if (domain.includes(AppSettings.LOCALHOST)) {
      history.push(callback);
      return;
    }

    const { getDomainType, getDomainTopLevel } = EventHelper;
    const sortEventDomainTypes = eventDomainTypes.sort((a, b) => a - b);

    const domainType = getDomainType(domain);
    const isDomainExist = sortEventDomainTypes.includes(domainType);

    if (isDomainExist || isEmpty(eventDomainTypes)) {
      history.push(callback);
      return;
    }

    const [firstDomain] = eventDomainTypes;
    const domainName = getDomainTopLevel(firstDomain);
    const changedDomain = changeDomainTopLevel(domainName, domain);
    open(`${changedDomain}${callback}`);
  };

  const backRoute = () => {
    const isEventDetailsPage = isActivePage(Pages.EventDetails);
    if (history.length === 1 && isEventDetailsPage) {
      location.replace(location.origin);
      return;
    }
    history.goBack();
  };

  const getBookRoute = useCallback(
    (
      eventSlug: string,
      quotaId?: string,
      sectorSlug?: string,
      rowSlug?: string,
      tickets?: number,
      sell = false
    ): string => {
      const page = `/${sell ? Pages.Sell : Pages.Book}/${eventSlug}`;
      const quota = !!quotaId ? `/${quotaId}` : '';
      const sector = !!sectorSlug ? `/${sectorSlug}` : '';
      const sectorRow = !!sector && !!rowSlug ? `/${rowSlug}` : '';
      const ticketsCount = !!sector && !sectorRow && !!tickets ? `/${tickets}` : '';
      return page + sector + quota + sectorRow + ticketsCount;
    },
    []
  );

  const getBookRouteFromBook = (book: IBookStoreState, sell = false): string => {
    if (!book || !book.event) {
      return null;
    }
    return getBookRoute(book.event.slug, null, book.sectorSlug, null, null, sell);
  };

  const getCheckoutRouteFromBook = (book: IBookStoreState): string => `${getBookRouteFromBook(book)}/${Pages.Checkout}`;

  const getBookResultRoute = (status: BookStatus = null): string => `/${Pages.Book}/${Pages.BookResult}/${status}`;

  const getRouteData = (): string[] => {
    const routeData = window.location.pathname.split('/');
    return routeData.filter((routeSegment) => !!routeSegment);
  };

  const getWidgetToken = (): string => {
    const routeData = getRouteData();
    const [widget, , slug] = routeData;
    let token = '';
    switch (widget) {
      case Pages.Widget:
        token = slug;
        break;
      default:
        token = '';
        break;
    }
    return token;
  };

  const getWidgetBookResultRoute = (status: BookStatus = null, token: string): string => {
    return `/${Pages.Widget}/${Pages.Book}/${token}/${Pages.BookResult}/${status}`;
  };

  const isActivePage = useCallback((pageName: string, subPageName?: string, options?: Array<string>): boolean => {
    if (window.location && window.location.pathname) {
      const routeData = window.location.pathname.substr(1).split('/');
      if (routeData.length) {
        const [currentPageName, currentSubPageName] = routeData;

        const isCurrentPage = pageName.toLowerCase().replace('/', '') === currentPageName.toLowerCase();

        if (!!subPageName) {
          const isCurrentSubPage =
            !!currentSubPageName && subPageName.toLowerCase() === currentSubPageName.toLowerCase();

          if (!!options) {
            let isCurrentOptionsPage = true;
            options.forEach((o) => {
              const isExistOpt = routeData.indexOf(o) > -1;
              if (!isExistOpt) {
                isCurrentOptionsPage = false;
              }
            });
            return isCurrentPage && isCurrentSubPage && isCurrentOptionsPage;
          }
          return isCurrentPage && isCurrentSubPage;
        }
        return isCurrentPage;
      }
    }
    return false;
  }, []);

  const getProfileRoute = useCallback((): string => {
    const state = store.getState();
    const isAuthorized = state.app.isAuthorized && state.user && state.user.email;

    if (isAuthorized && !state.user.phoneNumberConfirmed) {
      return Routes.EditProfile;
    } else if (isAuthorized) {
      return Routes.Profile;
    } else {
      return Routes.Login;
    }
  }, []);

  const getEventRoute = useCallback((eventSlug: string): string => {
    return `/${Pages.EventDetails}/${eventSlug}`;
  }, []);

  const getBaseUrl = (): string => location.origin || `${location.protocol}//${location.host}`;

  const getHostReferralUrl = (eventDomainTypes) => {
    const domain = location.host;

    const { getDomainType, getDomainTopLevel } = EventHelper;
    const domainType = getDomainType(domain);

    const isDomainExist = eventDomainTypes.includes(domainType);

    if (domain.includes(AppSettings.LOCALHOST) || isDomainExist || isEmpty(eventDomainTypes)) {
      return getBaseUrl();
    }

    const [firstDomain] = eventDomainTypes;
    const domainName = getDomainTopLevel(firstDomain);
    return changeDomainTopLevel(domainName, domain);
  };

  const generateReferralUrl = (
    user: IUserStoreState,
    domainTypes: number[],
    eventSlug?: string,
    sectorSlug?: string,
    rowSlug?: string,
    tickets?: number
  ): string =>
    `${getHostReferralUrl(domainTypes)}/${Pages.Referral}/${user.referralSlug}${eventSlug ? `/${eventSlug}` : ''}${
      !!eventSlug && !!sectorSlug ? `/${sectorSlug}` : ''
    }${!!eventSlug && !!sectorSlug && !!rowSlug ? `/${rowSlug}` : ''}${
      !!eventSlug && !!sectorSlug && !rowSlug && !!tickets ? `/${tickets}` : ''
    }`;

  const getResultStatusRoute = useCallback((status, key) => {
    const isSuccess = status !== AlertType.Error;

    return `${isSuccess ? Routes.Success : Routes.Failed}/${key}`;
  }, []);

  const getWidgetResultStatusRoute = useCallback((status, key) => {
    const isSuccess = status !== AlertType.Error;

    return `/${Pages.Widget}/${isSuccess ? Pages.Success : Pages.Failed}/${key}`;
  }, []);

  const getWidgetCheckoutRouteFromBook = (book: IBookStoreState, token: string): string => {
    if (!book || !book.event) return null;
    return `/${Pages.Widget}/${Pages.Book}/${token}/${Pages.Checkout}`;
  };

  const getWidgetRouteFromToken = (token: string): string => {
    if (!token) return null;
    return `/${Pages.Widget}/${Pages.Book}/${token}`;
  };

  const getUserTicketsRoute = useCallback(() => `/${Pages.User}/${Pages.UserTickets}`, []);

  const getUserTicketPurchaseRoute = useCallback((masterTicketId: string, ticketId: string): string => {
    return `${getUserTicketsRoute()}/purchase/${masterTicketId}/${ticketId}`;
  }, []);

  const getUserTicketRoute = useCallback((masterTicketId: string, ticketId: string): string => {
    return `${getUserTicketsRoute()}/${masterTicketId}/${ticketId}`;
  }, []);

  const getUserTicketSellRoute = useCallback((masterTicketId: string, ticketId: string): string => {
    return `${getUserTicketsRoute()}/sell/${masterTicketId}/${ticketId}`;
  }, []);

  return {
    handleEventLink,
    changeDomainTopLevel,
    backRoute,
    getCheckoutRouteFromBook,
    getBookResultRoute,
    getWidgetToken,
    getWidgetBookResultRoute,
    isActivePage,
    getProfileRoute,
    getBookRoute,
    getEventRoute,
    getBaseUrl,
    generateReferralUrl,
    getResultStatusRoute,
    getWidgetCheckoutRouteFromBook,
    getWidgetRouteFromToken,
    getWidgetResultStatusRoute,
    getUserTicketRoute,
    getUserTicketSellRoute,
    getUserTicketPurchaseRoute,
    getUserTicketsRoute,
  };
};
