export interface ILocationStoreState {
  location: string;
}

export class LocationStoreState implements ILocationStoreState {
  location: string;

  constructor(dto?: any) {
    this.location = dto && dto.location ? dto.location : '';
  }
}

export const enum LocationActionTypes {
  INIT_LOCATION = '@@location/INIT_LOCATION',
}
