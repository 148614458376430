import { Reducer } from 'redux';

import { IAppSettingsState, AppSettingsState, AppSettingsActionTypes } from './types';

const appSettingsReducer: Reducer<IAppSettingsState> = (state: IAppSettingsState = new AppSettingsState(), action) => {
  switch (action.type) {
    case AppSettingsActionTypes.APP_SETTINGS_INIT:
      return { ...action.payload } as IAppSettingsState;
    default:
      return state;
  }
};

export default appSettingsReducer;
