import dayjs from 'dayjs';
import { BaseApiService } from '../baseApiService';
import {
  EventCategory,
  EventShortInformation,
  EventStoreState,
  EventType,
  IEventCategory,
  IEventShortInformation,
  IEventStoreState,
  ILocationShortInformation,
  ISchema,
  LocationShortInformation,
  Schema,
} from '../../store/event/types';
import { EventDetailsStoreState, IEventDetailsStoreState } from '../../store/event/eventDetails/EventDetailsStoreState';
import EventHelper from '../../helpers/eventHelper';

export type GetEventByTypeParams = {
  eventType: EventType;
  search?: string | null;
  date?: Date | null;
  categoryId?: number | null;
  count?: number | null;
  city?: string | null;
};

export type GetEventByCategoriesParams = { categoryId: number };
export type GetCategoryParams = { categorySlug: string };
export type GetSectorSchemaParams = { venueSlug: string; sectorSlug: string; schemaVersion: number };
export type GetVenueSchemaParams = { venueSlug: string; schemaVersion: number };

export class EventsApiService extends BaseApiService {
  private static _instance: EventsApiService;

  constructor(config?: any) {
    if (typeof EventsApiService._instance === 'undefined') {
      super(config);
      EventsApiService._instance = this;
    }
    return EventsApiService._instance;
  }

  public async getDatepickerDates(date: Date): Promise<Array<Date>> {
    const responce = await this.get(`/calendar/dates?baseDate=${dayjs(date).format('YYYY-MM-DDTHH:mm:ss')}`);
    return responce.data ? responce.data.map((item: string | number | Date) => new Date(item)) : [];
  }

  public async getEventsCategories(): Promise<Array<IEventCategory>> {
    const domain = window.location.hostname;
    const resultDomainType = EventHelper.getDomainType(domain);
    const response = await this.get(`/categories?dtype=${resultDomainType}`);
    return response.data ? response.data.map((item) => new EventCategory(item)) : [];
  }

  public async getCategory(eventParams: GetCategoryParams): Promise<IEventCategory> {
    const response = await this.get(`/categories/${eventParams.categorySlug}`);
    return new EventCategory(response.data);
  }

  public async getSectorSchema(eventParams: GetSectorSchemaParams): Promise<ISchema> {
    const response = await this.get(
      `/venue/${eventParams.venueSlug}/sector/${eventParams.sectorSlug}/schema?version=${eventParams.schemaVersion}`
    );
    return new Schema(response.data);
  }

  public async getVenueSchema(eventParams: GetVenueSchemaParams): Promise<ISchema> {
    const response = await this.get(`/venue/${eventParams.venueSlug}/schema?version=${eventParams.schemaVersion}`);
    return new Schema(response.data);
  }

  public async getEventsByType(eventParams: GetEventByTypeParams): Promise<Array<IEventShortInformation>> {
    const domain = window.location.hostname;
    const resultDomainType = EventHelper.getDomainType(domain);

    let params = `?eventType=${eventParams.eventType}&dtype=${resultDomainType}`;

    if (eventParams.search) {
      params += `&search=${eventParams.search}`;
    }

    if (eventParams.date) {
      params += `&date=${dayjs(eventParams.date).format('YYYY-MM-DDTHH:mm:ss')}`;
    }

    if (eventParams.categoryId) {
      params += `&categoryId=${eventParams.categoryId}`;
    }

    if (eventParams.city) {
      params += `&city=${eventParams.city}`;
    }

    if (eventParams.count) {
      params += `&count=${eventParams.count}`;
    }

    const response = await this.get(params);
    return response.data ? response.data.map((item) => new EventShortInformation(item)) : [];
  }

  public async getEventsByCategories(eventParams: GetEventByCategoriesParams): Promise<Array<IEventShortInformation>> {
    const domain = window.location.hostname;
    const resultDomainType = EventHelper.getDomainType(domain);
    const params = `?categoryId=${eventParams.categoryId}&eventType=${EventType.Category}&dtype=${resultDomainType}`;
    const response = await this.get(params);
    return response.data ? response.data.map((item) => new EventShortInformation(item)) : [];
  }

  public async searchEvents(search: string): Promise<Array<IEventShortInformation>> {
    const response = await this.get(`/search?name=${search}&count=6`);
    return response.data ? response.data.map((item: any) => new EventShortInformation(item)) : [];
  }

  public async searchLocations(search: string): Promise<Array<ILocationShortInformation>> {
    const response = await this.get(`locations/search?query=${search}&count=6`);
    return response.data ? response.data.map((item: any) => new LocationShortInformation(item)) : [];
  }

  public async getLocations(): Promise<Array<ILocationShortInformation>> {
    const response = await this.get(`locations`);
    return response.data ? response.data.map((item: any) => new LocationShortInformation(item)) : [];
  }

  public async getEvents(city: string): Promise<Array<IEventStoreState>> {
    const domain = window.location.hostname;
    const resultDomainType = EventHelper.getDomainType(domain);
    const response = await this.get(`list?dtype=${resultDomainType}`);
    return response.data ? response.data.map((item: any) => new EventStoreState(item)) : [];
  }

  public async getEventById(id: string, distributorSlug?: string, token?: string): Promise<IEventStoreState> {
    const domain = window.location.hostname;
    const resultDomainType = EventHelper.getDomainType(domain);
    const response = await this.get(
      `book/details/${id}${distributorSlug ? `/${distributorSlug}` : ''}${
        token ? `/${token}` : ''
      }?dtype=${resultDomainType}`
    );
    return new EventStoreState(response.data);
  }

  public async getSellEventDetails(slug: string): Promise<IEventStoreState> {
    const response = await this.get(`sell/details/${slug}`);
    return new EventStoreState(response.data);
  }

  public async getEventDetailsBySlug(
    id: string,
    distributorSlug?: string,
    token?: string
  ): Promise<IEventDetailsStoreState> {
    const domain = window.location.hostname;
    const resultDomainType = EventHelper.getDomainType(domain);
    const response = await this.get(
      `details/${id}${distributorSlug ? `/${distributorSlug}` : ''}${
        token ? `/${token}` : ''
      }?dtype=${resultDomainType}`
    );
    return new EventDetailsStoreState(response.data);
  }

  public async getUserEvents(): Promise<Array<IEventStoreState>> {
    const domain = window.location.hostname;
    const resultDomainType = EventHelper.getDomainType(domain);

    const response = await this.get(`/my?dtype=${resultDomainType}`);
    return response.data && response.data.Events ? response.data.Events.map((item) => new EventStoreState(item)) : [];
  }

  public async startDistribute(id: string): Promise<void> {
    const response = await this.post(`/my/${id}`);
    return Promise.resolve();
  }

  public async stopDistribute(id: string): Promise<void> {
    const response = await this.delete(`/my/${id}`);
    return Promise.resolve();
  }
}
