import { GetSectorSchemaParams, GetVenueSchemaParams, ThunkService } from '.';
import { GetCategoryParams, GetEventByCategoriesParams, GetEventByTypeParams } from './repositories';
import { AppSettings } from '../settings/appSettings';

export default function useApi() {
  const thunkService = new ThunkService({ baseURL: `${AppSettings.BASE_API_URL}` });

  const eventsApi = getEventsAPI(thunkService);
  return {
    eventsApi,
  };
}

export function getEventsAPI(thunkService: ThunkService) {
  return {
    getEventsCategories: () => thunkService.api.events.getEventsCategories(),
    getCategory: (params: GetCategoryParams) => thunkService.api.events.getCategory(params),
    getEventsByType: (params: GetEventByTypeParams) => thunkService.api.events.getEventsByType(params),
    getEventsByCategories: (params: GetEventByCategoriesParams) =>
      thunkService.api.events.getEventsByCategories(params),
    searchEvents: async (search: string) => thunkService.api.events.searchEvents(search),
    searchLocations: async (search: string) => thunkService.api.events.searchLocations(search),
    getLocations: async () => thunkService.api.events.getLocations(),
    getDatepickerDates: (date: Date) => thunkService.api.events.getDatepickerDates(date),
    getSectorSchema: (date: GetSectorSchemaParams) => thunkService.api.events.getSectorSchema(date),
    getVenueSchema: (date: GetVenueSchemaParams) => thunkService.api.events.getVenueSchema(date),
  };
}
