import { BaseApiService } from '../baseApiService';
import {
  IPaymentInitiateResult,
  PaymentInitiateResult,
  IPaymentInitiateInfo,
  IPaymentProcessInfo,
  IPaymentProcessResult,
  PaymentProcessResult,
  IPaymentCompleteInfo,
  IPaymentCompleteResult,
  PaymentCompleteResult,
} from '../../store/payment/types';
import EventHelper from '../../helpers/eventHelper';

export class PaymentsApiService extends BaseApiService {
  private static _instance: PaymentsApiService;

  constructor(config?: any) {
    if (typeof PaymentsApiService._instance === 'undefined') {
      super(config);
      PaymentsApiService._instance = this;
    }
    return PaymentsApiService._instance;
  }

  public async widgetInitiate(model: IPaymentInitiateInfo): Promise<IPaymentInitiateResult> {
    const response = await this.post('/widgetInitiate', model);
    return new PaymentInitiateResult(response.data);
  }

  public async widgetProcess(model: IPaymentProcessInfo): Promise<IPaymentProcessResult> {
    const response = await this.post('/widgetProcess', model);
    return new PaymentProcessResult(response.data);
  }

  public async initiate(model: IPaymentInitiateInfo): Promise<IPaymentInitiateResult> {
    const domain = window.location.hostname;
    const resultDomainType = EventHelper.getDomainType(domain);
    const response = await this.post(`/initiate?dtype=${resultDomainType}`, model);
    return new PaymentInitiateResult(response.data);
  }

  public async process(model: IPaymentProcessInfo): Promise<IPaymentProcessResult> {
    const domain = window.location.hostname;
    const resultDomainType = EventHelper.getDomainType(domain);
    const response = await this.post(`/process?dtype=${resultDomainType}`, model);
    return new PaymentProcessResult(response.data);
  }

  public async complete(model: IPaymentCompleteInfo): Promise<IPaymentCompleteResult> {
    const domain = window.location.hostname;
    const resultDomainType = EventHelper.getDomainType(domain);
    const response = await this.post(`/complete?dtype=${resultDomainType}`, model);
    return new PaymentCompleteResult(response.data);
  }
}
