import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import { createMigrate, MigrationManifest, PersistConfig, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/es/storage';
import thunk from 'redux-thunk';
import { ThunkService } from './services';
import { IGlobalStoreState, rootReducer } from './store';
import { AppSettings } from './settings/appSettings';

const thunkService = new ThunkService({
  baseURL: `${AppSettings.BASE_API_URL}`,
});

const enhancer = compose(applyMiddleware(thunk.withExtraArgument(thunkService)));

const migrations: MigrationManifest = {
  0: (state: IGlobalStoreState) => ({ ...state }),
  1: (state: IGlobalStoreState) => ({
    ...state,
    app: {
      ...state.app,
      isRedirection: false,
    },
  }),
  2: (state: IGlobalStoreState) => ({
    ...state,
    user: {
      ...state.user,
      balances: [],
    },
  }),
};

const persistConfig: PersistConfig = {
  key: 'fanaticka',
  storage,
  stateReconciler: autoMergeLevel2,
  version: 3,
  whitelist: ['app', 'auth', 'book', 'checkout', 'distribution', 'user', 'region', 'userTickets'],
  migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const thunk = thunkService;
  const store = createStore(persistedReducer, enhancer);
  const persistor = persistStore(store);

  if (module.hot) {
    module.hot.accept(() => {
      store.replaceReducer(persistReducer(persistConfig, rootReducer));
    });
  }

  return { store, persistor, thunk };
};
