import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import dayjs from 'dayjs';
import YoutubePlayer from 'react-youtube-player';
import i18next from 'i18next';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { IGlobalStoreState } from '../store';
import { Button, RLink } from '../components/UI';
import Timer from '../components/timer/timer';
import { BookStatus, DomainType, Locale, Pages } from '../store/enums';
import { checkCurrentCheckout, checkoutClear, checkoutComplete } from '../store/checkout/actions';
import { IBookStoreState } from '../store/book/types';
import { logout } from '../store/user/actions';
import { PopUp } from '../store/animations';
import { hideMenu, showMenu } from '../store/menu/actions';
import { IUserStoreState } from '../store/user/types';
import { CheckoutHelper, EventHelper } from '../helpers';
import { ICheckoutStoreState } from '../store/checkout/types';
import { TestBanner } from '../components/testBanner';
import { IHeaderStoreState } from '../store/header/types';
import { hidePlayer, showPlayer } from '../store/header/actions';
import { IAppSettingsState } from '../store/appSettings/types';
import { AppSettings } from '../settings/appSettings';
import { AppTheme } from '../settings/appTheme';
import { useRoutesHelper } from '../helpers/routesHelper';
import UserHelper from '../helpers/userHelper';

const Header: FC = () => {
  let timer: ReturnType<typeof setTimeout>;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const checkout = useSelector<IGlobalStoreState, ICheckoutStoreState>((state) => state.checkout);
  const header = useSelector<IGlobalStoreState, IHeaderStoreState>((state) => state.header);
  const book = useSelector<IGlobalStoreState, IBookStoreState>((state) => state.book);
  const isMenuShow = useSelector<IGlobalStoreState, boolean>((state) => state.menu.isShow);
  const isAuthorized = useSelector<IGlobalStoreState, boolean>((state) => state.app.isAuthorized);
  const appSettings = useSelector<IGlobalStoreState, IAppSettingsState>((state) => state.appSettings);
  const user = useSelector<IGlobalStoreState, IUserStoreState>((state) => state.user);

  const [opacity, setOpacity] = useState(0);
  const [checkoutCheckAt, setCheckoutCheckAt] = useState(null);
  const topOffset = 0;
  const domain = window.location.host;
  const location = useLocation();
  const milliSeconds = 1000;

  const {
    backRoute,
    getCheckoutRouteFromBook,
    getBookResultRoute,
    getWidgetToken,
    getWidgetBookResultRoute,
    isActivePage,
    getProfileRoute,
  } = useRoutesHelper();

  const token = getWidgetToken();
  const isMainPage = isActivePage('/');
  const isInformationFailedResultPage = isActivePage(Pages.Failed);
  const isInformationSuccessResultPage = isActivePage(Pages.Success);
  const isNotInformationResultPage = !isInformationFailedResultPage && !isInformationSuccessResultPage;
  const isBookPage = isActivePage(Pages.Book) || isActivePage(Pages.Sell);
  const isSharePage = isActivePage(Pages.Share);
  const isBookResultPage = isActivePage(Pages.Book, Pages.BookResult);
  const isCheckoutPage = book && book.event && isActivePage(Pages.Book, book.event.slug, [Pages.Checkout]);
  const isProfilePage = isActivePage(Pages.User, Pages.Profile);
  const isEditProfilePage = isActivePage(Pages.User, Pages.EditProfile);
  const isUserEventsPage = isActivePage(Pages.User, Pages.UserEvents);
  const isUserTicketsPage = isActivePage(Pages.User, Pages.UserTickets);
  const isContactsPage = isActivePage(Pages.Contacts);
  const isPaymentsInfoPage = isActivePage(Pages.PaymentsInfo);
  const isReferralPage = isActivePage(Pages.Referral);
  const isWidgetPage = isActivePage(Pages.Widget);
  const isWidgetInformationFailedResultPage = isActivePage(Pages.Widget, Pages.Failed);
  const isWidgetInformationSuccessResultPage = isActivePage(Pages.Widget, Pages.Success);
  const isWidgetCheckoutPage = book && book.event && isActivePage(Pages.Widget, Pages.Book, [token, Pages.Checkout]);
  const showBackBtn =
    isWidgetCheckoutPage || (!isWidgetPage && !isMainPage && isNotInformationResultPage && !isReferralPage);
  const isStaticPage = isContactsPage || isPaymentsInfoPage;
  const showMenuBtn = isMainPage;
  const showProfileBtn =
    !isProfilePage && !isEditProfilePage && !isMenuShow && isNotInformationResultPage && !isReferralPage;
  const showLogoutBtn = isAuthorized && (isProfilePage || isEditProfilePage);
  const showLogo = isWidgetPage ?
                    appSettings.showLogo :
                      (!isMainPage || appSettings.showLogo) && !isUserEventsPage && !isUserTicketsPage;
  const showAd = isMainPage && !appSettings.useNewDashboard;
  const isTransparent =
    (!isStaticPage && !isBookPage && !isSharePage && !isWidgetPage) ||
    isBookResultPage ||
    isWidgetCheckoutPage ||
    isCheckoutPage ||
    isInformationSuccessResultPage ||
    isInformationFailedResultPage ||
    isWidgetInformationSuccessResultPage ||
    isWidgetInformationFailedResultPage;

  const isTimer =
    CheckoutHelper.getIsInProcess(checkout) ||
    (!CheckoutHelper.getIsCompleted(checkout) && CheckoutHelper.getIsProcessed(checkout));
  const timeoutAt = checkout.process && checkout.process.paymentTimeoutAt;

  const isDomainEu = EventHelper.getDomainType(domain) === DomainType.Eu;

  const handleScroll = useCallback(
    (e) => {
      if (e && e.target && e.target.scrollingElement) {
        const { scrollTop } = e.target.scrollingElement;

        const currentOpacity = scrollTop <= 60 ? scrollTop / 150 : 0.4;
        const bannerSize = AppSettings.IS_DEV ? 70 + 66 : 66;
        const currentTopOffset = scrollTop <= bannerSize * 2 ? -scrollTop / 2 : -bannerSize;

        if (opacity !== currentOpacity || (header.showAdButton && topOffset !== currentTopOffset)) {
          setOpacity(currentOpacity);
        } else {
          setOpacity(0);
        }
      }
    },
    [header.showAdButton]
  );

  useEffect(() => {
    window.addEventListener('scroll', (e) => handleScroll(e));
    return () => window.removeEventListener('scroll', (e) => handleScroll(e));
  }, [handleScroll]);

  useEffect(() => {
    if (CheckoutHelper.getIsInProcess(checkout) && CheckoutHelper.getIsCompleted(checkout)) {
      history.push(getBookResultRoute(BookStatus.Success));
    }
  }, [checkout, getBookResultRoute, history]);

  useEffect(() => {
    if (CheckoutHelper.getIsProcessed(checkout) && !CheckoutHelper.getIsCompleted(checkout)) {
      timer = setTimeout(() => {
        dispatch(checkCurrentCheckout());
        setCheckoutCheckAt(dayjs());
      }, milliSeconds);
    }
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isTransparent) {
      setOpacity(0);
    } else {
      setOpacity(opacity);
    }
  }, [isTransparent, location]);

  useEffect(() => {
    const scrollTop = window.scrollY;
    const opacity = scrollTop <= 60 ? scrollTop / 150 : 0.4;

    if (isMenuShow) {
      setOpacity(0);
    } else {
      setOpacity(opacity);
    }
  }, [isMenuShow]);

  const handleModalBtnClick = () => {
    if (header.showPlayer) {
      dispatch(hidePlayer());
    } else {
      dispatch(showPlayer());
    }
  };

  const handleBackgroundClick = () => {
    dispatch(hidePlayer());
  };

  const handleMenuBtnClick = () => {
    if (isMenuShow) {
      dispatch(hideMenu());
    } else {
      dispatch(showMenu());
    }
  };

  const handleLogoutClick = () => {
    if (isAuthorized) {
      dispatch(logout());
      history.replace('/');
    }
  };

  const handleChangeLanguageClick = () => {
    if (i18next.language === Locale.ru) {
      i18next.changeLanguage(Locale.en);
      dayjs.locale(Locale.en);
    } else if (i18next.language === Locale.en) {
      i18next.changeLanguage(Locale.ru);
      dayjs.locale(Locale.ru);
    }
    localStorage.setItem('language', i18next.language);
  };

  const handleTimerClick = () => {
    if (CheckoutHelper.getIsProcessed(checkout)) {
      dispatch(checkoutComplete(checkout.process.referenceNumber, false, false));
      history.push(getCheckoutRouteFromBook(book));
    }
  };

  const handleStopTimer = useCallback(() => {
    dispatch(checkoutClear());
    if (isWidgetPage) {
      const token = getWidgetToken();
      history.push(getWidgetBookResultRoute(BookStatus.Timeout, token));
    } else {
      history.push(getBookResultRoute(BookStatus.Timeout));
    }
  }, []);

  const handleTickTimer = useCallback(() => {
    if (CheckoutHelper.getIsInProcess(checkout)) {
      const addSeconds = checkoutCheckAt?.clone().add(10, 's');
      const canCheck = !checkoutCheckAt || addSeconds.isSame(dayjs()) || addSeconds.isBefore(dayjs());
      if (canCheck) {
        dispatch(checkoutComplete(checkout.process.referenceNumber, false, false));
        setCheckoutCheckAt(dayjs());
      }
    }
  }, [checkout]);

  const handleGoToMainPage = () => {
    if (isMenuShow) {
      dispatch(hideMenu());
      return;
    }
    history.push('/');
  };

  return (
    <Navigation transparent={isTransparent} opacity={opacity} delayOpacity={isMenuShow} topOffset={topOffset}>
      {false && AppSettings.IS_DEV && <TestBanner />}
      <Container>
        {showAd && (
          <Advertising>
            <ALogoContainer key="earnTicket">
              <Button advertising onClick={handleModalBtnClick}>
                {t<string>('Dashboard.EarnOnATicket')}
              </Button>
            </ALogoContainer>
          </Advertising>
        )}
      </Container>
      <LinksList>
        <ListItem left>
          <div>
            {showBackBtn && (
              <ABackBtn key="back" to={(location) => ({ ...location })} title={t('Header.Back')} onClick={backRoute} />
            )}
            {showMenuBtn && (
              <MenuBtn opened={isMenuShow} key="menu" title={t('Header.Menu')} onClick={handleMenuBtnClick} />
            )}
          </div>
        </ListItem>
        <ListItem center>
          <AnimatePresence>
            {showLogo && (
              <ALogoContainer key="logo" style={{ marginLeft: 34 }}>
                {!isWidgetPage && (
                  <Link onClick={handleGoToMainPage} to={'/'} title={t('Header.GoToMainPage')}>
                    <Logo src={AppTheme.img.logo} alt={`${AppSettings.APP_NAME} ${t('Header.Logo')}`} />
                  </Link>
                )}
                {isWidgetPage && <Logo src={AppTheme.img.logo} alt={`${AppSettings.APP_NAME} ${t('Logo')}`} />}
              </ALogoContainer>
            )}
            {isUserEventsPage && !isUserTicketsPage && !showLogo && (
              <ATitle variants={PopUp} initial={'enter'} animate={'exit'} key="title">
                {t('Profile.MyEvents')}
              </ATitle>
            )}
            {!isUserEventsPage && isUserTicketsPage && !showLogo && (
              <ATitle variants={PopUp} initial={'enter'} animate={'exit'} key="title">
                {t('Profile.MyTickets')}
              </ATitle>
            )}
          </AnimatePresence>
        </ListItem>
        <ListItem right>
          {isTimer && !isMenuShow && (
            <TimerBtn key="timer" title={t('Header.GoToPaymentPage')} onClick={handleTimerClick}>
              <Timer endTime={timeoutAt} onStop={handleStopTimer} onTick={handleTickTimer} />
            </TimerBtn>
          )}
          {showProfileBtn && !isWidgetPage && (
            <AProfileBtn key="profile" to={getProfileRoute} logged={+isAuthorized}>
              {UserHelper.getProfileIconEmblem(user)}
            </AProfileBtn>
          )}
          {showLogoutBtn && (
            <ALogoutBtn key="logout" to={'/'} onClick={handleLogoutClick}>
              {t('Profile.Logout')}
            </ALogoutBtn>
          )}
        </ListItem>
      </LinksList>
      {header.showPlayer && (
        <>
          <Modal>
            <YoutubePlayer videoId="SJTf2zHKtOw" playbackState="unstarted" />
          </Modal>
          <Background onClick={handleBackgroundClick} />
        </>
      )}
    </Navigation>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 910;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
`;
const Modal = styled.div`
  height: 364px;
  width: 100%;
  z-index: 1000;
  position: relative;
`;
const Advertising = styled.aside`
  padding: 15px 25px;
  margin-left: auto;
  margin-right: auto;
`;

const Navigation = styled.nav<{ transparent: boolean; opacity: number; topOffset: number; delayOpacity: boolean }>`
  background-color: ${(props) => (props.transparent ? `rgba(0,0,0,${props.opacity})` : props.theme.colors.bg)};
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
  position: ${(props) => (props.transparent ? 'fixed' : 'relative')};
  z-index: 900;
  top: ${(props) => `${props.topOffset}px`};
  transition: ${(props) => props.delayOpacity && 'background-color ease-in 0.2s 0.3s'};
`;

const LinksList = styled(motion.ul)`
  align-items: center;
  padding: 15px 25px;
  margin: 0;
  list-style: none;
  display: flex;
`;

const ListItem = styled.li<{ right?: boolean; left?: boolean; center?: boolean }>`
  display: flex;
  flex: 1;
  text-align: ${(props) => (props.right ? 'right' : props.left ? 'left' : 'center')};
  justify-content: ${(props) => (props.right ? 'flex-end' : props.left ? 'flex-start' : 'center')};
  align-items: center;
  min-height: 30px;
`;

const Link = styled(RLink)`
  display: flex;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 901;
`;

const ALogoContainer = (props) => <LogoContainer ref={props.hostRef} {...props} />;

const Logo = styled.img`
  height: 40px;
`;

const Title = styled(motion.h1)`
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.boldFont};
  position: absolute;
  margin: 0;
  padding: 0;
`;

const ATitle = (props) => <Title ref={props.hostRef} {...props} />;

const BackBtn = styled(RLink)`
  background: url(${(props) => props.theme.img.icons.back}) 0 no-repeat;
  width: 27px;
  height: 27px;
  border: none;
  display: inline-block;
  background-size: contain;
  z-index: 901;

  &:hover {
    cursor: pointer;
  }
`;

const ABackBtn = (props) => <BackBtn ref={props.hostRef} {...props} />;

const ProfileBtn = styled(RLink)<{ logged: boolean }>`
  background: ${(props) => (!props.logged ? `url(${props.theme.img.icons.profile}) 0 no-repeat` : 'unset')};
  width: 24px;
  height: 24px;
  border: ${(props) => (props.logged ? `1px solid ${props.theme.colors.text}` : 'none')};
  border-radius: ${(props) => props.logged && '50%'};
  padding: ${(props) => props.logged && '4px'};
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.lighterFont};
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  background-size: contain;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.colors.textSecond};
  }
`;

const AProfileBtn = (props) => <ProfileBtn ref={props.hostRef} {...props} />;

const LogoutBtn = styled(RLink)`
  margin-left: 10px;
`;

const ALogoutBtn = (props: any) => <LogoutBtn ref={props.hostRef} {...props} />;

const MenuBtn = styled('button')<{ opened: boolean }>`
  background: url(${(props: any) => (props.opened ? props.theme.img.icons.close : props.theme.img.icons.menu)})
    no-repeat;
  width: 24px;
  height: 24px;
  border: none;
  display: inline-block;
  background-size: contain;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;

const TimerBtn = styled('button')`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${(props) => props.theme.colors.text};
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.textSecond};
  }
`;

export default Header;
