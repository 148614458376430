import { BaseApiService } from '../baseApiService';
import {
  IBoughtTicketInfo,
  IIssueTicketInfo,
  ISellTicketInfo,
  ITicketStoreState,
  TicketStoreState,
} from '../../store/ticket/types';
import { IProfileTicketStoreState, ProfileTicketStoreState } from '../../store/ticket/ProfileTicketStoreState';
import { RequestType } from '../../store/enums';

export class TicketsApiService extends BaseApiService {
  private static _instance: TicketsApiService;

  constructor(config?: any) {
    if (typeof TicketsApiService._instance === 'undefined') {
      super(config);
      TicketsApiService._instance = this;
    }
    return TicketsApiService._instance;
  }

  public async issueTicket(model: IIssueTicketInfo): Promise<ITicketStoreState> {
    const response = await this.post(`/issue`, model);
    return new TicketStoreState(response.data);
  }

  public async sellTicket(model: ISellTicketInfo): Promise<ITicketStoreState> {
    const response = await this.post(`/sell`, model);
    return new TicketStoreState(response.data);
  }

  public async boughtTicket(model: IBoughtTicketInfo): Promise<ITicketStoreState> {
    const response = await this.post(`/buy`, model);
    return new TicketStoreState(response.data);
  }

  public async cancelSellTicket(ticketId: string): Promise<IProfileTicketStoreState> {
    const response = await this.post(`/cancelSell/${ticketId}`);
    return new ProfileTicketStoreState(response.data);
  }

  public async resendTicket(ticketId: string): Promise<void> {
    // await this.post('/resend', { ticketId });
  }

  public async getUserTickets(): Promise<Array<IProfileTicketStoreState>> {
    const response = await this.get('/my', {}, RequestType.userTickets);
    return response.data && response.data.Tickets
      ? response.data.Tickets.map((item) => new ProfileTicketStoreState(item))
      : [];
  }
}
