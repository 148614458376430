import React, { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { IGlobalStoreState } from '../../../store';
import { PageContent } from '../../../components/UI';
import { ChangePasswordInfo, IUserStoreState, UpdateUserStoreState } from '../../../store/user/types';
import { EditProfileForm } from '../../../components/forms';
import { confirmPhone, updatePhone, updateUser } from '../../../store/user/actions';
import { Utils } from '../../../services';
import { IErrorStoreState } from '../../../store/app/types';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { PopUp } from '../../../store/animations';

export type IEditProfileForm = {
  phoneCode: string;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
  phoneNumber: string;
  fullName: string;
  referralSlug: string;
  imageUrl: string;
  email: string;
  emailConfirmed: boolean;
  phoneNumberConfirmed: boolean;
};

const EditProfilePage: FC = () => {
  const { getProfileRoute } = useRoutesHelper();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAuthorized = useSelector<IGlobalStoreState, boolean>((state) => state.app.isAuthorized);
  const user = useSelector<IGlobalStoreState, IUserStoreState>((state) => state.user);
  const error = useSelector<IGlobalStoreState, IErrorStoreState>((state) => state.app.error);

  const [errors, setErrors] = useState(null);

  useLayoutEffect(() => {
    if (!isAuthorized) {
      history.replace(getProfileRoute());
      return;
    }
  }, []);

  useEffect(() => {
    setErrors({ form: error });
  }, [error?.message, error?.details]);

  const handleSendCodeClick = useCallback((phone: string) => {
    if (!phone) {
      return;
    }
    dispatch(updatePhone(phone));
    setErrors({ phone: null });
  }, []);

  const handleFormSubmit = useCallback(
    async (formModel: IEditProfileForm) => {
      if (!formModel) {
        return;
      }

      const updateUserModel = new UpdateUserStoreState(formModel.fullName, formModel.referralSlug);
      const updatePasswordModel =
        !!formModel.oldPassword && !!formModel.newPassword
          ? new ChangePasswordInfo(formModel.oldPassword, formModel.newPassword)
          : null;
      const isPhoneConfirmed = user.phoneNumberConfirmed;
      const isPhoneChanged = formModel.phoneNumber !== user.phoneNumber;
      const isFullNameChanged = formModel.fullName !== user.fullName;
      const isReferralSlugChanged = formModel.referralSlug !== user.referralSlug;

      if (isFullNameChanged || isReferralSlugChanged || updatePasswordModel) {
        await dispatch(updateUser(updateUserModel, updatePasswordModel));
      }

      if ((!isPhoneConfirmed || isPhoneChanged) && formModel.phoneCode) {
        await dispatch(confirmPhone(formModel.phoneCode, formModel.phoneNumber));
      }

      history.push(getProfileRoute());
    },
    [
      dispatch,
      getProfileRoute,
      history,
      user?.fullName,
      user?.phoneNumber,
      user?.phoneNumberConfirmed,
      user?.referralSlug,
    ]
  );

  return (
    <AnimatePresence>
      <PageContent center variants={PopUp} initial={'enter'} animate={'exit'}>
        <Helmet>
          <title>{Utils.toPageTitle(t('EditProfileForm.EditingProfile'))}</title>
        </Helmet>
        <Header>{t<string>('EditProfileForm.Editing')}</Header>
        {isAuthorized && (
          <EditProfileForm
            errors={errors}
            user={user}
            submitTitle={t('EditProfileForm.Save')}
            onSendCodeClick={(phone: string) => handleSendCodeClick(phone)}
            onSubmit={(formModel: IEditProfileForm) => handleFormSubmit(formModel)}
          />
        )}
      </PageContent>
    </AnimatePresence>
  );
};

const Header = styled.h1`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
`;

export default EditProfilePage;
