import { AlertColor } from '@mui/material/Alert/Alert';
import { RequestType } from '../enums';

export interface IErrorStoreState {
  message: string;
  details?: string;
  requestType?: RequestType;
  type?: AlertColor;
}

export class ErrorStoreState implements IErrorStoreState {
  message: string;
  details?: string;
  requestType?: RequestType;
  type?: AlertColor;

  constructor(message: string, details?: string, requestType?: RequestType, type?: AlertColor) {
    this.message = message;
    this.details = details;
    this.requestType = requestType;
    this.type = type;
  }
}

export interface IAppStoreState {
  isInitializing: boolean;
  isFinalized: boolean;
  isAuthorized: boolean;
  isLoading: boolean;
  error: IErrorStoreState;
  isRedirection: boolean;
}

export class AppStoreState implements IAppStoreState {
  isInitializing: boolean;
  isFinalized: boolean;
  isAuthorized: boolean;
  isLoading: boolean;
  error: IErrorStoreState;
  isRedirection: boolean;

  constructor() {
    this.isInitializing = true;
    this.isFinalized = false;
    this.isAuthorized = false;
    this.isLoading = false;
    this.error = null;
    this.isRedirection = false;
  }
}

export interface IActionResponseStoreState {
  message: string;
  details: string;
}

export class ActionResponseStoreState implements IActionResponseStoreState {
  message: string;
  details: string;

  constructor(dto?: any) {
    this.message = dto && dto.Message ? dto.Message : '';
    this.details = dto && dto.MessageDetails ? dto.MessageDetails : '';
  }
}

export const enum AppActionTypes {
  APP_INIT = '@@app/INIT',
  APP_FINALIZE = '@@app/FINALIZE',
  SPINNER_SHOW = '@@spinner/SHOW',
  SPINNER_HIDE = '@@spinner/HIDE',
  APP_AUTHORIZE = '@@app/AUTHORIZE',
  APP_DEAUTHORIZE = '@@app/DEAUTHORIZE',
  SPLASH_HIDE = '@@splash/HIDE',
  ERROR_SET = '@@error/SET',
  ERROR_CLEAR = '@error/CLEAR',
  APP_REDIRECTION = '@app/REDIRECTION',
}
