import dayjs from 'dayjs';
import { IUserStoreState, IUserBalanceStoreState } from '../store/user/types';
import Constants from '../store/constants';
import { DomainSetting, LanguageSetting } from '../settings/appSettings';
import RouteService from '../services/routeService';

const routeSrv = new RouteService();

class UserHelper {
  public static generateReferralUrl(
    user: IUserStoreState,
    eventSlug?: string,
    sectorSlug?: string,
    rowSlug?: string,
    tickets?: number,
    host = true
  ): string {
    return (
      !!user &&
      `${host ? routeSrv.getBaseUrl() : ''}/r/${user.referralSlug}` +
        (!!eventSlug ? `/${eventSlug}` : '') +
        (!!eventSlug && !!sectorSlug ? `/${sectorSlug}` : '') +
        (!!eventSlug && !!sectorSlug && !!rowSlug ? `/${rowSlug}` : '') +
        (!!eventSlug && !!sectorSlug && !rowSlug && !!tickets ? `/${tickets}` : '') +
        '/'
    );
  }

  public static generateReferralUrlByToken(
    user: IUserStoreState,
    eventSlug: string,
    token: string,
    host = true
  ): string {
    return !!token && `${host ? routeSrv.getBaseUrl() : ''}/tr/${user.referralSlug}/${eventSlug}/${token}`;
  }

  public static isPhoneCodeSendingAvailable(user: IUserStoreState): boolean {
    return (
      !!user &&
      (!user.phoneCodeSentAt ||
        dayjs(user.phoneCodeSentAt).clone().add(Constants.ResendCodeInSecs, 's').isBefore(dayjs()))
    );
  }

  public static getProfileIconEmblem(user: IUserStoreState): string {
    if (!user) return '';
    const name = user.fullName || user.referralSlug;
    return name ? name[0] : '';
  }

  public static getBalanceByCurrency(user: IUserStoreState, currency: string): IUserBalanceStoreState {
    return !!user && !!currency && user.stats.balances.find((b) => b.currency === currency);
  }

  public static checkoutLanguage(): string {
    const domain = window.location.host;
    const parseDomain = domain.split('.');
    const topLevelDomain = parseDomain[parseDomain.length - 1];

    if (topLevelDomain !== DomainSetting.Eu) {
      return LanguageSetting.Ru;
    }

    const { language } = window.navigator;
    return language.includes(LanguageSetting.Ru) ? LanguageSetting.Ru : LanguageSetting.En;
  }
}

export default UserHelper;
