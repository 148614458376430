import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { IGlobalStoreState } from '../../../store';
import { Page, PageContent } from '../../../components/UI';
import { AlertType, KeyErrors } from '../../../store/enums';
import { boughtTicket, getTicketById } from '../../../store/user/actions';
import { IErrorStoreState } from '../../../store/app/types';
import { EventHelper } from '../../../helpers';
import { EventSellHeader } from '../../../components/event/eventSellHeader';
import { PurchaseForm } from '../../../components/forms/purchaseForm';
import { withTranslation } from 'react-i18next';
import { IProfileTicketStoreState } from '../../../store/ticket/ProfileTicketStoreState';
import { setResultInfo } from '../../../store/resultInformarion/actions';
import { ResultInfoType } from '../../../store/resultInformarion/types';
import RouteService from '../../../services/routeService';

interface IUserTicketPurchasePageProps {
  masterTicketId: string;
  ticketId: string;
  isFinalized: boolean;
  isAuthorized: boolean;
  error: IErrorStoreState;

  getTicketById: (masterTicketId: string, ticketId?: string) => Promise<IProfileTicketStoreState>;
  boughtTicket: (ticketId: string) => Promise<IProfileTicketStoreState>;
  go: (url: string) => void;
  setResultInfo: (config: ResultInfoType) => void;
  t: (key: string) => string;
}

interface IUserTicketPurchasePageState {
  ticket: IProfileTicketStoreState;
  errors: any;
  resendInSecs: number;
}

class UserTicketPurchasePage extends React.Component<IUserTicketPurchasePageProps, IUserTicketPurchasePageState> {
  private routeSrv = new RouteService();

  constructor(props: IUserTicketPurchasePageProps) {
    super(props);

    this.state = {
      ticket: null,
      errors: null,
      resendInSecs: 0,
    };
  }

  componentWillMount() {
    if (this.props.isFinalized) {
      this.initTicket(this.props.masterTicketId, this.props.ticketId);
    }
  }

  componentWillReceiveProps(nextProps: IUserTicketPurchasePageProps) {
    /*const isFinalizedChanged = this.props.isFinalized !== nextProps.isFinalized && nextProps.isFinalized;
    const isIdChanged = this.props.masterTicketId !== nextProps.masterTicketId || this.props.ticketId !== nextProps.ticketId;
    const isErrorChanged = !Utils.isEqual(this.props.error, nextProps.error);
    if (isIdChanged || isFinalizedChanged) this.initTicket(nextProps.masterTicketId, nextProps.ticketId);
    if (!nextProps.isAuthorized) this.props.go(this.routeSrv.getProfileRoute());
    if (isErrorChanged) this.setState({ errors: { form: nextProps.error } });*/
  }

  render() {
    const ticket = this.state.ticket;
    if (!this.props.isFinalized || !ticket || !ticket.masterTicketId) {
      return null;
    }
    const event = ticket.event;
    return (
      <Page title={`${this.props.t('TicketIssue')} | ${EventHelper.getEventDetailsMetaDescription(event)}`}>
        <EventSellHeader ticket={ticket} />
        <PageContent roundCorners>{this.renderCheckoutContent()}</PageContent>
      </Page>
    );
  }

  private renderCheckoutContent() {
    return <React.Fragment>{<PurchaseForm token={this.state.ticket.token} />}</React.Fragment>;
  }

  private initTicket(masterTicketId: string, ticketId?: string) {
    if (!masterTicketId) {
      return;
    }
    this.props.boughtTicket(ticketId).then((ticket) => {
      if (!ticket || !ticket.masterTicketId) {
        this.props.setResultInfo({
          type: AlertType.Error,
          key: KeyErrors.TicketNotFound,
          title: 'Alert.WaitForTheServerToCompleteThePayment',
        });
        this.props.go(this.routeSrv.getResultStatusRoute(AlertType.Error, KeyErrors.TicketNotFound));
        return;
      }
      this.setState({ ticket });
    });
  }
}

const mapStateToProps = (state: IGlobalStoreState, ownProps: any) => {
  return {
    masterTicketId: ownProps.match && ownProps.match.params && ownProps.match.params.masterTicketId,
    ticketId: ownProps.match && ownProps.match.params && ownProps.match.params.ticketId,
    isFinalized: state.app.isFinalized,
    error: state.app.error,
    isAuthorized: state.app.isAuthorized,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  getTicketById: (masterTicketId: string, ticketId?: string) => dispatch(getTicketById(masterTicketId, ticketId)),
  boughtTicket: (ticketId: string) => dispatch(boughtTicket(ticketId)),
  go: ownProps.history.replace,
  setResultInfo: (config: ResultInfoType) => dispatch(setResultInfo(config)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserTicketPurchasePage)));
