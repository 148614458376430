import React, { FC, memo, useEffect, useState } from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { EventCarousel } from './eventCarousel';
import { useRouteService } from '../../helpers/customHooks';

const useStyles = makeStyles()(() => ({
  gridPopularEvents: {
    marginBottom: 50,
  },
  headingText: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: '14px',
    cursor: 'default',
  },
  gridPopular: {
    marginTop: 50,
  },
}));

export const Events: FC<EventPropsType> = memo(({ city, load, title, reloadDependencyValue }) => {
  const { classes } = useStyles();
  const routeSrv = useRouteService();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [events, setEvents] = useState<ItemEventCarouselType[]>([]);

  const eventsCount = 6;

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      const result = await load();
      setEvents(result);
      setIsLoading(false);
    })();
  }, [reloadDependencyValue]);

  const getEventsByLocation = () => {
    const filteredEvents = events.filter((event) => event?.city === city);
    const sortedEvents = [
      ...events.filter((event) => event.city === city),
      ...events.filter((event) => event.city !== city),
    ];
    return filteredEvents.length >= eventsCount ? filteredEvents : sortedEvents;
  };
  const eventsLocation = city && getEventsByLocation();

  return (
    <Grid item lg={12} xs={12} sm={12} md={12} xl={12} className={classes.gridPopularEvents}>
      <div>
        {title && isLoading && (
          <Typography variant="h6" className={classes.headingText}>
            <Skeleton variant="rectangular" width={140} height={25} />
          </Typography>
        )}

        {!isLoading && (
          <Typography variant="h6" className={classes.headingText}>
            {city && eventsLocation?.length >= eventsCount ? `${title}, ${city}` : title}
          </Typography>
        )}
      </div>
      <EventCarousel
        events={city ? eventsLocation : events}
        isLoading={isLoading}
        getItemLink={(itemKey) => routeSrv.getEventRoute(itemKey)}
      />
    </Grid>
  );
});

type EventPropsType = {
  load: () => any;
  reloadDependencyValue?: any;
  title?: string;
  city?: string;
};
export type ItemEventCarouselType = {
  key: string;
  city: string;
  imageUrl: string;
  title: string;
  date: Date;
  domainTypes: number[];
};
