import * as React from 'react';
import styled from 'styled-components';
import paymentFormStyles from './paymentFormStyles';
import { BePaidLink, UserAgreementLink } from '../UI';
import { FormInfo } from './commonForm';
import { PaymentSettings } from '../../settings/paymentSettings';
import { AppTheme } from '../../settings/appTheme';

declare let BeGateway: any;

interface IPurchaseFormProps {
  token: string;
}

interface IPurchaseFormState {
  formLoaded: boolean;
}

export class PurchaseForm extends React.Component<IPurchaseFormProps, IPurchaseFormState> {
  private _script: any;
  private _frame: any;
  private _DOM: any;

  constructor(props: IPurchaseFormProps) {
    super(props);

    this.state = {
      formLoaded: false,
    };
  }

  componentDidMount() {
    if (!this._script) {
      this._script = document.createElement('script');
      this._script.src = PaymentSettings.SCRIPT_URL;
      this._script.async = true;
      this._script.onload = () => this.buildForm();
      document.body.appendChild(this._script);
    }
  }

  componentWillUnmount() {
    document.body.removeChild(this._script);
  }

  render() {
    return (
      <React.Fragment>
        <PaymentForm id="payment-form" ref={(e) => this.setRef(e)} />
        <Notes>
          <NoteInfo>
            Нажимая на кнопку "Оплатить", вы принимаете условия{' '}
            <UserAgreementLink primary title="Публичного договора" />
          </NoteInfo>
          <BepaidInfo>
            Оплата обеспечивается <BePaidLink />
          </BepaidInfo>
          <Logos />
        </Notes>
      </React.Fragment>
    );
  }

  private setRef(element: any) {
    if (!this._DOM) {
      this._DOM = element;
    }
    this._DOM.addEventListener('DOMSubtreeModified', (e) => {
      const iframe = document.getElementsByTagName('iframe')[0];
      if (!this._frame && iframe) {
        this._frame = iframe;
        this._frame.onload = (e) => {
          this.setState({ formLoaded: true });
        };
      }
    });
  }

  private buildForm() {
    const options = {
      type: 'inline',
      id: 'payment-form',
      url: `${PaymentSettings.BASE_API_URL}/checkout?token=${this.props.token}`,
      style: paymentFormStyles, // this.props.styles,
      size: { height: 260 },
    };

    const pf = new BeGateway(options);
    pf.buildForm();
  }
}

const PaymentForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  & > #beGatewayForm {
    flex: 1;
    display: flex;
    flex-direction: column;

    iframe {
      width: 100% !important;
      height: auto !important;
      flex: 1;
    }
  }
`;

const BepaidInfo = styled.div`
  font-size: 10px;
  text-align: center;
`;

const NoteInfo = styled(FormInfo as any)`
  color: ${(props) => props.theme.colors.text};
  text-align: center;
`;

const Notes = styled.div`
  margin-top: 20px;
`;

const Logos = styled.div`
  display: block;
  margin: 0 auto;
  background: url(${AppTheme.img.payment.formLogos}) center no-repeat;
  background-size: contain;
  height: 40px;
  margin-top: 15px;
`;

export default PurchaseForm;
