import * as React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import Button from './button';
import { Utils } from '../../services';

interface ICopyButtonProps {
  value: string;
  title?: string;
  text?: string;
  t: (key: string) => string;
}

interface ICopyButtonState {
  isCopied: boolean;
}

export class CopyButton extends React.Component<ICopyButtonProps, ICopyButtonState> {
  private _inputDOM: any;
  private _copyDelayMs = 5 * 1000;

  constructor(props: ICopyButtonProps) {
    super(props);
    this.state = {
      isCopied: false,
    };
  }

  render() {
    const copied = this.state.isCopied;
    const btnText = copied ? this.props.t('Share.Copied') : this.props.text;
    return (
      <>
        <Text ref={(item) => (this._inputDOM = item)} tabIndex={-1} value={this.props.value} readOnly />
        <Button
          key="big"
          copy={!btnText}
          type="button"
          onClick={(e) => this.copyToClipboard(e)}
          title={this.props.title}
          disabled={copied}
        >
          {btnText}
        </Button>
      </>
    );
  }

  private copyToClipboard(e: any) {
    if (e) {
      e.preventDefault();
    }
    if (this._inputDOM) {
      if (Utils.isIOs()) {
        const range = document.createRange();
        range.selectNodeContents(this._inputDOM);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        this._inputDOM.setSelectionRange(0, 999999);
      } else {
        this._inputDOM.select();
      }
      document.execCommand('copy');
      this.setState({ isCopied: true }, () => {
        setTimeout(() => this.setState({ isCopied: false }), this._copyDelayMs);
      });
    }
  }
}

const Text = styled.textarea`
  width: 0;
  height: 0;
  opacity: 0;
  flex: 0;
`;

export default withTranslation()(CopyButton);
