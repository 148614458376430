import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as qs from 'query-string';

import { IGlobalStoreState } from '../../../store';
import { Utils } from '../../../services';
import { AlertType, KeyErrors } from '../../../store/enums';
import { checkoutClear, checkoutClearPayment, redoCheckout } from '../../../store/checkout/actions';
import { ICheckoutStoreState } from '../../../store/checkout/types';
import { PaymentCompleteResult } from '../../../store/payment/types';
import { setResultInfo } from '../../../store/resultInformarion/actions';
import { ResultInfoType } from '../../../store/resultInformarion/types';
import RouteService from '../../../services/routeService';

interface IBookResultErrorPageProps {
  refnumber: string;
  status: string;
  checkout: ICheckoutStoreState;

  go: (url: string) => void;
  replace: (url: string) => void;
  checkoutClearPayment: (refNumber: string) => Promise<PaymentCompleteResult>;
  checkoutClear: () => Promise<void>;
  showAlert: (config: ResultInfoType) => void;
  checkoutRedo: () => Promise<void>;
}

interface IBookResultErrorPageState {
  isHandlingResult: boolean;
}

class BookResultErrorPage extends React.PureComponent<IBookResultErrorPageProps, IBookResultErrorPageState> {
  constructor(props: IBookResultErrorPageProps) {
    super(props);

    this.state = {
      isHandlingResult: false,
    };
  }

  componentWillReceiveProps(nextProps: IBookResultErrorPageProps) {
    //ToDo: many request
    const isCheckoutChanged = !Utils.isEqual(this.props.checkout, nextProps.checkout);
    const isTypeChanged = this.props.status !== nextProps.status;
    const isRefNumberChanged = this.props.refnumber !== nextProps.refnumber;
    if (isCheckoutChanged || isTypeChanged || isRefNumberChanged) {
      this.handlePaymentResult(nextProps);
    }
  }

  componentWillMount() {
    this.handlePaymentResult(this.props);
  }

  componentDidMount() {
    this.handlePaymentResult(this.props);
  }

  render() {
    return null;
  }

  private handlePaymentResult(props: IBookResultErrorPageProps) {
    const routeSrv = new RouteService();

    if (props.refnumber) {
      this.props.checkoutClearPayment(this.props.refnumber);
      this.props.showAlert({
        type: AlertType.Warning,
        key: KeyErrors.PaymentFailed,
        title: 'Result.PaymentErrorHasOccurred',
        message: 'Result.PleaseTryAgainLater',
        buttons: [
          {
            title: 'Result.GoToMainPage',
            callback: () => this.props.go('/'),
          },
        ],
      });
      this.props.replace(routeSrv.getResultStatusRoute(AlertType.Warning, KeyErrors.PaymentFailed));
    }
  }
}

const mapStateToProps = (state: IGlobalStoreState, ownProps: any) => {
  const queryParams: any = qs.parse(ownProps.location && ownProps.location.search);
  return {
    refnumber: queryParams.refnumber,
    status: queryParams.status,
    checkout: state.checkout,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  go: ownProps.history.push,
  replace: ownProps.history.replace,
  checkoutClearPayment: (refNumber: string) => dispatch(checkoutClearPayment(refNumber, true)),
  checkoutClear: () => dispatch(checkoutClear()),
  showAlert: (config: ResultInfoType) => dispatch(setResultInfo(config)),
  checkoutRedo: () => dispatch(redoCheckout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BookResultErrorPage));
