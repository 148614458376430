import React, { FC, useEffect, useState } from 'react';
import { Grid, Link, Skeleton, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useRouteService } from '../../helpers/customHooks';
import { useRoutesHelper } from '../../helpers/routesHelper';

const useStyles = makeStyles<void, 'titleImage' | 'gradientImg'>()((theme: Theme, _params, classes) => ({
  gridTile: {
    height: '223px',
    width: 230,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      height: '265px',
      width: 330,
    },
  },
  gradientImg: {
    position: 'absolute',
    width: '100%',
    height: '42px',
    left: '0px',
    bottom: '5px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
    opacity: 0.7,
    borderRadius: '0px 0px 8px 8px',
  },
  titleImage: {
    height: '175px',
    [theme.breakpoints.down('md')]: {
      height: '212px',
    },
    width: '100%',
    objectFit: 'cover',
    borderRadius: '8px',
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.45)',
  },
  skeletonImage: {
    height: '223px',
    width: '100%',
    borderRadius: 8,
  },
  skeletonText: {
    height: 20,
    marginTop: '5px',
  },
  titleText: {
    marginTop: 12,
    fontSize: 16,
    lineHeight: '16px',
    marginBottom: 6,
  },
  subTitleText: {
    fontSize: 14,
    lineHeight: '14px',
    color: '#6B6B6B',
  },
  linkStyle: {
    '&:hover': {
      textDecoration: 'none',
      color: 'rgb(178, 178, 178)',
      filter: 'brightness(60%)',
    },
  },
  gridImage: {
    position: 'relative',
    '&:hover': {
      [`& .${classes.titleImage}, .${classes.gradientImg}`]: {
        filter: 'brightness(60%)',
      },
    },
  },
}));

export const HorizontalCarousel: FC<HorizontalCarouselType> = ({ load, reloadDependencyValue }) => {
  const { getEventRoute } = useRouteService();
  const { handleEventLink } = useRoutesHelper();

  const [items, setItems] = useState<ItemHorizontalCarouselType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { classes } = useStyles();

  useEffect(() => {
    setIsLoading(true);

    const loadEvents = async () => {
      const result = await load();
      setItems(result);
      setIsLoading(false);
    };
    loadEvents();
  }, [reloadDependencyValue]);

  return (
    <Grid container justifyContent={'space-between'} spacing={2}>
      {items.length > 0 &&
        !isLoading &&
        items.map((item) => (
          <Grid item key={item.key} className={classes.gridTile} xs={12} sm={6} md={4} lg={4} xl={4}>
            <Link
              onClick={() => handleEventLink(item.domainTypes, getEventRoute(item.key))}
              className={classes.linkStyle}
            >
              <Grid container spacing={0} direction="row">
                <Grid item lg={12} xs={12} sm={12} md={12} xl={12} className={classes.gridImage}>
                  <img src={item.imageUrl} alt={item.title} className={classes.titleImage} />
                  <div className={classes.gradientImg} />
                </Grid>
                <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                  <Typography className={classes.titleText}>{item.title}</Typography>
                </Grid>
                <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                  <Typography variant="caption" className={classes.subTitleText}>
                    {item.subTitle}
                  </Typography>
                </Grid>
              </Grid>
            </Link>
          </Grid>
        ))}

      {isLoading &&
        Array.from(new Array(6)).map((item, idx) => (
          <Grid item key={idx} className={classes.gridTile} xs={12} sm={6} md={4} lg={4} xl={4}>
            <Grid container spacing={0} direction="row">
              <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                <Skeleton animation="wave" variant="rectangular" className={classes.skeletonImage} />
              </Grid>
              <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                <Skeleton animation="wave" variant="rectangular" className={classes.skeletonText} />
              </Grid>
              <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                <Skeleton animation="wave" variant="rectangular" width="50%" className={classes.skeletonText} />
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

type HorizontalCarouselType = {
  load: () => Promise<ItemHorizontalCarouselType[]>;
  reloadDependencyValue?: any;
};

type ItemHorizontalCarouselType = {
  key: string;
  imageUrl: string;
  title: string;
  subTitle: string;
  domainTypes: number[];
};
