import { Dispatch } from 'redux';
import { action } from 'typesafe-actions';

import { BookActionTypes, IBookStoreState, BookStoreState } from './types';
import { IEventStoreState, ISeatStoreState } from '../event/types';

const init = (payload: IBookStoreState) => action(BookActionTypes.BOOK_INIT, payload);
export const setQuota = (payload: number) => action(BookActionTypes.BOOK_SET_QUOTA, payload);
export const setSector = (payload: string) => action(BookActionTypes.BOOK_SET_SECTOR, payload);
export const setSectorRow = (payload: string) => action(BookActionTypes.BOOK_SET_SECTOR_ROW, payload);
export const setSectorRowSeat = (payload: string) => action(BookActionTypes.BOOK_SET_SECTOR_ROW_SEAT, payload);
export const setTicketsCount = (payload: number) => action(BookActionTypes.BOOK_SET_TICKETS_COUNT, payload);
const clear = () => action(BookActionTypes.BOOK_CLEAR);

export const initBook =
  (event: IEventStoreState, sectorSlug?: string, seats?: ISeatStoreState[], token?: string) =>
  (dispatch: Dispatch<any>): Promise<IBookStoreState> => {
    const book = new BookStoreState();
    book.event = event;
    book.sectorSlug = sectorSlug;
    book.seats = seats;
    book.token = token;
    dispatch(clear());
    dispatch(init(book));
    return Promise.resolve(book);
  };

export const clearBook =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(clear());
  };
