import { Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import { IGlobalStoreState } from '..';
import { ThunkService } from '../../services';

import { AppActionTypes } from '../app/types';
import { AppSettingsActionTypes, IAppSettingsState } from './types';

export const init = () => action(AppActionTypes.APP_INIT);
export const finalize = () => action(AppActionTypes.APP_FINALIZE);
export const showSpinner = () => action(AppActionTypes.SPINNER_SHOW);
export const hideSpinner = () => action(AppActionTypes.SPINNER_HIDE);

const appSettingInit = (settings: IAppSettingsState) => action(AppSettingsActionTypes.APP_SETTINGS_INIT, settings);

export const initWidgetApp =
  (token: string) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    dispatch(init());
    try {
      dispatch(getSettings(token));
      dispatch(hideSplash());
    } catch (e) {
    } finally {
      await dispatch(finalize());
    }
  };

export const getSettings =
  (token: string) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IGlobalStoreState,
    thunkService: ThunkService
  ): Promise<IAppSettingsState> => {
    const appSettings = await thunkService.api.widget.getAppSettings(token);
    dispatch(appSettingInit(appSettings));
    return Promise.resolve(appSettings);
  };

export const hideSplash = () => (): void => {
  document.body.classList.remove('splash-show');
};
