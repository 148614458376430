import { IEventStoreState } from '../event/types';

export interface IShareStoreState {
  event: IEventStoreState;
}

export class ShareStoreState implements IShareStoreState {
  public event: IEventStoreState;

  constructor() {
    this.event = null;
  }
}

export enum ShareActionTypes {
  SHARE_INIT = '@@share/INIT',
  CLEAR_INIT = '@@share/CLEAR_INIT',
}
