import { AlertType, KeyErrors, KeySuccess } from '../enums';

export type ResultInfoButtonConfigType = {
  title: string;
  callback: () => void;
};

export type ResultInfoType = {
  type: AlertType;
  key: KeySuccess | KeyErrors;
  title?: string;
  message?: string;
  buttons?: Array<ResultInfoButtonConfigType>;
};

export enum ResultInfoActionTypes {
  RESULT_INFO_SHOW = '@@resultInfo/SHOW',
  RESULT_INFO_CLEAR = '@@resultInfo/CLEAR',
}
