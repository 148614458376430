import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { Page, PageContent } from '../../../components/UI';
import { EventCheckoutHeader } from '../../../components/event';
import { EmailForm, IEmailForm } from '../../../components/forms';
import { checkCurrentCheckout, checkoutSetEmail, widgetCheckoutProcess } from '../../../store/checkout/actions';
import { CheckoutHelper, EventHelper } from '../../../helpers';
import { ICheckoutStoreState } from '../../../store/checkout/types';
import { IErrorStoreState } from '../../../store/app/types';
import { IGlobalStoreState } from '../../../store';
import { StripeCheckoutForm } from '../../../components/forms/stripeCheckoutForm';

const WidgetCheckoutPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const checkoutSteps = 2;

  const [errors, setErrors] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const { token } = useParams<{ token: string }>();

  const checkout = useSelector<IGlobalStoreState, ICheckoutStoreState>((state) => state.checkout);
  const isInitializing = useSelector<IGlobalStoreState, boolean>((state) => state.app.isInitializing);
  const error = useSelector<IGlobalStoreState, IErrorStoreState>((state) => state.app.error);
  const isAuthorized = useSelector<IGlobalStoreState, boolean>((state) => state.app.isAuthorized);

  useEffect(() => {
    init(checkout);
  }, []);

  useEffect(() => {
    setErrors({ form: error });
  }, [error?.details, error?.message]);

  useEffect(() => {
    const isAvailableForProcessing = CheckoutHelper.getIsAvailableForProcessing(checkout) && !isAuthorized;
    if (isAvailableForProcessing) {
      handlePaymentFormSubmit(token);
    }
  }, [checkout?.email, isAuthorized, token]);

  useEffect(() => {
    const IsInProcess = CheckoutHelper.getIsProcessed(checkout) && CheckoutHelper.getIsInProcess(checkout);
    if (IsInProcess) {
      init(checkout);
    }
  }, [checkout?.process, checkout?.complete?.result?.isProcessing]);

  useEffect(() => {
    if (CheckoutHelper.isRedirectingMethod(checkout)) {
      setRedirectUrl(checkout.process.paymentRedirectUrl);
    }
  }, [checkout?.process, checkout?.process?.paymentRedirectUrl, checkout?.process?.paymentProvider]);

  const renderEmailForm = (checkoutModel: ICheckoutStoreState) => {
    const { email } = checkoutModel;
    const title = isAuthorized ? t('CheckoutWidget.BookTicketsNoteAuthorized') : t('CheckoutWidget.BookTicketsNote');
    return (
      <PageContent roundCorners>
        <Header>
          {t('CheckoutWidget.Step')} 1 {t('CheckoutWidget.Of')} {checkoutSteps}
        </Header>
        <Title>{title}</Title>
        <EmailForm
          errors={errors}
          email={email}
          emailPlaceholder={t('CheckoutWidget.EnterEmail')}
          emailConfirmPlaceholder={t('CheckoutWidget.RepeatEmail')}
          submitTitle={t('CheckoutWidget.Next')}
          onSubmit={(formModel: IEmailForm) => handleEmailFormSubmit(formModel)}
        />
      </PageContent>
    );
  };

  const renderPaymentForm = (checkoutModel: ICheckoutStoreState) => {
    const step = 2;
    return (
      <PageContent roundCorners>
        <Header>
          {t('CheckoutWidget.Step')} {step} {t('CheckoutWidget.Of')} {checkoutSteps}
        </Header>
        <Title>{t('CheckoutWidget.PayByCard')}</Title>
        {renderCheckoutContent(checkoutModel)}
      </PageContent>
    );
  };

  const renderCheckoutContent = (checkoutModel: ICheckoutStoreState) => {
    const isRedirecting = !!redirectUrl;
    const needForm = CheckoutHelper.getIsNeedPaymentForm(checkoutModel);
    if (isRedirecting) {
      window.location.href = redirectUrl;
    }
    return (
      <>
        {!isRedirecting && needForm && <StripeCheckoutForm paymentToken={checkoutModel.process.paymentToken} />}
        {isRedirecting && (
          <RedirectWrapper>
            <RedirectTitle>{t('CheckoutWidget.PaymentRedirectionMessage')}</RedirectTitle>
          </RedirectWrapper>
        )}
      </>
    );
  };

  const handleEmailFormSubmit = (formModel: IEmailForm) => {
    if (formModel) {
      dispatch(checkoutSetEmail(formModel.email));
    }
  };

  const handlePaymentFormSubmit = (token: string) => {
    dispatch(widgetCheckoutProcess(token));
  };

  const init = (checkout: ICheckoutStoreState) => {
    if (CheckoutHelper.getIsProcessed(checkout) && !CheckoutHelper.getIsCompleted(checkout)) {
      dispatch(checkCurrentCheckout());
      return;
    }
  };

  const checkoutModel = checkout;
  const isInitiated = CheckoutHelper.getIsInitiated(checkoutModel);
  if (!isInitiated || isInitializing || !checkoutModel) {
    return null;
  }

  const isEmailSet = CheckoutHelper.getIsEmailSet(checkoutModel);
  const isProcessed = CheckoutHelper.getIsProcessed(checkoutModel);
  const { event } = checkoutModel.init;

  let posterUrl = event.optionPosterUrl;
  if (posterUrl === '') {
    posterUrl = event.posterUrl;
  }

  return (
    <Page
      title={`${t('CheckoutWidget.TicketPurchase')} | ${event.title} - ${event.subtitle}`}
      description={EventHelper.getMetaDescription(event)}
      keywords={[event.title, event.venue.title, t('CheckoutWidget.BuyTicket'), t('CheckoutWidget.Concert')]}
      imageUrl={posterUrl}
    >
      <EventCheckoutHeader checkoutModel={checkoutModel} isAuthorized={isAuthorized} />
      {isInitiated && !isEmailSet && renderEmailForm(checkoutModel)}
      {isProcessed && renderPaymentForm(checkoutModel)}
    </Page>
  );
};

const Header: any = styled.h2`
  font-size: 14px;
  margin: 0 0 10px 0;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.boldFont};
`;

const RedirectWrapper = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const RedirectTitle = styled(Header as any)``;

const Title = styled.h1`
  font-size: 14px;
  text-align: center;
`;

export default WidgetCheckoutPage;
