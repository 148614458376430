import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Page } from '../../../components/UI';
import { GlobalStoreState, IGlobalStoreState } from '../../../store';
import { IEventStoreState } from '../../../store/event/types';
import { hideAdButton, hideCitySelector, showAdButton, showCitySelector } from '../../../store/header/actions';
import EventHeadline from '../../../components/event/eventHeadline';
import { EventHelper } from '../../../helpers';
import { AnalyticService } from '../../../services';

export const EventsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const events = useSelector<GlobalStoreState, IEventStoreState[]>((state) => state.events);
  const isInitializing = useSelector<IGlobalStoreState, boolean>((state) => state.app.isInitializing);
  const userEvents = useSelector<IGlobalStoreState, IEventStoreState[]>((state) => state.userEvents);

  const analyticSrv = new AnalyticService();

  useEffect(() => {
    analyticSrv.trackEvent('EventsList');
    dispatch(showAdButton());
    dispatch(showCitySelector());

    return () => {
      dispatch(hideAdButton());
      dispatch(hideCitySelector());
    };
  }, []);

  if (isInitializing || !events.length) {
    return null;
  }

  return (
    <Page
      title={t('EventPage.Events')}
      description={t('EventPage.UpcomingEvents')}
      keywords={events.map((e) => e.title)}
    >
      <EventHeadline event={events[0]} isMain isDistributed={EventHelper.isEventDistributed(events[0], userEvents)} />
      {events.slice(1).map((event, index) => (
        <EventHeadline key={index} event={event} isDistributed={EventHelper.isEventDistributed(event, userEvents)} />
      ))}
    </Page>
  );
};
