import { AppTheme } from '../../settings/appTheme';
import RouteService from '../../services/routeService';

const routeSrv = new RouteService();
const baseUrl = routeSrv.getBaseUrl().replace('.by', '.live');

export default `
@font-face {
    font-family: Intro;
    src: url(${baseUrl}/content/fonts/Intro-Regular.otf);
}
@font-face {
    font-family: Intro-Book;
    src: url(${baseUrl}/content/fonts/Intro-Book.otf);
    font-weight: normal;
}
@font-face {
    font-family: Intro-Bold;
    src: url(${baseUrl}/content/fonts/Intro-Bold.otf);
    font-weight: normal;
}
@font-face {
    font-family: Intro-Black;
    src: url(${baseUrl}/content/fonts/Intro-Black.otf);
    font-weight: normal;
}
@font-face {
    font-family: Intro-Light;
    src: url(${baseUrl}/content/fonts/Intro-Light.otf);
    font-weight: normal;
}
body#iframe .payment-page,
body#iframe .payment-methods {
    background: ${AppTheme.colors.bg};
    font-family: ${AppTheme.fonts.regularFont};
}
body#iframe .content,
body#iframe .payment-page .payment-method {
    margin: 0;
}
body#iframe .payment-methods {
    border: none;
}
body#iframe .content #request .submit-button,
body#iframe .content .credit-card {
    padding: 0;
}
body#iframe .content .credit-card {
    margin-bottom: 85px;
}
body#iframe .content .credit-card .credit-card-icon,
body#iframe .content .credit-card .card-holder-icon,
body#iframe .content .credit-card .exp-date label,
body#iframe .content .credit-card .verification-value label,
body#iframe .test-payment,
body#iframe #logos-container #logos * {
    display: none;
}
body#iframe .content .credit-card .input input {
    font-family: ${AppTheme.fonts.regularFont};
    color: ${AppTheme.colors.text};
    font-size: 12px;
}
body#iframe .content .credit-card .input input::placeholder {
    opacity: 0.5;
    font-size: 12px;
}
body#iframe .content .credit-card .input input.error {
    color: ${AppTheme.colors.error};
    border-color: ${AppTheme.colors.error};
    font-size: 12px;
}
body#iframe .content .credit-card .input input {
    padding: 15px 20px;
    margin-left: 0px;
    border: 1px solid ${AppTheme.colors.text};
    border-radius: 24px;
    margin-bottom: 15px;
    background: transparent;
}
body#iframe .content .credit-card .input input:focus {
    border-color: ${AppTheme.colors.accent};
    color: ${AppTheme.colors.text};
}
body#iframe .content .credit-card .exp-date,
body#iframe .content .credit-card .verification-value {
    width: 50%;
}
body#iframe .content .credit-card .exp-date input,
body#iframe .content .credit-card .verification-value input {
    width: calc(100% - 5px);
}
body#iframe .content #request .submit-button .pure-button {
    background: none;
}
body#iframe .content #request .submit-button input[type=submit] {
    padding: 15px;
    text-transform: uppercase;
    font-size: 14px;
    background: ${AppTheme.colors.accent};
    color: ${AppTheme.colors.text};
    border-radius: 24px;
    font-family: ${AppTheme.fonts.boldFont};
    cursor: pointer;
}
body#iframe .content #request .submit-button input[type=submit]:disabled {
    background: ${AppTheme.colors.accentDisabled};
    color: ${AppTheme.colors.textSecond};
    cursor: not-allowed;
}
body#iframe .content #request .submit-button input[type=submit]:enabled:hover {
    background: #D4A108;
}
body#iframe .content #request .submit-button input[type=submit]:enabled:focus {
    opacity: 1;
}
body#iframe .content #request .submit-button input[type=submit]:enabled:active {
    background-color: ${AppTheme.colors.accentDimmed};
    opacity: 1;
}
body#iframe .credentials {
    display: none;
}
body#iframe .credentials img {
    filter: invert(1);
}
body#iframe #logos-container {
    display: none;
}
body#iframe #logos-container #logos {
    display: block;
    margin: 0 auto;
    background: url(${routeSrv.getBaseUrl()}${AppTheme.img.payment.formLogos}) center no-repeat;
    background-size: contain;
    height: 40px;
    margin-top: 15px;
}
`;
