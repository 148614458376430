import { Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import { ThunkService } from '../../services';

import { ContractInfo, IMenuStoreState, MenuActionTypes } from './types';
import { IGlobalStoreState } from '../index';

export const show = () => action(MenuActionTypes.MENU_SHOW);
export const hide = () => action(MenuActionTypes.MENU_HIDE);
export const initContract = (contractInfo: ContractInfo) => action(MenuActionTypes.CONTRACTS_INIT, contractInfo);

export const showMenu = () => async (dispatch: Dispatch<any>) => {
  dispatch(show());
};

export const hideMenu = () => (dispatch: Dispatch<any>) => {
  dispatch(hide());
};

export const getContracts =
  () =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IGlobalStoreState,
    thunkService: ThunkService,
  ): Promise<IMenuStoreState> => {
    const menuStore = await thunkService.api.publicContract.getUserAgreements();
    dispatch(initContract(menuStore.contracts));
    return Promise.resolve(menuStore);
  };
