import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { InformationSuccess } from './informationSuccess';
import { Routes } from '../../../store/routes';
import { InformationFailed } from './informationFailed';

export const WidgetInformationRoutes = () => (
  <Route
    render={({ location }) => (
      <Switch location={location}>
        <Route exact path={Routes.WidgetInformationSuccess} component={InformationSuccess} />
        <Route exact path={Routes.WidgetInformationFailed} component={InformationFailed} />
      </Switch>
    )}
  />
);
