import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Image, PageHeader } from '../../UI';
import { ICheckoutStoreState } from '../../../store/checkout/types';
import { RenderPriceInfo } from './renderPriceInfo';
import { RenderTicketsInfo } from './renderTicketsInfo';
import EventInfoItem from '../eventInfoItem';

type EventCheckoutHeaderProps = {
  checkoutModel: ICheckoutStoreState;
  isAuthorized: boolean;
};

export const EventCheckoutHeader: FC<EventCheckoutHeaderProps> = ({ checkoutModel, isAuthorized }) => {
  const { event } = checkoutModel.init;
  let posterUrl = event.optionPosterUrl;

  if (posterUrl === '') {
    posterUrl = event.posterUrl;
  }

  return (
    <Header>
      <Poster src={posterUrl} />
      <InfoWrapper>
        <Title>{event.title}</Title>
        <List>
          <ListItem>
            <EventInfoItem
              icon="calendar"
              themed
              title={dayjs(event.date).format('DD MMMM YYYY')}
              subtitle={dayjs(event.date).format('LT')}
            />
          </ListItem>
          {event.venue && (
            <ListItem>
              <EventInfoItem
                icon="location"
                themed
                title={event.venue.title}
                subtitle={`${event.venue.address}, ${event.venue.city}`}
              />
            </ListItem>
          )}
          {<RenderTicketsInfo tickets={checkoutModel.init.tickets} />}
          {<RenderPriceInfo checkoutModel={checkoutModel} isAuthorized={isAuthorized} />}
        </List>
      </InfoWrapper>
    </Header>
  );
};

const InfoWrapper = styled.div`
  z-index: 1;
  width: 100%;
  margin-bottom: 4vh;
`;

const Title = styled.h1`
  font-size: 36px;
  margin: 0 0 20px 0;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.bolderFont};
`;

const Poster = styled(Image as any)`
  position: absolute;
  object-position: 50% 20%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 50px;
`;

export const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const Header = styled(PageHeader as any)`
  max-height: 60vh;
`;
