import { Reducer } from 'redux';

import { IBookStoreState, BookStoreState, BookActionTypes } from './types';

const bookReducer: Reducer<IBookStoreState> = (state: IBookStoreState = new BookStoreState(), action) => {
  switch (action.type) {
    case BookActionTypes.BOOK_INIT:
      return { ...action.payload } as IBookStoreState;
    case BookActionTypes.BOOK_SET_SECTOR:
      return { ...state, sectorSlug: action.payload, rowSlug: null, seatSlugs: [] } as IBookStoreState;
    case BookActionTypes.BOOK_SET_SECTOR_ROW:
      return { ...state, rowSlug: action.payload, seatSlugs: [] } as IBookStoreState;
    case BookActionTypes.BOOK_SET_SECTOR_ROW_SEAT: {
      let seatSlugs = state.seats || [];
      if (seatSlugs.some((s) => s === action.payload)) {
        seatSlugs = seatSlugs.filter((s) => s !== action.payload);
      } else {
        seatSlugs.push(action.payload);
      }
      return { ...state, seatSlugs } as IBookStoreState;
    }
    case BookActionTypes.BOOK_SET_TICKETS_COUNT:
      return { ...state, tickets: action.payload, seatSlugs: [] } as IBookStoreState;
    case BookActionTypes.BOOK_CLEAR:
      return new BookStoreState();
    case BookActionTypes.BOOK_SET_QUOTA:
      return { ...state, quotaId: action.payload } as IBookStoreState;
    default:
      return state;
  }
};

export default bookReducer;
