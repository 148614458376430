import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';

import { IGlobalStoreState } from '../../../store';
import { IEventStoreState } from '../../../store/event/types';
import { getSellEventDetails } from '../../../store/event/actions';
import { Button, Page, PageContent, PageFooter, TextInput } from '../../../components/UI';
import { SchemaSectorSelector, TicketCountSelector } from '../../../components/book';
import { AlertType, KeyErrors, Pages } from '../../../store/enums';
import { clear, getTicketShareToken, initShare } from '../../../store/share/actions';
import { IUserStoreState } from '../../../store/user/types';
import { IErrorStoreState } from '../../../store/app/types';
import { FormError } from '../../../components/forms';
import CopyButton from '../../../components/UI/copyButton';
import { useTranslation } from 'react-i18next';
import { IBookTicketQuotaStoreState } from '../../../store/ticket/types';
import { BookTotalPrices } from '..';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { PopUp } from '../../../store/animations';
import { AnimatePresence } from 'framer-motion';
import { setResultInfo } from '../../../store/resultInformarion/actions';

const SharePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { getProfileRoute, getBookRoute, getEventRoute, generateReferralUrl, getResultStatusRoute } = useRoutesHelper();

  const event = useSelector<IGlobalStoreState, IEventStoreState>((state) => state.share.event);
  const isLoading = useSelector<IGlobalStoreState, boolean>((state) => state.app.isLoading);
  const user = useSelector<IGlobalStoreState, IUserStoreState>((state) => state.user);
  const error = useSelector<IGlobalStoreState, IErrorStoreState>((state) => state.app.error);
  const isRedirection = useSelector<IGlobalStoreState, boolean>((state) => state.app.isRedirection);
  const isFinalized = useSelector<IGlobalStoreState, boolean>((state) => state.app.isFinalized);

  const [errors, setErrors] = useState(null);
  const { eventSlug, sectorSlug, rowSlug, tickets } = useParams<{
    eventSlug: string;
    sectorSlug: string;
    rowSlug: string;
    tickets: string;
  }>();

  let ticketsOrRow = +tickets || +rowSlug;
  if (isNaN(ticketsOrRow)) {
    ticketsOrRow = 0;
  }

  const initEvent = (eventSlug, sectorSlug) => {
    if (isRedirection || !isFinalized) {
      return;
    }
    if (!eventSlug) {
      history.push('/');
      return;
    }

    // @ts-ignore
    dispatch(getSellEventDetails(eventSlug)).then((event) => {
      if (!event || !event.slug) {
        dispatch(
          setResultInfo({
            type: AlertType.Error,
            key: KeyErrors.EventNotFound,
            title: 'Alert.SomethingWentWrong',
            message: 'Alert.CheckLinkNoEventFound',
          })
        );
        history.replace(getResultStatusRoute(AlertType.Error, KeyErrors.EventNotFound));
        return;
      } else if (!event.isDistributionAvailable) {
        history.push(getEventRoute(event.slug));
        return;
      }
      if (!sectorSlug) {
        dispatch(initShare(event));
        return;
      }
      dispatch(initShare(event));
    });
  };

  useEffect(() => {
    initEvent(eventSlug, sectorSlug);
  }, []);

  useEffect(() => {
    if (errors) {
      setErrors({ form: error });
    }
    if (!user) {
      history.push(getProfileRoute());
      return;
    }
  }, [error?.details, error?.message, user]);

  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, []);

  if (isRedirection || isLoading || !event) {
    return null;
  }

  const renderFooter = () => {
    const sector = event.sectors.find((x) => x.slug === sectorSlug);
    if (!event || !sector) return null;

    let isPrivateQuota = sectorSlug && event ? sector.ticketQuotas.find((q) => q.isPrivate) : null;
    return isPrivateQuota ? renderPrivateSellControls() : renderSellControls();
  };

  const renderSellControls = () => {
    const { domainTypes } = event;
    const referralLink = generateReferralUrl(user, domainTypes, eventSlug, sectorSlug, rowSlug, ticketsOrRow);

    return (
      <>
        <ReferralLinkInput type="text" readOnly tabIndex={-1} value={referralLink} />
        <CopyButton value={referralLink} title={t('Share.CopyLink')} text={t('Share.CopyLink')} />
      </>
    );
  };

  const renderTicketsCountSelector = (event: IEventStoreState) => {
    const sector = event.sectors.find((x) => x.slug == sectorSlug);
    if (!sector) {
      return null;
    }
    const quota = sector.ticketQuotas.find((x) => x.isPrivate);

    if (!quota) {
      return null;
    }

    return (
      <TicketsPriceWrapper>
        <Title>{t<string>('Share.TicketCount')}</Title>
        <TicketSelectorsWrapper>
          <TicketCountSelector
            max={quota.left}
            sector={sector}
            tickets={ticketsOrRow}
            handleTicketsCount={(count) => handleTicketsCount(count)}
          />
        </TicketSelectorsWrapper>
        {renderPrices(event, quota)}
      </TicketsPriceWrapper>
    );
  };

  const renderPrices = (event: IEventStoreState, quota: IBookTicketQuotaStoreState) => {
    const defaultPaymentMethod =
      event.paymentMethods && event.paymentMethods.length > 0 ? event.paymentMethods[0] : null;
    const ticketPrice = quota.price;
    const ticketsCount = ticketsOrRow;
    let total = ticketPrice * ticketsCount;
    let currency = quota.currency;
    if (!!defaultPaymentMethod.useConvertion) {
      total = Math.round(total * defaultPaymentMethod.convertionRate * 100) / 100;
      currency = defaultPaymentMethod.convertionCurrency;
    }
    return <BookTotalPrices border ticketPrice={ticketPrice} totalPrice={total} currency={currency} />;
  };

  const handleTicketsCount = (count: number) => {
    history.push(getBookRoute(eventSlug, null, sectorSlug, null, count, true));
  };

  const renderPrivateSellControls = () => {
    return (
      <Footer>
        <AnimatePresence>{renderError()}</AnimatePresence>
        <Button disabled={!ticketsOrRow} onClick={(e) => handleGetLinkClick(e)}>
          {t<string>('Share.GetTheLink')}
        </Button>
      </Footer>
    );
  };
  const renderError = () => {
    let error = null;

    if (errors && errors.form) {
      error = errors.form.details || errors.form.message;
    }
    return (
      !!error && (
        <ErrorWrapper key="form-error" variants={PopUp} initial={'enter'} animate={'exit'}>
          {error}
        </ErrorWrapper>
      )
    );
  };

  const handleSchemaSectorSelect = (sectorSlug: string) => {
    history.push(getBookRoute(eventSlug, null, sectorSlug, null, null, true));
  };

  const handleGetLinkClick = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    const token = dispatch(getTicketShareToken(eventSlug, sectorSlug, ticketsOrRow));
    try {
      history.push(`/${Pages.Share}/${eventSlug}/${token}`);
    } catch (e) {
      dispatch(
        setResultInfo({
          type: AlertType.Error,
          key: KeyErrors.QuotaNotFound,
          title: 'Alert.SomethingWentWrong',
          message: 'Alert.CheckLinkNoLocationFound',
        })
      );
      history.replace(getResultStatusRoute(AlertType.Error, KeyErrors.QuotaNotFound));
    }
  };

  return (
    <Page isForm title={`${t('Share.TicketSell')} | ${event.title} - ${event.subtitle}`}>
      <PageContent>
        <SchemaSectorSelector
          venue={event.venue}
          sectors={event.sectors}
          selectedSector={sectorSlug}
          onChange={(sectorSlug: string) => handleSchemaSectorSelect(sectorSlug)}
        />
        {renderTicketsCountSelector(event)}
      </PageContent>
      {renderFooter()}
    </Page>
  );
};

const TicketsPriceWrapper = styled.div`
  padding-top: 30px;
`;

const TicketSelectorsWrapper = styled.section`
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: 14px;
  margin: 0 0 15px 0;
`;

const ReferralLinkInput = styled(TextInput as any)`
  color: ${(props) => props.theme.colors.accent};
  padding: 15px 20px;

  &::placeholder {
    color: ${(props) => props.theme.colors.accent};
    opacity: 1;
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.text};
  }
`;

const Footer = styled(PageFooter as any)`
  padding: 0 25px 20px 25px;

  > button,
  > input {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;

const ErrorWrapper = styled(FormError as any)`
  width: 100%;
`;

export default SharePage;
