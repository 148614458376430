import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { AnimatePresence } from 'framer-motion';
import { IGlobalStoreState } from '../../../store';
import { PageContent } from '../../../components/UI';
import { RegistrationForm, RegistrationFormType } from '../../../components/forms';
import { registerUser } from '../../../store/user/actions';
import { IErrorStoreState } from '../../../store/app/types';
import { Utils } from '../../../services';
import { PopUp } from '../../../store/animations';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { AlertType, KeySuccess } from '../../../store/enums';
import { ModalWindow } from './socialNetworkLogin/modalWindow';
import { Divider, isMobile } from './loginPage';
import { IDistributionStoreState } from '../../../store/distribution/types';

const RegistrationPage = () => {
  const isInitializing = useSelector<IGlobalStoreState, boolean>((state) => state.app.isInitializing);
  const error = useSelector<IGlobalStoreState, IErrorStoreState>((state) => state.app.error);
  const isAuthorized = useSelector<IGlobalStoreState, boolean>((state) => state.app.isAuthorized);
  const distributor = useSelector<IGlobalStoreState, IDistributionStoreState>((state) => state.distribution);

  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const { getResultStatusRoute } = useRoutesHelper();

  useEffect(() => {
    if (isAuthorized) {
      history.replace('/');
    }
  }, []);

  useEffect(() => {
    setErrors({ form: error });
  }, [error?.message, error?.details]);

  if (isInitializing) {
    return null;
  }

  const handleFormSubmit = (formModel: RegistrationFormType) => {
    if (formModel) {
      if (distributor?.distributorSlug) {
        formModel.distributorSlug = distributor.distributorSlug;
        formModel.isDistributor = true;
      }

      (async () => dispatch(registerUser(formModel.email, formModel.password)))().then(() => {
        history.replace(getResultStatusRoute(AlertType.Info, KeySuccess.EmailSent));
      });
    }
  };

  return (
    <AnimatePresence>
      <PageContent center variants={PopUp} initial={'enter'} animate={'exit'}>
        <Helmet>
          <title>{Utils.toPageTitle(t('Registration.Registration'))}</title>
        </Helmet>
        <Header>{t('Registration.Registration')}</Header>
        <RegistrationForm
          submitTitle={t('Login.SignUp')}
          onSubmit={(formModel: RegistrationFormType) => handleFormSubmit(formModel)}
          errors={errors}
        />
        {/*<Divider isMobile={isMobile}>{t('Login.Or')}</Divider>*/}
        {/*<ModalWindow />*/}
      </PageContent>
    </AnimatePresence>
  );
};
const Header = styled.h1`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
`;

export default RegistrationPage;
