import * as React from 'react';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ICheckoutStoreState } from '../../../store/checkout/types';
import { CheckoutHelper } from '../../../helpers';
import { Utils } from '../../../services';
import Constants from '../../../store/constants';
import { ListItem } from './eventCheckoutHeader';
import EventInfoItem from '../eventInfoItem';

type RenderPriceInfoPropsType = {
  checkoutModel: ICheckoutStoreState;
  isAuthorized: boolean;
};

export const RenderPriceInfo: FC<RenderPriceInfoPropsType> = memo(({ checkoutModel, isAuthorized }) => {
  const { t } = useTranslation();

  if (!CheckoutHelper.getIsInitiated(checkoutModel)) {
    return null;
  }

  const paymentInfo = checkoutModel.init;
  const paymentMethod = paymentInfo.event.paymentMethods.find((m) => m.method === checkoutModel.paymentMethod);
  if (!paymentMethod) {
    return null;
  }

  const commission = paymentInfo.priceComission;
  const { currency } = paymentInfo;

  const pricePayByPointsTitle = `${paymentInfo.pricePayByPoints} ${t('Checkout.PointsCurrency')} 
    ${Utils.getEmojiFlagByCurrency(currency)}`;
  let title = `${paymentInfo.priceTotal} ${currency}`;

  if (isAuthorized) {
    title += ` ${Constants.BulletDelimiter} ${pricePayByPointsTitle}`;
  }

  let subtitle =
    commission > 0
      ? `${commission} ${currency} (${t('Checkout.BankCommission')} ${(paymentMethod.comission * 100).toFixed(2)}%)`
      : t('Checkout.NoCommission');

  if (checkoutModel.usePoints) {
    title = `${paymentInfo.priceTotal} ${currency} = ${paymentInfo.pricePayByCard} ${currency} + ${pricePayByPointsTitle}`;
    subtitle =
      commission > 0
        ? `${commission} ${currency} ${t('Checkout.CommissionIncluded')}`
        : t('Checkout.CommissionIncluded');
  }
  return (
    <ListItem>
      <EventInfoItem icon="purse" themed title={title} subtitle={subtitle} />
    </ListItem>
  );
});
