import { Grid, Skeleton, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SellEventButton } from '../../../components/event/sellEventButton';
import useApi from '../../../services/apiService';
import Constants from '../../../store/constants';
import { EventType } from '../../../store/event/types';
import { SearchInput } from '../../../components/event/searchInput';
import { DatePicker } from '../../../components/event/datePicker/datePicker';
import { HorizontalCarousel } from '../../../components/event/horizontalCarousel';
import { Events } from '../../../components/event/events';

const useStyles = makeStyles()((theme: Theme) => ({
  imageCardContent: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.3) 51.04%, #000000 100%)',
    borderRadius: '0px',
    transition: theme.transitions.create('opacity'),
    objectPosition: '50% 20%',
    width: '100%',
    height: '465px',
    objectFit: 'cover',
  },
  skeletonCategoryImage: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    height: '465px',
  },
  skeletonTicketsFor: {
    height: '134px',
  },
  headingText: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: '14px',
    cursor: 'default',
  },
  gridSearchInput: {
    paddingBottom: '150px',
    [theme.breakpoints.down('xs')]: {
      margin: '0px 16px',
    },
  },
}));

export const EventCategoryPage = () => {
  const { categorySlug } = useParams<EventCategoryUrlParams>();
  const [category, setCategory] = useState<CategoryType>();
  const [selectedLocation, setSelectedLocation] = useState<LocationType>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const eventsCount = 10;

  const { eventsApi } = useApi();
  const { t } = useTranslation();

  const { classes } = useStyles();

  useEffect(() => {
    const loadCategory = async () => {
      const result = await eventsApi.getCategory({ categorySlug });
      setCategory(result);
      setIsLoading(false);
    };

    loadCategory();
  }, []);

  const loadEvents = async (
    eventType: EventType,
    search?: string | null,
    date?: Date | null,
    count?: number | null
  ) => {
    const result = await eventsApi.getEventsByType({
      eventType,
      search,
      date,
      categoryId: category.id,
      count,
    });
    return result.map((event) => ({
      key: event.slug,
      title: event.title,
      imageUrl: event.posterUrl,
      subTitle: `${dayjs(event.date).format(Constants.DateFormat)}, ${event.city}`,
      date: dayjs(event.date).format(Constants.DateFormat),
      city: event.city,
      domainTypes: event.domainTypes,
    }));
  };

  return (
    <>
      {isLoading && (
        <>
          <span style={{ zIndex: 0 }}>
            <Skeleton animation="wave" variant="rectangular" width="100%" className={classes.skeletonCategoryImage} />
          </span>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
            style={{ marginTop: '180px', zIndex: 1 }}
          >
            <Grid item style={{ marginTop: '135px' }}>
              <SellEventButton />
            </Grid>
          </Grid>
        </>
      )}

      {!isLoading && (
        <>
          <span style={{ zIndex: 0 }}>
            <img src={category.imageUrl} className={classes.imageCardContent} alt={category.slug} />
          </span>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
            style={{ marginTop: '60px', zIndex: 1 }}
          >
            <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
              <TextContainer>
                <p>{t<string>('Categories.TicketsTo')}</p>
                <p>{t<string>(`Dashboard.Categories.${category.slug}`)}</p>
              </TextContainer>
            </Grid>

            <Grid item lg={12} xs={12} sm={12} md={12} xl={12} className={classes.gridSearchInput}>
              <div style={{ marginTop: '10px' }}>
                <SearchInput />
              </div>
            </Grid>

            {false && (
              <Grid item>
                <SellEventButton />
              </Grid>
            )}

            {selectedLocation?.value && (
              <Events
                title={`события рядом с ${selectedLocation.value}`}
                reloadDependencyValue={selectedLocation.value}
                load={() => loadEvents(EventType.Near, selectedLocation.id)}
              />
            )}
            <Grid container style={{ padding: '12px 16px' }}>
              <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                <Events load={() => loadEvents(EventType.Popular)} title={t('Dashboard.PopularEvents')} />
              </Grid>

              <Grid item lg={12} xs={12} sm={12} md={12} xl={12} style={{ marginBottom: 50 }}>
                <DatePicker selectedDate={selectedDate} onDateChanged={setSelectedDate} />
              </Grid>
              <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                <HorizontalCarousel
                  reloadDependencyValue={selectedDate}
                  load={() => loadEvents(EventType.Date, null, selectedDate, eventsCount)}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

type EventCategoryUrlParams = {
  categorySlug: string;
};

type CategoryType = {
  id: number;
  imageUrl: string;
  title: string;
  slug: string;
};

type LocationType = {
  id: string;
  value: string;
};

const TextContainer = styled.div`
  padding: 15px 25px;

  p {
    text-align: center;
    font-size: 40px;
    color: ${(props) => props.theme.colors.text};
    margin: 0;
    font-style: normal;
    font-weight: normal;
    line-height: 130%;
    /* or 18px */
    text-align: center;
  }

  @media screen and (min-width: 576px) {
    p {
      font-size: 40px;
    }
  }
`;
