import * as PT from '../payment/types';

export interface ICheckoutCompleteStoreState {
  result: PT.IPaymentCompleteResult;
}

export interface ICheckoutStoreState extends PT.IPaymentProcessInfo {
  init: PT.IPaymentInitiateResult;
  process: PT.IPaymentProcessResult;
  complete: ICheckoutCompleteStoreState;
  isRedoing?: boolean;
  isChecking?: boolean;
}

export class CheckoutStoreState extends PT.PaymentProcessInfo implements ICheckoutStoreState {
  init: PT.IPaymentInitiateResult;
  process: PT.IPaymentProcessResult;
  complete: ICheckoutCompleteStoreState;
  isRedoing?: boolean;
  isChecking?: boolean;

  constructor() {
    super();
    this.init = null;
    this.process = null;
    this.complete = null;
    this.isRedoing = false;
    this.isChecking = false;
  }
}

export const enum CheckoutActionTypes {
  CHECKOUT_INITIATE = '@@checkout/INITIATE',
  CHECKOUT_INITIATED = '@@checkout/INITIATED',
  CHECKOUT_PROCESS = '@@checkout/PROCESS',
  CHECKOUT_PROCESSED = '@@checkout/PROCESSED',
  CHECKOUT_COMPLETE = '@@checkout/COMPLETE',
  CHECKOUT_COMPLETED = '@@checkout/COMPLETED',
  CHECKOUT_CLEAR = '@@checkout/CLEAR',
  CHECKOUT_SET_EMAIL = '@@checkout/SET_EMAIL',
  CHECKOUT_REDO = '@@checkout/REDO',
}
