import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IEventStoreState, ISeatStoreState } from '../../../../store/event/types';
import { IVenueSectorStoreState } from '../../../../store/ticket/types';
import { TicketCountSelector } from '../../../../components/book';
import { WidgetPrices } from './widgetPrice';

export const TicketsCountSelector: FC<TicketsCountSelectorPropsType> = memo(
  ({ handleTicketsCount, event, sector, seats, quotaId }) => {
    const { t } = useTranslation();

    if (!event || !sector || !sector.ticketQuotas) {
      return null;
    }

    const quota = sector.ticketQuotas.find((q) => q.id === quotaId);
    if (!quota) {
      return null;
    }

    const tickets = seats.filter((s) => s.quotaId === quotaId);
    const maxPurchase = quota.left < event.maxPurchase ? quota.left : event.maxPurchase;

    return (
      <TicketsPriceWrapper>
        {sector.noSeats && (
          <>
            <Title>{t('WidgetBook.TicketCount')}</Title>
            <TicketSelectorsWrapper>
              <TicketCountSelector
                max={maxPurchase - seats.filter((s) => s.quotaId !== quotaId).length}
                sector={sector}
                tickets={tickets.length}
                handleTicketsCount={handleTicketsCount}
              />
            </TicketSelectorsWrapper>
          </>
        )}
        {!sector.noSeats && <Title>{t('WidgetBook.TicketsPrice')}</Title>}
        {<WidgetPrices event={event} sector={sector} seats={seats} quotaId={quotaId} />}
      </TicketsPriceWrapper>
    );
  }
);

const TicketsPriceWrapper = styled.div`
  padding-top: 30px;
`;

const Title = styled.h1`
  font-size: 14px;
  margin: 0 0 15px 0;
`;

const TicketSelectorsWrapper = styled.section`
  margin-bottom: 30px;
`;

type TicketsCountSelectorPropsType = {
  event: IEventStoreState;
  sector: IVenueSectorStoreState;
  quotaId: number;
  seats: ISeatStoreState[];
  handleTicketsCount: (count: number) => void;
};
