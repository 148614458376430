import { AlertType, BookStatus, Pages } from '../store/enums';
import { store } from '../index';
import { IBookStoreState } from '../store/book/types';
import { Routes } from '../store/routes';

class RouteService {
  private static _instance: RouteService;

  constructor() {
    if (typeof RouteService._instance === 'undefined') {
      RouteService._instance = this;
    }
    return RouteService._instance;
  }

  public isActivePage(pageName: string, subPageName?: string, options?: Array<string>): boolean {
    if (window.location && window.location.pathname) {
      const routeData = window.location.pathname.substr(1).split('/');
      if (routeData && routeData.length > 0) {
        const currentPageName = routeData[0];
        const currentSubPageName = routeData[1];

        const isCurrentPage = pageName.toLowerCase().replace('/', '') === currentPageName.toLowerCase();

        if (!!subPageName) {
          const isCurrentSubPage =
            !!currentSubPageName && subPageName.toLowerCase() === currentSubPageName.toLowerCase();

          if (!!options) {
            let isCurrentOptionsPage = true;
            options.forEach((o) => {
              const isExistOpt = routeData.indexOf(o) > -1;
              if (!isExistOpt) {
                isCurrentOptionsPage = false;
              }
            });
            return isCurrentPage && isCurrentSubPage && isCurrentOptionsPage;
          }
          return isCurrentPage && isCurrentSubPage;
        }
        return isCurrentPage;
      }
    }
    return false;
  }

  public getBaseUrl(): string {
    return location.origin || location.protocol + '//' + location.host;
  }

  public getLocation(domain?: string): string {
    let host = location.host;
    if (domain) {
      host = this.checkIsDev() ? `dev.${domain}` : domain;
    }
    return `${location.protocol}//${host}${location.pathname}${location.search}`;
  }

  public getRouteData(): Array<string> {
    const routeData = window.location.pathname.split('/');
    return routeData.filter((routeSegment) => !!routeSegment);
  }

  public getwidgetToken(): string {
    const routeData = this.getRouteData();
    const page = routeData && routeData[0];
    let token = '';
    switch (page) {
      case Pages.Widget:
        token = routeData[2];
        break;
      default:
        token = '';
        break;
    }

    return token;
  }

  public getEventRoute(eventSlug: string): string {
    return `/${Pages.EventDetails}/${eventSlug}`;
  }

  public getBookRoute(
    eventSlug: string,
    quotaId?: string,
    sectorSlug?: string,
    rowSlug?: string,
    tickets?: number,
    sell: boolean = false
  ): string {
    const page = `/${sell ? Pages.Sell : Pages.Book}/${eventSlug}`;
    const quota = !!quotaId ? `/${quotaId}` : '';
    const sector = !!sectorSlug ? `/${sectorSlug}` : '';
    const sectorRow = !!sector && !!rowSlug ? `/${rowSlug}` : '';
    const ticketsCount = !!sector && !sectorRow && !!tickets ? `/${tickets}` : '';
    return page + sector + quota + sectorRow + ticketsCount;
  }

  public getBookRouteFromBook(book: IBookStoreState, sell: boolean = false): string {
    if (!book || !book.event) return null;
    return this.getBookRoute(book.event.slug, null, book.sectorSlug, null, null, sell);
  }

  public getCheckoutRoute(
    eventSlug: string,
    quotaId: number,
    sectorSlug?: string,
    rowSlug?: string,
    tickets?: number
  ): string {
    return this.getBookRoute(eventSlug, quotaId.toString(), sectorSlug, rowSlug, tickets) + `/${Pages.Checkout}`;
  }

  public getCheckoutRouteFromBook(book: IBookStoreState): string {
    return this.getBookRouteFromBook(book) + `/${Pages.Checkout}`;
  }

  public getWidgetRouteFromToken(token: string): string {
    if (!token) return null;
    return `/${Pages.Widget}/${Pages.Book}/${token}`;
  }

  public getBookResultRoute(status: BookStatus = null): string {
    return `/${Pages.Book}/${Pages.BookResult}/${status}`;
  }

  public getProfileRoute(): string {
    const state = store.getState();
    const isAuthorized = state.app.isAuthorized && state.user && state.user.email;
    if (isAuthorized && !state.user.phoneNumberConfirmed) return Routes.EditProfile;
    else if (isAuthorized) return Routes.Profile;
    else return Routes.Login;
  }

  public getUserTicketsRoute(): string {
    return `/${Pages.User}/${Pages.UserTickets}`;
  }

  public getUserTicketRoute(masterTicketId: string, ticketId: string): string {
    return this.getUserTicketsRoute() + `/${masterTicketId}/${ticketId}`;
  }

  public getUserTicketSellRoute(masterTicketId: string, ticketId: string): string {
    return this.getUserTicketsRoute() + `/sell/${masterTicketId}/${ticketId}`;
  }

  public getUserTicketPurchaseRoute(masterTicketId: string, ticketId: string): string {
    return this.getUserTicketsRoute() + `/Purchase/${masterTicketId}/${ticketId}`;
  }

  public getEmailConfirmationRedirectUrl() {
    return `${this.getBaseUrl()}/${Pages.User}/${Pages.EmailConfirm}`;
  }

  public getResetPasswordRedirectUrl() {
    return `${this.getBaseUrl()}/${Pages.User}/${Pages.ResetPassword}`;
  }

  private checkIsDev(): boolean {
    return location.host.includes('dev');
  }

  public getEventCategoryRoute(categorySlug: string): string {
    return `/${Pages.Categories}/${categorySlug}`;
  }

  public getEventsLocation(city: string) {
    return `?city=${city}`;
  }

  public getResultStatusRoute(status, key) {
    const isSuccess = status !== AlertType.Error;
    return `${isSuccess ? Routes.Success : Routes.Failed}/${key}`;
  }
}

export default RouteService;
