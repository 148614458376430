import { Reducer } from 'redux';

import { IHeaderStoreState, HeaderStoreState, HeaderActionTypes } from './types';

const headerReducer: Reducer<IHeaderStoreState> = (
  state: IHeaderStoreState = new HeaderStoreState(),
  action,
): IHeaderStoreState => {
  switch (action.type) {
    case HeaderActionTypes.HEADER_SHOW:
      return { ...state, showAdButton: true };
    case HeaderActionTypes.HEADER_HIDE:
      return { ...state, showAdButton: false };
    case HeaderActionTypes.PLAYER_SHOW:
      return { ...state, showPlayer: true };
    case HeaderActionTypes.PLAYER_HIDE:
      return { ...state, showPlayer: false };
    case HeaderActionTypes.CITYSELECTOR_SHOW:
      return { ...state, showCitySelector: true };
    case HeaderActionTypes.CITYSELECTOR_HIDE:
      return { ...state, showCitySelector: false };
    default:
      return state;
  }
};

export default headerReducer;
