import { action } from 'typesafe-actions';
import { Dispatch } from 'redux';
import dayjs from 'dayjs';

import { DistributionActionTypes, IDistributionStoreState, DistributionStoreState } from './types';
import Constants from '../constants';
import { IGlobalStoreState } from '..';

const init = (payload: IDistributionStoreState) => action(DistributionActionTypes.DISTRIBUTION_INIT, payload);
const clear = () => action(DistributionActionTypes.DISTRIBUTION_CLEAR);

export const distributionInit =
  (distributorSlug: string) =>
  (dispatch: Dispatch<any>, getState: () => IGlobalStoreState): void => {
    const state = getState();
    const isDistributorSlugExisted = !!state.distribution && state.distribution.distributorSlug === distributorSlug;
    if (!distributorSlug || isDistributorSlugExisted) {
      return;
    }
    const expirationDate = dayjs().add(Constants.DistributionExpirationMins, 'm');
    const distribution = new DistributionStoreState(distributorSlug, expirationDate);
    dispatch(init(distribution));
  };

export const distributionClear =
  () =>
  (dispatch: Dispatch<any>): void => {
    dispatch(clear());
  };
