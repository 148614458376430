import * as React from 'react';
import styled from 'styled-components';

import { Translation } from 'react-i18next';
import { IUserStatsStoreState, IUserBalanceStoreState } from '../../store/user/types';
import { UserStatItem } from './userStatItem';
import { Utils } from '../../services';

interface IUserStatsProps {
  stats: IUserStatsStoreState;
}

export class UserStats extends React.PureComponent<IUserStatsProps> {
  constructor(props: IUserStatsProps) {
    super(props);
  }

  render() {
    const stats = this.props.stats;
    return (
      <>
        <Translation>
          {(t) => (
            <StatsList>
              <UserStatItem title={t('Profile.Events')} count={stats.events} icon="meet" />
              <UserStatItem title={t('Profile.SoldTicket')} count={stats.ticketsSold} icon="soldTicket" />
            </StatsList>
          )}
        </Translation>
        <StatsList>{this.renderBalances(stats.balances)}</StatsList>
      </>
    );
  }

  private renderBalances(balances: Array<IUserBalanceStoreState>) {
    if (!balances || !balances.length) {
      return <Translation>{(t) => <UserStatItem title={t('Profile.Points')} count={0} icon="star" />}</Translation>;
    }

    return (
      <>
        {balances.map((item, index) => (
          <Translation key={index}>
            {(t) => (
              <UserStatItem
                key={index}
                title={t('Profile.Points') + ` ${Utils.getEmojiFlagByCurrency(item.currency)}`}
                count={item.amount}
                icon="star"
              />
            )}
          </Translation>
        ))}
      </>
    );
  }
}


const StatsList = styled('ul' as any)`
  display: flex;
  list-style: none;
  padding: 0;
  align-items: unset;
  margin: 25px 0;
`;
