import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';

import { Translation, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { IGlobalStoreState } from '../../../store';
import { Button, Message, MessageButtonsWrapper, PageContent } from '../../../components/UI';
import { IUserStoreState } from '../../../store/user/types';
import { Utils } from '../../../services';
import { IEventStoreState } from '../../../store/event/types';
import { UserEventsList } from '../../../components/event';
import { useRoutesHelper } from '../../../helpers/routesHelper';

const UserEventsPage = () => {
  const isInitializing = useSelector<IGlobalStoreState, boolean>((state) => state.app.isInitializing);
  const isAuthorized = useSelector<IGlobalStoreState, boolean>((state) => state.app.isAuthorized);
  const user = useSelector<IGlobalStoreState, IUserStoreState>((state) => state.user);
  const events = useSelector<IGlobalStoreState, Array<IEventStoreState>>((state) => state.userEvents);

  const { getProfileRoute } = useRoutesHelper();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isAuthorized || !user.phoneNumberConfirmed) {
      history.push(getProfileRoute());
      return;
    }
  }, [getProfileRoute, history, isAuthorized, user.phoneNumberConfirmed]);

  if (isInitializing || !user) {
    return null;
  }

  return (
    <PageContent noPadding>
      <Helmet>
        <title>{Utils.toPageTitle(t('Profile.MyEvents'))}</title>
      </Helmet>
      {!!events.length && <UserEventsList events={events} />}
      {!events.length && (
        <Message
          title={t('MyEvents.NoEvents')}
          description={t('MyEvents.NoEventsDescription')}
          renderButtons={() => (
            <MessageButtonsWrapper>
              <Button title={t('MyEvents.AllEvents')} onClick={() => history.push('/')}>
                {t<string>('MyEvents.AllEvents')}
              </Button>
            </MessageButtonsWrapper>
          )}
        />
      )}
    </PageContent>
  );
};

export default UserEventsPage;
