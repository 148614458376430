import React, { FC } from 'react';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { StripeSettings } from '../../settings/appSettings';
import { CheckoutForm } from './checkoutForm';

const stripePromise = loadStripe(StripeSettings.STRIPE_KEY);

export const StripeCheckoutForm: FC<ContentPropsType> = ({ paymentToken }) => {
  const options: StripeElementsOptions = {
    locale: 'en-GB',
    clientSecret: paymentToken,
    loader: 'always',
    appearance: {
      ...{ theme: 'night', variables: { borderRadius: '24px' } },
    },
  };

  return (
    <Elements options={options} stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

type ContentPropsType = {
  paymentToken: string;
};
