import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Price, PriceTitle } from '../../book';
import { IEventStoreState, ISeatStoreState } from '../../../../store/event/types';

export const TicketsInfo: FC<TicketsInfoPropsType> = memo(({ seats, event }) => {
  const { t } = useTranslation();

  if (!seats.length) return null;

  const defaultPaymentMethod = event.paymentMethods && event.paymentMethods.length > 0 ? event.paymentMethods[0] : null;

  let total = 0;
  let currency: string;

  seats.forEach((ticket) => {
    event.sectors.forEach((sector) => {
      const countTickets = sector.ticketQuotas.find((st) => st.id === ticket.quotaId);
      if (countTickets) {
        total += countTickets.price;
        currency = countTickets.currency;
      }
    });
  });

  if (defaultPaymentMethod.useConvertion) {
    total = Math.round(total * defaultPaymentMethod.convertionRate * 100) / 100;
    currency = defaultPaymentMethod.convertionCurrency;
  }
  return (
    <>
      <Table>
        {t('WidgetBook.SelectedTickets')}
        {seats.map((ticket) => {
          const sector = event.sectors.find((x) => x.slug === ticket.sectorSlug);
          const quota = sector.ticketQuotas.find((x) => x.id === ticket.quotaId);
          const title = `${t('WidgetBook.Sector')} ${ticket.sectorTitle}${
            ticket.rowSlug ? `, ${t('WidgetBook.Row')} ${ticket.rowSlug}` : ``
          }${ticket.seatSlug ? `, ${t('WidgetBook.Seat')} ${ticket.seatSlug}.` : ``}`;
          const price = `${quota.price} ${quota.currency}`;
          return (
            <TableRow>
              <TableData>
                <CostTitle>{title}</CostTitle>
              </TableData>
              <TableData>
                <CostTitle>{price}</CostTitle>
              </TableData>
            </TableRow>
          );
        })}
      </Table>
      <Table>
        <TableRow>
          <TableData>
            <TotalPriceTitle>{t('WidgetBook.Total')}</TotalPriceTitle>
          </TableData>
          <TableData>
            <TotalPrice>
              {total} {currency}
            </TotalPrice>
          </TableData>
        </TableRow>
      </Table>
    </>
  );
});

const CostTitle = styled.span`
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.lighterFont};
`;

const TotalPriceTitle = styled(PriceTitle as any)`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.regularFont};
`;
const TotalPrice = styled(Price as any)`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.boldFont};
`;

const Table: any = styled.table`
  border-top: 1px solid ${(props) => props.theme.colors.accent};
  table-layout: fixed;
  width: 55%;
  margin-left: auto;
  min-width: 330px;
`;
const TableRow = styled.tr``;
const TableData = styled.td``;

type TicketsInfoPropsType = {
  seats: ISeatStoreState[];
  event: IEventStoreState;
};
