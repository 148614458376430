export interface IHeaderStoreState {
  showAdButton: boolean;
  showPlayer: boolean;
  showCitySelector: boolean;
}

export class HeaderStoreState implements IHeaderStoreState {
  showAdButton: boolean;
  showPlayer: boolean;
  showCitySelector: boolean;

  constructor() {
    this.showAdButton = true;
    this.showPlayer = false;
    this.showCitySelector = false;
  }
}

export enum HeaderActionTypes {
  HEADER_SHOW = '@@header/SHOW',
  HEADER_HIDE = '@@header/HIDE',
  PLAYER_SHOW = '@@header/PLAYER_SHOW',
  PLAYER_HIDE = '@@header/PLAYER_HIDE',
  CITYSELECTOR_SHOW = '@@header/CITYSELECTOR_SHOW',
  CITYSELECTOR_HIDE = '@@header/CITYSELECTOR_HIDE',
}
