import { Grid, Link, Typography, Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FC, memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IEventCategory } from '../../store/event/types';
import { SkeletonEventCarousel } from './skeletonEventCarousel';
import useApi from '../../services/apiService';
import { Events } from './events';
import { useRouteService } from '../../helpers/customHooks';

const useStyles = makeStyles()(() => ({
  skeletonContainer: {
    marginLeft: '25px',
    marginRight: '25px',
  },
  skeletonTitle: {
    marginBottom: 20,
  },
  skeletonText: {
    height: 20,
    marginTop: '10px',
  },
  gridTitle: {
    fontSize: 14,
    lineHeight: '14px',
    textTransform: 'uppercase',
    display: 'inline-block',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
}));

export const CategorizedEventCarousel: FC<CategorizedEventCarouselType> = memo(({ categories }) => {
  const history = useHistory();
  const { classes } = useStyles();
  const { eventsApi } = useApi();
  const routeSrv = useRouteService();
  const { t } = useTranslation();

  const loadCategorisedEvents = useCallback(async (categoryId: number) => {
    const result = await eventsApi.getEventsByCategories({ categoryId });
    return result.map((event) => ({
      key: event.slug,
      title: event.title,
      imageUrl: event.posterUrl,
      date: event.date,
      city: event.city,
      domainTypes: event.domainTypes,
    }));
  }, []);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
      {!categories.length &&
        Array.from(new Array(3)).map((value, index) => (
          <Grid key={index} item lg={12} xs={12} sm={12} md={12} xl={12} className={classes.skeletonContainer}>
            <div className={classes.skeletonTitle}>
              <Skeleton animation="wave" variant="rectangular" width="25%" className={classes.skeletonText} />
            </div>
            <SkeletonEventCarousel skeletonCount={3} />
          </Grid>
        ))}

      {categories.length &&
        categories
          .sort((a, b) => b.eventsCount - a.eventsCount)
          .map((category) => (
            <Grid key={category.id} item lg={12} xs={12} sm={12} md={12} xl={12}>
              <div>
                {!categories.length && (
                  <Typography variant="h6" className={classes.gridTitle}>
                    <Skeleton variant="rectangular" width={140} height={25} />
                  </Typography>
                )}
                {categories.length && (
                  <Typography variant="h6" className={classes.gridTitle}>
                    {t<string>(`Dashboard.Categories.${category.slug}`)}
                  </Typography>
                )}
              </div>
              <Events load={() => loadCategorisedEvents(category.id)} />
            </Grid>
          ))}
    </Grid>
  );
});

type CategorizedEventCarouselType = {
  categories: IEventCategory[];
};
