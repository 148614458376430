import React, { FC } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, RLink, TextInput } from '../UI';
import { FieldError, Fieldset, FieldWrapper, FormError } from './commonForm';
import { Routes } from '../../store/routes';
import { AnimatePresence } from 'framer-motion';
import { PopUp } from '../../store/animations';

export type LoginFormType = {
  email: string;
  password: string;
};
type LoginFormProps = {
  submitTitle: string;
  onSubmit: (LoginFormType) => void;
};

export const LoginForm: FC<LoginFormProps> = ({ submitTitle, onSubmit }) => {
  const { t } = useTranslation();

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().required(t('Login.EmailRequired')).email(t('Registration.EmailIncorrect')),
    password: Yup.string().required(t('Login.PasswordRequired')),
  });

  const initialValues = {
    email: '',
    password: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: LoginFormType) => {
        if (onSubmit) {
          onSubmit(values);
        }
      }}
      validationSchema={ValidationSchema}
    >
      {({ isValid, handleChange, handleBlur, values }) => (
        <StyledForm autoComplete="on">
          <Fieldset>
            <Field name="email" type="email">
              {({ field, form }) => (
                <FieldWrapper noted={!!form.touched.email && !!form.errors && !!form.errors.email}>
                  <TextInput
                    placeholder="E-mail"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={!!form.touched.email && !!form.errors && !!form.errors.email}
                    {...field}
                  />
                  <AnimatePresence>
                    {!!form.touched.email && !!form.errors && !!form.errors.email && (
                      <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                        {form.errors.email}
                      </FieldError>
                    )}
                  </AnimatePresence>
                </FieldWrapper>
              )}
            </Field>
            <Field name="password" type="password">
              {({ field, form }) => (
                <FieldWrapper noted={!!form.touched.password && !!form.errors && !!form.errors.password}>
                  <TextInput
                    name="password"
                    placeholder={t('Login.Password')}
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={!!form.touched.email && !!form.errors && !!form.errors.email}
                    {...field}
                  />
                  <AnimatePresence>
                    {!!form.touched.password && !!form.errors && !!form.errors.password && (
                      <FieldError key="error" variants={PopUp} initial={'enter'} animate={'exit'}>
                        {form.errors.password}
                      </FieldError>
                    )}
                  </AnimatePresence>
                </FieldWrapper>
              )}
            </Field>
            <ForgotPassLink to={Routes.PasswordRecovery} title={t('Login.GoToPasswordRecovery')}>
              {t('Login.ForgotYourPassword')}
            </ForgotPassLink>
          </Fieldset>
          <StyledButton type="submit" disabled={!isValid}>
            {submitTitle}
          </StyledButton>
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledForm = styled(Form)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const ForgotPassLink = styled(RLink)`
  font-size: 12px;
  margin-bottom: 15px;
`;
