export interface ITokenRequestStoreState {
  username: string;
  password: string;
  grant_type: string;
  client_id: string;
  client_secret: string;
  refresh_token?: string;
}

export class TokenRequestStoreState implements ITokenRequestStoreState {
  username: string;
  password: string;
  grant_type: string;
  client_id: string;
  client_secret: string;
  refresh_token?: string;
}

export interface IAuthStoreState {
  access_token: string;
  token_type: string;
  refresh_token: string;
  client_id: string;
  issued: string;
  expires: string;
  expires_in: number;
}

export class AuthStoreState implements IAuthStoreState {
  access_token: string;
  token_type: string;
  refresh_token: string;
  client_id: string;
  issued: string;
  expires: string;
  expires_in: number;

  constructor(dto?: any) {
    this.access_token = dto && dto.access_token ? dto.access_token : '';
    this.token_type = dto && dto.token_type ? dto.token_type : '';
    this.refresh_token = dto && dto.refresh_token ? dto.refresh_token : '';
    this.client_id = dto && dto.client_id ? dto.client_id : '';
    this.issued = dto && dto.issued ? dto.issued : '';
    this.expires = dto && dto.expires ? dto.expires : '';
    this.expires_in = dto && dto.expires_in ? dto.expires_in : '';
  }
}

export const enum AuthActionTypes {
  AUTH_INIT = '@@auth/INIT',
  AUTH_CLEAR = '@@auth/CLEAR',
}
