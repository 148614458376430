import React, { FC, useState } from 'react';
import { Chip, ClickAwayListener, ListItemText, Paper, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { ListItemLink } from '../popupLocation';
import { IEventCategory } from '../../../store/event/types';
import { AppTheme } from '../../../settings/appTheme';

const useStyles = makeStyles()((theme: Theme) => ({
  containerGenresAccordion: {
    position: 'relative',
    marginLeft: 20,
    [theme.breakpoints.down(375)]: {
      marginLeft: 'auto',
    },
  },
  paperListGenres: {
    position: 'absolute',
    minWidth: '196px',
    background: '#FFF',
    zIndex: 1,
    right: 0,
    marginTop: 10,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
  },
  listItemText: {
    '& span': {
      fontSize: 14,
      lineHeight: '14px',
      zIndex: 1,
      color: 'black',
    },
  },
  chipLocationInfo: {
    border: '1px solid rgb(107, 107, 107, 0.6)',
    borderRadius: 35,
    height: 30,
    width: 115,
    overflow: 'auto',
    textOverflow: 'ellipsis',
    fontSize: 12,
    color: 'white',
    lineHeight: '12px',
    fontFamily: AppTheme.fonts.regularFont,
    display: 'flex',
    justifyContent: 'space-around',
    '& span': {
      marginTop: 1,
      marginRight: '10%',
    },
    '& .MuiChip-deleteIcon': {
      color: 'rgba(255, 255, 255, 0.26)',
      '&:hover': {
        color: 'rgba(255, 255, 255, 0.4)',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  notFoundText: {
    fontSize: 14,
    lineHeight: '14px',
    zIndex: 1,
    color: 'black',
    padding: 10,
    cursor: 'default',
    userSelect: 'none',
  },
}));

export const CategoriesAccordion: FC<CategoriesAccordionType> = ({ categories, onCategoryChange }) => {
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState<number>(null);

  const { t } = useTranslation();

  const handleOpenClick = () => setOpen((open) => !open);
  const handleAwayClick = () => setOpen(false);

  const handleCategoriesFiltered = (genre: number) => {
    onCategoryChange(genre);
    setCurrentCategoryId(genre);
    setOpen(false);
  };

  const genreTitle = () => {
    const category =
      categories.length && currentCategoryId && categories.find((category) => category.id === currentCategoryId);
    return categories.length && currentCategoryId ? t(`Dashboard.Categories.${category.slug}`) : t(`Dashboard.Genres`);
  };

  const handleDeleteGenre = () => {
    onCategoryChange(null);
    setCurrentCategoryId(null);
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleAwayClick}>
      <div className={classes.containerGenresAccordion}>
        <Chip
          className={classes.chipLocationInfo}
          label={genreTitle()}
          icon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={handleOpenClick}
          onDelete={currentCategoryId && handleDeleteGenre}
        />
        {open && (
          <Paper className={classes.paperListGenres}>
            {categories.length ? (
              categories.map((category) => (
                <ListItemLink key={category.slug} onClick={() => handleCategoriesFiltered(category.id)}>
                  <ListItemText
                    primary={t<string>(`Dashboard.Categories.${category.slug}`)}
                    className={classes.listItemText}
                  />
                </ListItemLink>
              ))
            ) : (
              <div className={classes.notFoundText}>{t<string>(`Dashboard.NothingFound`)}</div>
            )}
          </Paper>
        )}
      </div>
    </ClickAwayListener>
  );
};

type CategoriesAccordionType = {
  categories: IEventCategory[];
  onCategoryChange: (genre: number) => void;
};
