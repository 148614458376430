import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { useTranslation } from 'react-i18next';
import { IGlobalStoreState } from '../../../../store';
import { PageContent } from '../../../../components/UI';
import { Utils } from '../../../../services';
import { IErrorStoreState } from '../../../../store/app/types';
import { ResetPasswordForm, ResetPasswordFormType } from '../../../../components/forms';
import { confirmResetPassword } from '../../../../store/user/actions';
import { AlertType, KeyErrors } from '../../../../store/enums';
import { setResultInfo } from '../../../../store/resultInformarion/actions';
import { useRoutesHelper } from '../../../../helpers/routesHelper';
import { useQuery } from '../../../../helpers/customHooks';
import { Routes } from '../../../../store/routes';
import { useUtils } from '../../../../services/useUtils';

const ResetPasswordPage = () => {
  const { getResultStatusRoute, getProfileRoute } = useRoutesHelper();
  const { t } = useTranslation();
  const { toPageTitle } = useUtils();

  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const token = query.get('token');
  const encodedEmail = query.get('email');

  const isInitializing = useSelector<IGlobalStoreState, boolean>((state) => state.app.isInitializing);
  const error = useSelector<IGlobalStoreState, IErrorStoreState>((state) => state.app.error);

  const [errors, setErrors] = useState(null);

  useEffect(() => {
    setErrors({ form: error });
  }, [error?.message, error?.details]);

  useEffect(() => {
    if (!token || !encodedEmail) {
      dispatch(
        setResultInfo({
          key: KeyErrors.EmailConfirmFailed,
          type: AlertType.Error,
          title: 'Alert.SomethingWentWrong',
          message: 'Alert.EmailVerificationLinkIsInvalidOrOutOfDate',
          buttons: [
            {
              title: 'PasswordRecovery.GoToMainPage',
              callback: () => history.replace(Routes.Main),
            },
          ],
        })
      );
      history.replace(getResultStatusRoute(AlertType.Error, KeyErrors.EmailConfirmFailed));
    }
  }, []);

  if (isInitializing) {
    return null;
  }

  const handleFormSubmit = (formModel: ResetPasswordFormType) => {
    if (formModel) {
      (async () => dispatch(confirmResetPassword(formModel.password, token, encodedEmail)))().then(() => {
        history.replace(getProfileRoute());
      });
    }
  };

  return (
    <PageContent center>
      <Helmet>
        <title>{toPageTitle(t('PasswordRecovery.PasswordRecovery'))}</title>
      </Helmet>
      <Header>{t('PasswordRecovery.PasswordRecovery')}</Header>
      <ResetPasswordForm
        submitTitle={t('PasswordRecovery.SignIn')}
        errors={errors}
        onSubmit={(formModel: ResetPasswordFormType) => handleFormSubmit(formModel)}
      />
    </PageContent>
  );
};

const Header = styled.h1`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
`;

export default ResetPasswordPage;
